import AddGallerySet from "./AddGallerySet";
import SectionHeader from "./SectionHeader";
import Title from "./Title";
import {useState,useEffect} from "react";
export default function Gallery() {
const [deletedids, setdeletedids] = useState([]);
const [GalleryData, SetGalleryData] = useState([]);
const [isLoading, setIsLoading] = useState(false);
  return (
    <div className='container mt-5 px-2 px-md-5'>
      <SectionHeader  GalleryData={GalleryData} SetGalleryData={SetGalleryData} setIsLoading={setIsLoading} deletedids={deletedids} setdeletedids={setdeletedids}  />
      <div className='bg-white rounded p-lg-5 p-md-3 p-2 shadow-sm'>
        {isLoading ? (
          <div className="text-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
            <div>
              <Title />
              <AddGallerySet GalleryData={GalleryData} deletedids={deletedids} setdeletedids={setdeletedids} />
            </div>
        )}
      </div>
    </div>
  );
}
