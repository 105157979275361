import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; 
export default function CategoriesImageGallery() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { galleryid } = useParams();
  const [GalleryData, SetGalleryData] = useState([]);
  const [imgs, setImgs] = useState([]);
  const [mainImage, setMainImage] = useState([]);
  const [GalleryTitle, setGalleryTitle] = useState([]);
  const [GalleryNote, setGalleryNote] = useState([]);
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const navigate = useNavigate();

  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const closeModal = () => {
    setModalImageUrl(null);
  };



  // State variables for form fields
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    notes: ""
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone_number: false,
    notes: false
  });

  // Destructure form data for easier access
  const { name, email, phone_number, notes } = formData;

  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if (galleryid) {
      const filterGallery = async () => {
        try {
          const response = await axios.get(`${backendUrl}/api/gallery/${galleryid}`);
          SetGalleryData(response.data.gallery);
          const galleryData = response.data.gallery;
          setGalleryTitle(response.data.gallery.title);
          setGalleryNote(response.data.gallery.note);
          const imgPaths = galleryData.gallery_media.map(galleryItem => {
            return { url: galleryItem.path };
          });
          setImgs(imgPaths);
          setMainImage(imgPaths[0]);
        } catch (error) {
          console.error('Error fetching or setting gallery:', error);
        }
      };

      filterGallery();
    }
  }, [galleryid, backendUrl]);

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    const newErrors = {
      name: !name.trim(),
      email: !email.trim() || !emailRegex.test(email),
      phone_number: !phone_number.trim(),
      notes: !notes.trim()
    };

    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      // If there are validation errors, do not submit the form
      console.log("Please fill out all required fields correctly.");
      return;
    }

    try {
      
      const response = await axios.post(`${backendUrl}/api/customer-review-add`, {
        name: name,
        email: email,
        phone_number: phone_number,
        notes: notes,
        gallery_id: galleryid,
        mainImage: `${backendUrl}${mainImage.url}`
      });
 
      if (response.data.success === true) {
        navigate(`/gallery/view/`); 
      }   

      setFormData({
        name: "",
        email: "",
        phone_number: "",
        notes: ""
      });

      // Clear errors after successful submission
      setErrors({
        name: false,
        email: false,
        phone_number: false,
        notes: false
      });

    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error as needed
    }
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Function to determine the input field's class based on error state
  const getInputClassName = (fieldName) => {
    return errors[fieldName] ? "form-control is-invalid" : "form-control";
  };

  return (
    <div className="row mt-5 row_area align-items-center h-100">
      <div className="col-md-8">
        <div className="Main_section">
          <div className="side_bar">
            {imgs.length !== 0 &&
              imgs.map((curElm, index) => (
                <figure
                  className={`${mainImage.url === curElm.url ? "activeFigure" : ""}`}
                  key={index}
                >
                  <img
                    src={`${backendUrl}${curElm.url}`}
                    alt="images"
                    onClick={() => setMainImage(curElm)}
                  />
                </figure>
              ))}
          </div>
          <div className="main-screen">
            <img
              src={mainImage.url ? `${backendUrl}${mainImage.url}` : ""}
              alt={mainImage.filename}
              onClick={() => openModal(mainImage.url ? `${backendUrl}${mainImage.url}` : "")}
            />
          </div>
        </div>
      </div>

      <div className="col-md-4">
        <div className="right_side_area px-2 px-md-2">
          <form onSubmit={handleSubmit}>
            <div className="gallery_title">
              <h2 className="mb-1">
               {GalleryTitle}
              </h2>
              <span>
                {GalleryNote}
              </span>
            </div>

            <div className="form-group mt-4">
              <label htmlFor="notes" className="mb-1">
                Leave Notes / Directions
              </label>
              <textarea
                className={getInputClassName('notes')}
                id="notes"
                rows="5"
                name="notes"
                value={notes}
                onChange={handleInputChange}
              ></textarea>
              {errors.notes && <div className="invalid-feedback">This field is required.</div>}
            </div>
            <div className="form-group mt-4">
              <label htmlFor="name" className="mb-1">
                Full Name
              </label>
              <input
                type="text"
                className={getInputClassName('name')}
                id="name"
                name="name"
                value={name}
                onChange={handleInputChange}
              />
              {errors.name && <div className="invalid-feedback">This field is required.</div>}
            </div>
            <div className="form-group mt-4">
              <label htmlFor="email" className="mb-1">
                Email Address
              </label>
              <input
                type="email"
                className={getInputClassName('email')}
                id="email"
                name="email"
                value={email}
                onChange={handleInputChange}
              />
              {errors.email && <div className="invalid-feedback">Please enter a valid email address.</div>}
            </div>
            <div className="form-group mt-4">
              <label htmlFor="phone_number" className="mb-1">
                Phone Number
              </label>
              <input
                type="text"
                className={getInputClassName('phone_number')}
                id="phone_number"
                name="phone_number"
                value={phone_number}
                onChange={handleInputChange}
              />
              {errors.phone_number && <div className="invalid-feedback">This field is required.</div>}
            </div>
            <div className="form-group mt-4 d-flex justify-content-center">
              <button type="submit" className="custom-btn mt-4 w-50">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Modal */}
      {modalImageUrl && (
        <div className={`custom-modal ${modalImageUrl ? 'active' : ''}`}>
          <span className="cu-close" onClick={closeModal}>&times;</span>
          <div className="cu-modal">
            <div className="cu-modal-content">
              <img src={modalImageUrl} alt="Enlarged View" />
            </div>
          </div>
        </div>
      )}

    </div>
  );
}
