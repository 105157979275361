import React from "react";
import { Link } from "react-router-dom";
import "./ContractForm.css";
import ContractFormModal from "./ContractFormModal";
// ContactFormHeader component for displaying a header with navigation and buttons
const ContactFormHeader = ({ toggleSidebar }) => (
  <>
    <ContractFormModal />
    <nav className='navbar navbar-expand flex-column flex-md-row bottom-header-main  px-lg-5 px-2 py-2'>
      <div className=' d-flex align-items-center d-md-flex ms-md-2'>
        <div className='d-flex align-items-center bottom-header'>
          {/* Back arrow icon */}
          {/* <div className='icon'>
          <MdKeyboardArrowLeft />
        </div> */}
          {/* ====== Hamburger Button to Toggle The side Bar =========== */}
          <button
            className='sidebar-toggler flex-shrink-0 bg-transparent border-0 me-3'
            onClick={toggleSidebar}>
            <i className='fa fa-bars' />
          </button>

          <Link to='/templates'>
            {/* Title */}
            <h5 className='untitled-product-title'>Untitled Product</h5>
            {/* Folder icon and preview text */}
            <p className='d-flex align-items-center'>
              <i className='far fa-folder'></i>
              <span className='preview-text'>Templates</span>
            </p>
          </Link>
        </div>
      </div>
      <div className='navbar-nav align-items-center ms-md-auto'>
        <div className='py-3 button_group  gy-3'>
          {/* Preview button */}
          <button className='mb-md-0 bg-white'>PREVIEW</button>
          {/* Use template button */}
          <button
            type='btn'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop01'>
            PUBLISH
          </button>
        </div>
      </div>
    </nav>
  </>
);

export default ContactFormHeader;
