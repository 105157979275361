import { useEffect, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useEditableContent } from "../../Context/EditableContentContext";
import { deleteProduct, fetchProducts } from "../../services/productsService"; // Import your service functions

function Products() {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const { editableProductContent, handleProductContentChange } =
    useEditableContent();

  useEffect(() => {
    const getProducts = async () => {
      try {
        const products = await fetchProducts();
        setProducts(products);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    getProducts();
  }, []);
  

  const toggleMenu = (product) => {
    setSelectedProduct(product);
    setMenuVisible(!menuVisible);
  }; 

  const showMenu = (product) => {
    setSelectedProduct(product);
    setMenuVisible(true);
  };

  const hideMenu = () => {
    setMenuVisible(false);
    setSelectedProduct(null);
  };

  const handleEdit = (productId) => {
    window.location.href = `/edit-product/${productId}`;
  };

  const handleDelete = async (productId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await deleteProduct(productId);
        setProducts(products.filter((product) => product.id !== productId));
        Swal.fire("Deleted!", "Your product has been deleted.", "success");
        handleProductContentChange("Untitled Item");
      }
    } catch (error) {
      console.error(`Failed to delete product with ID ${productId}:`, error);
      Swal.fire("Error", "Failed to delete product.", "error");
    }
  };

  return (
    <div className='container-fluid p-lg-5 p-md-5 p-3'>
      <div className='row g-lg-5 g-md-5 g-3'>
        <div className='col-sm-12 col-md-12 col-xl-12'>
          <div className='mb-4'>
            <h3 className='mb-1'>Product Templates</h3>
            <p>
              Product templates save time by streamlining the integration of
              individual items into various documents like brochures.
              <br />
              Product templates save time by streamlining the integration of
              individual items.
            </p>
          </div>
        </div>

        <div className='col-sm-12 col-md-6 col-xl-4 product-items'>
          <div className='h-100 bg-white rounded p-3 shadow-sm'>
            <Link
              to='/add-product'
              id=''
              className='h-100 d-flex align-items-center justify-content-center px-2 py-5 p-urls'
              style={{ border: "1px dashed #534dbb" }}>
              <div className='mb-4 text-center'>
                <h1 className='mb-0 fw-light text-uppercase'>+</h1>
                <h6 className='mb-0 fw-light text-uppercase'>
                  Create a new Product
                </h6>
              </div>
            </Link>
          </div>
        </div>

        {products.map((product) => (
          <div
            key={product.id}
            onClick={() => handleEdit(product.id)}
            className='col-sm-12 col-md-6 col-xl-4 product-items'>
            <div className='h-100 bg-white rounded p-4 shadow-sm position-relative product-items2'>
              <div className='d-flex justify-content-between '>
                <div id='p-meta'>
                  <h5>
                    {product.name}
                    <br />
                  </h5>
                  <p style={{ fontSize: 12 }}>
                    Created at{" "}
                    {new Date(product.created_at).toLocaleDateString()}
                  </p>
                </div>
                <div
                  id='p-dot'
                  style={{ position: "relative", zIndex: 10000 }}
                  onMouseEnter={() => showMenu(product)}
                  onMouseLeave={hideMenu}>
                  <FaEllipsisV
                    // onClick={() => toggleMenu(product)}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click event from propagating to the parent div
                      toggleMenu(product);
                    }}
                  />
                  {menuVisible && selectedProduct === product && (
                    <div
                      className='position-absolute bg-white shadow-sm rounded'
                      style={{
                        top: "20px",
                        right: "0",
                        zIndex: "999",
                      }}>
                      <button
                        className='dropdown-item'
                        onClick={() => handleEdit(product.id)}>
                        Edit
                      </button>
                      <button
                        className='dropdown-item'
                        onClick={(e) => 
                          {
                            e.stopPropagation();
                            handleDelete(product.id)
                          }
                        }>
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div id='product-cart' style={{ fontSize: 12 }}>
                <i className='fa fa-shopping-cart pe-2' aria-hidden='true' />
                Product
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export { Products };
