import { useState } from "react";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import { useEditableContent } from "../../Context/EditableContentContext";

const PackageHeader = ({ toggleSidebar }) => {
  const { packageId } = useParams();
  const { editablePackageContent, handlePackageContentChange } =
    useEditableContent();
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(editablePackageContent);

  return (
    <nav className='navbar navbar-expand nav_mobile flex-column flex-md-row bottom-header-main px-lg-5 px-2 py-2'>
      <div className=' d-flex align-items-center d-md-flex ms-md-2'>
        <div className='d-flex align-items-center bottom-header'>
        </div>
      </div>
    </nav>
  );
};

export default PackageHeader;
