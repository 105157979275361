import React, { useState } from "react";

const CommonDropdownMenuTwo = ({ options, handleOptionSelect = () => {} }) => {
  // State variables
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle click on existing option
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    handleOptionSelect(option); // Call the function from the parent component
  };

  return (
    <div className='accordion position-relative'>
      {/* Dropdown header */}
      <div
        className={`accordion-header  ${isOpen ? "active" : ""}`}
        onClick={toggleDropdown}>
        <span className='selected-option'>{selectedOption}</span>
        <span className='icon overflow-hidden'>
          <i
            className={
              isOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
            }
          />
        </span>
      </div>
      {/* Dropdown content */}
      <div className={`accordion-content ${isOpen ? "active" : ""}`}>
        {/* Render existing options */}
        {options.map((option, index) => (
          <div
            key={index}
            className='option'
            onClick={() => handleOptionClick(option)}>
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonDropdownMenuTwo;
