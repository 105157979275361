import { Link } from "react-router-dom";
import CommonDropdownMenu from "../Shared/CommonDropdownMenu";
import {useState,useEffect} from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom'; 
export default function SectionHeader({ GalleryData, setIsLoading,SetGalleryData,deletedids,setdeletedids}) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [GalleryTypeOptionsOpen, setGalleryTypeOptionsOpen] = useState(false);
  const [GalleryTypeId, setGalleryTypeId] = useState(0); 
  const [GalleryType, setGalleryType] = useState(""); // Updated to store id
  const [GalleryTypeOptions, setGalleryTypeOptions] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [callbackStatus, setCallbackStatus] = useState(false);

  const [created_by, setCreatedBy] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
      // Check if user data exists in localStorage
      const udata = localStorage.getItem('user');
      if (!udata) {
          // Redirect to login page if user data does not exist
          navigate('/sign-in');
          return;
      }
      const user_data = JSON.parse(udata);
      setCreatedBy(user_data.user.id);
  }, [navigate]);

  const fetchGalleryTypeOptions = async () => {
    if(created_by){
      try {
        const response = await axios.get(`${backendUrl}/api/gallery-category-list/${created_by}`);
        if (response.data.success === true) {
          // Map id to name for display and store id in state
          const options = response.data.gallary_categories.map((option) => ({
            id: option.id,
            name: option.name,
          }));
          if(options.length!=0){
            setGalleryTypeOptions(options);
          }
        }
      } catch (error) {
        console.error('Error fetching gallery:', error);
      }
    }

  }



  const handlesearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };


  useEffect(() => {
    fetchGalleryTypeOptions();
  }, [created_by]);

  const RemoveGallery = () => {
    if (deletedids.length !== 0) {
      // Ask for user confirmation before proceeding with deletion
      const userConfirmed = window.confirm("Are you sure you want to delete the gallery items? This action cannot be undone.");
      
      if (userConfirmed) {
        // User confirmed, proceed with deletion
        axios.post(`${backendUrl}/api/gallery-delete`, { delete_ids: deletedids })
          .then(response => {
            setCallbackStatus(prevStatus => !prevStatus);
          })
          .catch(error => {
            console.error("Error deleting gallery items:", error);
          });
      } else {
        // User canceled, do nothing
        console.log("Gallery deletion canceled by user.");
      }
    }
  };


  useEffect(() => {
    const filterGallery = async () => {
      if (created_by != null) {
        setIsLoading(true);
        try {
          const response = await axios.post(`${backendUrl}/api/gallery-fetch`, {
            GalleryTypeId: GalleryTypeId,
            searchTerm: searchTerm,
            created_by: created_by  // Assuming created_by is defined somewhere
          });
    
          if (response.data.success === true) {
            SetGalleryData(response.data.gallery); // Corrected function name to setGalleryData
          }
        } catch (error) {
          console.error('Error fetching gallery:', error);
        } finally {
          setIsLoading(false); // Removed the arrow function syntax from finally block
        }
      }
    };
    
  
    filterGallery();
  }, [created_by,GalleryTypeId, searchTerm,callbackStatus]); 

  return (
    <>
      <div className='row mb-4 align-items-center options-group'>
        <div className='col-12 col-lg-6 mb-2 mb-md-4'>
          <div className='d-flex align-items-center gap-3'>
            <Link to='/gallery/settings'>
              <button className='custom-btn add-gallery-set-btn'>
                ADD GALLERY SET
              </button>
            </Link>
            <button className='custom-btn-outline gallery-delete-btn'  onClick={() => RemoveGallery()}>
              DELETE
            </button>
          </div>
        </div>

        <div className='col-12 col-lg-6 mb-2 mb-md-4'>
          <div className='d-flex align-items-center flex-column flex-md-row gap-4'>
          <div className='w-100'>
            <div className='accordion position-relative w-100'>
              <div className={`accordion-header w-100 ${GalleryTypeOptionsOpen ? "active" : ""}`} onClick={() => setGalleryTypeOptionsOpen(!GalleryTypeOptionsOpen)}>
                <span className='selected-option' galleryid={GalleryTypeId?GalleryTypeId:0}>{GalleryType ? GalleryType : "All"}</span>
                <span className='icon overflow-hidden'>
                  <i className={GalleryTypeOptionsOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} />
                </span>
              </div>
              <div className={`accordion-content ${GalleryTypeOptions.length>4 ? "category_scroll" : ""} ${GalleryTypeOptionsOpen ? "active" : ""}`}>
              <div className="option" onClick={() => { setGalleryTypeId(0); setGalleryType('All'); setGalleryTypeOptionsOpen(false); }} >All</div>
                {GalleryTypeOptions.map((option) => (
                  <div key={option.id} className='option' onClick={() => { setGalleryTypeId(option.id); setGalleryType(option.name); setGalleryTypeOptionsOpen(false); }}>
                    {option.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
            <div className='input_group'>
              <span className='icon'>
                <i className='fa fa-search'></i>
              </span>
              <input type='text' placeholder='Search'  onChange={handlesearchInputChange}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
