import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props)
        const udata = localStorage.getItem('user')
        const odata = JSON.parse(udata)
        let loggedIN = true
        if (udata == null) {
            loggedIN = false
        }
        this.state = {
            user: odata ? odata.user : null,
            loggedIN
        }
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    toggleSidebar() {
        var flag = document.getElementById("menu").value;
        if (flag == 0) {
            document.getElementById("mainsidebar").classList.add("sidebar-open");
            document.getElementById("sidebar").classList.add("open");
            document.getElementById("maincontent").classList.add("open");
            document.getElementById("menu").value = 1;
        } else {
            document.getElementById("mainsidebar").classList.remove("sidebar-open");
            document.getElementById("sidebar").classList.remove("open");
            document.getElementById("maincontent").classList.remove("open");
            document.getElementById("menu").value = 0;
        }
    }

    getInitials(name) {
        const names = name.split(' ');
        const initials = names.map(name => name !="null" ? name.charAt(0).toUpperCase() : "").join('');
        return initials;
    }

    render() {
        if (this.state.loggedIN === false) {
            return <Navigate to="/sign-in" />
        }

        const initials = this.state.user ? this.getInitials(`${this.state.user.first_name} ${this.state.user.last_name}`) : 'RE';

        return (
            <nav className='navbar navbar-expand bg-light bg-white sticky-top px-lg-5 px-2 py-0'>
                <Link to='/' className='navbar-brand d-flex d-none d-lg-none me-4'>
                    <h3 className='m-0'>LOGO</h3>
                </Link>
                {/* ====== Hamburger Button to Toggle The side Bar =========== */}
                <button
                    className='sidebar-toggler flex-shrink-0 bg-transparent border-0'
                    onClick={this.toggleSidebar}>
                    <i className='fa fa-bars' />
                </button>
                <input type='hidden' id='menu' value='0' />
                <form className='d-none d-flex align-items-center d-md-flex ms-4'>
                    <h3>Customer Business Name</h3>
                </form>
                <div className='navbar-nav align-items-center ms-auto'>
                    <div className='money-container nav-item dropdown mx-lg-5 mx-md-2 mx-sm-2'>
                        <Link to='#' className='nav-link py-3' data-bs-toggle='dropdown'>
                            <span className='d-none d-lg-inline-flex me-lg-1'>Earn Cash</span>
                            <i className='fa-solid fa-sack-dollar ms-1' />
                        </Link>
                    </div>
                    <div className='nav-item dropdown' id='top-user-nav'>
                        <Link to='#' className='nav-link dropdown' data-bs-toggle='dropdown'>
                            <span
                                className='d-inline-flex p-lg-3 p-md-2 p-2 rounded-circle lead'
                                id='user-av'>
                                <strong>{initials}</strong>
                            </span>
                        </Link>
                        <div
                            className='dropdown-menu bg-white shadow-sm border-0 py-2 rounded-0 rounded-bottom'
                            id='cm-dropdown'>
                            <Link to='#' className='dropdown-item py-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='me-2 p-3 rounded-circle text-black' id='user-dp'>
                                        {initials}
                                    </div>
                                    <span>
                                        <div>
                                            <strong>{this.state.user.first_name} {this.state.user.last_name}</strong>
                                        </div>
                                        <div className='mt-2'>Company Name</div>
                                    </span>
                                </div>
                            </Link>
                            <hr className='my-1 py-0' />
                            <Link to='/company-setting/my-account' className='dropdown-item py-2'>
                                <i className='fa-solid fa-user me-2' />
                                My Account
                            </Link>
                            <Link to='/company-setting/my-company' className='dropdown-item py-2'>
                                <i className='fa-solid fa-hotel me-2' />
                                Company Settings
                            </Link>
                            <div className='dropdown-item py-2'>
                                <div className='d-flex align-items-center justify-content-between mt-5'>
                                    <Link to='/logout' className='text-dark'>
                                        Logout
                                    </Link>
                                    <Link to='#' className='text-dark'>
                                        Help Center
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header;
