import axios from 'axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
export const createContract = async (title, pages) => {
  try {
    const response = await axios.post(`${backendUrl}/contracts`, {
      title,
      pages
    });
    return response.data;
  } catch (error) {
    console.error('Error creating contract:', error);
    throw error;
  }
};

export const deleteContract = async (emailId) => {
  try {
      await axios.delete(`${backendUrl}/contracts/${emailId}`);
  } catch (error) {
      throw new Error(`Failed to delete email with ID ${emailId}: ${error.message}`);
  }
};
