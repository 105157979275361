import React, { useState } from "react";
import CommonDropdownMenuTwo from "../../../../../Shared/CommonDropdownMenuTwo";

export default function Preferences() {
  const options = ["1 day", "2 days", "3 days", "1 Week", "2 Weeks"];
  /*================ Start: Notification dummy data================ */
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      text: "Notify me by email if client has not viewed an email I send with a file",
      checkValue: true,
    },
    {
      id: 2,
      text: "Notify me by email if a file I've sent has not been signed or paid",
      checkValue: true,
    },
    {
      id: 3,
      text: "Notify me by email if a file created for an event remains unsent after this period",
      checkValue: true,
    },
    {
      id: 4,
      text: "Set a reminder to notify me by email before my event dates",
      checkValue: true,
    },
    {
      id: 5,
      text: "Display a notification when projects have conflicting dates",
      checkValue: true,
    },
    // {
    //   id: 6,
    //   text: "Display automated emails that are sent to my clients in my project activity feed",
    //   checkValue: true,
    // },
    // Add more notification objects as needed
  ]);
  const handleNotificationToggle = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id
          ? { ...notification, checkValue: !notification.checkValue }
          : notification
      )
    );
  };
  /*================ End: Notification dummy data================ */

  return (
    <>
      <div className='tab-pane fade' id='content2'>
        <div className='companysetting-window-container '>
          <div className='row'>
            <div className='mb-3 row col-md-12 bg-white company-setting-box rounded p-lg-3 p-md-2 p-2 my-4'>
              {/* ============= Start: Company Notifications Header Section =============*/}
              <div className='mb-3 col-md-8 pl-5 border-left '>
                <p className='fw-bolder fs-4'>Company Notifications </p>
                <p className='fs-14'>
                  {" "}
                  Select the notifications that you and team members will
                  receive via email{" "}
                </p>
              </div>
              {/* ============= End: Company Notifications Header Section =============*/}
              <hr />

              {/* Loop through notifications */}
              {notifications.map((notification) => (
                <div key={notification.id} className='card border-0'>
                  <div className='row d-flex flex-co flex-column-reverse flex-md-row my-1 align-items-center'>
                    <div className='col-md-1'>
                      <div className='form-check form-switch mt-2 mt-md-0'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          id={`notification-switch-${notification.id}`}
                          checked={notification.checkValue}
                          onChange={() =>
                            handleNotificationToggle(notification.id)
                          }
                        />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <CommonDropdownMenuTwo options={options} />
                    </div>
                    <div className='col-md-8'>
                      <span style={{ fontWeight: "500", fontSize: "14px" }}>
                        {notification.text}
                      </span>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
