import React, { useState, useEffect } from "react";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import "./Email.css";

const CreateEmailTemplate = ({ isEdit, emailTemplateId }) => {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [editorInstance, setEditorInstance] = useState(null);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (isEdit && emailTemplateId) {
      // Fetch the email template data by ID
      axios.get(`${backendUrl}/api/email-templates/${emailTemplateId}`)
        .then(response => {
          setSubject(response.data.title);
          setContent(response.data.content);
        })
        .catch(error => {
          console.error('There was an error fetching the email template data:', error);
          Swal.fire("Error", "Failed to fetch email template.", "error");
        });
    }
  }, [isEdit, emailTemplateId]);

  const editorOptionsShow = {
    height: 200,
    fontSize: ["12", "14", "16", "18", "20"],
    buttonList: [
      [
        "codeView",
        "paragraphStyle",
        "bold",
        "underline",
        "italic",
        "strike",
        "table",
        "link",
        "horizontalRule",
        "list",
        "align",
        "hiliteColor",
        "image",
        "video",
      ],
    ],
    imageRotation: false,
    colorList: [
      [
        "#828282",
        "#FF5400",
        "#676464",
        "#F1F2F4",
        "#FF9B00",
        "#F00",
        "#fa6e30",
        "#000",
        "rgba(255, 153, 0, 0.1)",
        "#FF6600",
        "#0099FF",
        "#74CC6D",
        "#FF9900",
        "#CCCCCC",
      ],
    ],
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleEditorChange = (content) => {
    setContent(content);
  };

  const handleSubmit = () => {
    const emailTemplate = {
      title: subject,
      content: editorInstance.getContents(),
    };

    if (isEdit && emailTemplateId) {
      axios.put(`${backendUrl}/api/email-templates/${emailTemplateId}`, emailTemplate)
        .then(response => {
          Swal.fire("Updated!", "Your email template has been updated.", "success");
          navigate('/email');
        })
        .catch(error => {
          console.error('There was an error updating the email template:', error);
          Swal.fire("Error", "Failed to update email template.", "error");
        });
    } else {
      axios.post(`${backendUrl}/api/email-templates`, emailTemplate)
        .then(response => {
          Swal.fire("Created!", "Your email template has been created.", "success");
          navigate('/email');
        })
        .catch(error => {
          console.error('There was an error creating the email template:', error);
          Swal.fire("Error", "Failed to create email template.", "error");
        });
    }
  };

  return (
    <div className='email-template-container'>
      <div className='d-flex align-items-center bg-white pt-2 ps-4 border shadow-sm '>
        <h6 className='pe-3'>Subject</h6>
        <input
          className='w-100 py-3 border-0 outline-none'
          type='text'
          placeholder="What's the topic?"
          value={subject}
          onChange={handleSubjectChange}
        />
      </div>
      <SunEditor
        setOptions={editorOptionsShow}
        getSunEditorInstance={setEditorInstance}
        placeholder='You can edit your message...'
        onChange={handleEditorChange}
        setContents={content}
      />
      <div className='d-flex justify-content-center pt-4'>
        <button
          type='button'
          className='email-template-done-btn'
          onClick={handleSubmit}
        >
          {isEdit ? "UPDATE" : "DONE"}
        </button>
      </div>
    </div>
  );
};

export default CreateEmailTemplate;
