import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// Import Routing Components For Linking to Web pages
import { Link } from "react-router-dom";

function MonthAvailability() {
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    //  ================= *****  TEMPLATES BODY JSX ***** =================

    <>
      <div className='container-fluid p-lg-5 p-md-5 p-2'>
        <div className='row'>
          {[...Array(12)].map((_, index) => {
            const month = index; // Month indexes are zero-based
            const date = new Date(2024, month, 1); // Create a date object for the specific month in 2024
            return (
              <div key={index} className='col-md-4'>
                <div className='mb-3'>
                  <Link to={`/month-calendar?date=${date}`}>
                    <Calendar value={date} />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>

    // ================= *****  TEMPLATES BODY JSX ***** =================
  );
}

export { MonthAvailability }; /* ========================= We had to remove default. */
