import { createContext, useContext, useState } from "react";

const EditableContentContext = createContext();

export const EditableContentProvider = ({ children }) => {
  /* This is for the add product page */
  const [editableProductContent, setEditableProductContent] =
    useState("Untitled Item");

  /* This is for the create package page */
  const [editablePackageContent, setEditablePackageContent] =
    useState("Untitled Package");

  /* This is for the gallery page */
  const [editableGalleryContent, setEditableGalleryContent] =
    useState("Untitled Gallery");

  /* This is for the gallery page */
  const [editableGalleryMessageContent, setEditableGalleryMessageContent] =
    useState("Write a message or direction for your customer here.");

  /* This is for the add product page */
  const handleProductContentChange = (newContent) => {
    setEditableProductContent(newContent);
  };

  /* This is for the create package page */
  const handlePackageContentChange = (newContent) => {
    setEditablePackageContent(newContent);
  };

  /* This is for the gallery page */
  const handleGalleryContentChange = (newContent) => {
    setEditableGalleryContent(newContent);
  };

  /* This is for the gallery page */
  const handleGalleryMessageContentChange = (newContent) => {
    setEditableGalleryMessageContent(newContent);
  };

  return (
    <EditableContentContext.Provider
      value={{
        /* This is for the add product page */

        editableProductContent,
        handleProductContentChange,

        /* This is for the create package page */

        editablePackageContent,
        handlePackageContentChange,

        /* This is for the gallery page */
        editableGalleryContent,
        handleGalleryContentChange,

        editableGalleryMessageContent,
        handleGalleryMessageContentChange,
      }}>
      {children}
    </EditableContentContext.Provider>
  );
};

export const useEditableContent = () => {
  return useContext(EditableContentContext);
};
