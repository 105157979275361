import React, { useEffect, useState } from "react";

const UnitOption = ({ onChange, rawselectedOption }) => {
  // State variables
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("UNIT");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Initialize options with default values
    setOptions(["Item", "Hour", "Day", "Week", "Month", "None"]);
    if (rawselectedOption) setSelectedOption(rawselectedOption);
    console.log(rawselectedOption);
  }, [rawselectedOption]);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle click on existing option
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Call the onChange function passed as a prop
    if (onChange) onChange(option);
  };

  return (
    <div className="accordion ">
      <div
        className={`accordion-header  ${isOpen ? "active" : ""}`}
        onClick={toggleDropdown}
      >
        <span className="selected-option ">{selectedOption}</span>
        <span className="icon overflow-hidden"></span>
      </div>
      <div
        className={`accordion-content unitAccordionContent ${
          isOpen ? "active" : ""
        }`}
      >
        {/* Render existing options */}
        {options.map((option, index) => (
          <div
            key={index}
            className="option"
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnitOption;
