import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const fetchPackages = async () => {
  try {
    const response = await axios.get(`${backendUrl}/packages`);
    return response.data;
  } catch (error) {
    console.error('Error fetching packages:', error);
    throw error;
  }
};

export const deletePackage = async (packageId) => {
    try {
        await axios.delete(`${backendUrl}/packages/${packageId}`);
    } catch (error) {
        throw new Error(`Failed to delete package with ID ${packageId}: ${error.message}`);
    }
};
export { fetchPackages };
