import React from "react";
import CompanyBrand from "./Tab-Content/Company-Brand/CompanyBrand";
import PaymentMethods from "./Tab-Content/Payment-Methods/PaymentMethods";
import Preferences from "./Tab-Content/Preferences/Preferences";
import Team from "./Tab-Content/Team/Team";
import TabNav from "./Tab-Nav/TabNav";

export default function CompanyTab() {
  return (
    <>
      <div className='row g-lg-5 g-md-5 g-3 justify-content-center'>
        <div className='companysetting-window-container '>
          <div className='container mt-5'>
            <div className='row'>
              {/* =============== Tab Nav Links =============== */}
              <div className='col-md-3'>
                <TabNav />
              </div>

              <div className='col-md-9'>
                <div className='tab-content'>
                  {/* =============== Start: Company Brand =============== */}
                  <CompanyBrand />
                  {/* =============== End: Company Brand =============== */}

                  {/* =============== Start: Preferences =============== */}
                  <Preferences />
                  {/* =============== End: Preferences =============== */}

                  {/* =============== Start: Payment Methods =============== */}
                  <PaymentMethods />
                  {/* =============== End: Payment Methods =============== */}

                  {/* =============== Start: Team =============== */}
                  <Team />
                  {/* =============== End: Team =============== */}
                </div>
              </div>
            </div>
          </div>
          {/* Company tab left end  */}
        </div>
      </div>
    </>
  );
}
