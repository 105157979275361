import React, { useState,useEffect } from "react";

export default function CreateNewClientForm({ clientsDetailes, setClientdetailes,onClose }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validEmail, setValidEmail] = useState(false); // State to track email validity

  const addNewClient = (newClient) => {
    setClientdetailes(newClient);
  };

  useEffect(() => {
    const isValid = /\S+@\S+\.\S+/.test(email); // Basic email regex validation
    setValidEmail(isValid);
  }, [email]);
 
  const handleAddClick = () => {
    // Basic validation
    if (!firstName || !lastName || !email || !phoneNumber) {
      alert("All fields are required!");
      return;
    }

    if (!validEmail) {
      alert("Please enter a valid email address!");
      return;
    }

    const newClient = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber
    };

    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");

    addNewClient(newClient);
    onClose();
  };

  return (
    <form>
      <div className="row">
        <div className="col-md-6">
          <label>First Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Last Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="col-md-6 mt-3">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-md-6 mt-3">
          <label>Phone Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>

        <div className="col-md-12 mt-5 p-4 text-end">
          <button
            type="button"
            className="btn but-primary"
            data-bs-dismiss={firstName && lastName && email && phoneNumber ? 'modal' : ''}
            onClick={handleAddClick}
          >
            Add
          </button>
        </div>

      </div>
    </form>
  );
}
