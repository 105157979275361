import axios from "axios";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEditableContent } from "../../Context/EditableContentContext";
import UnitOption from "../CreateInvoice/UnitOption";
import CommonDropdownMenu from "../Shared/CommonDropdownMenu";

function AddPackage() {
  const { editablePackageContent, handlePackageContentChange } =
    useEditableContent();

  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(editablePackageContent);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [options, setOptions] = useState([]);
  //const options = ["Photo Booth", "Backdrop", "Props", "Additional Items"];
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState(0);
  const [category, setCategory] = useState(options[0]);
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("UNIT"); // Declare selectedOption state
  const [tax, setTax] = useState(""); // Declare tax state
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    handlePackageContentChange("Untitled Package");
    //setNewContent("Untitled Item");
    setNewContent(editablePackageContent);

    axios
      .get(`${backendUrl}/api/categories`)
      .then((response) => {
        setOptions(response.data);
        if (response.data.length > 0) {
          setCategory(response.data[0].name);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the categories!", error);
      });
  }, []);

  const handleInputChange = (e) => {
    setNewContent(e.target.value);
    handlePackageContentChange(e.target.value);
  };

  const handleUnitOptionChange = (option) => {
    setSelectedOption(option); // Assuming you have a state variable for the selected unit option
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePackageContentChange(newContent);
      setIsEditing(false);
    }
  };

  const handleClick = () => {
    handlePackageContentChange(newContent);
    setIsEditing(true);
  };

  const handleBlur = () => {
    handlePackageContentChange(newContent);
    setIsEditing(false);
  };

  const handleQtyChange = (e) => {
    setQty(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const onDrop = (acceptedFiles, setImage) => {
    const file = acceptedFiles[0];
    setImage(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  };

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: (files) => onDrop(files, setImage1),
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: (files) => onDrop(files, setImage2),
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } =
    useDropzone({
      onDrop: (files) => onDrop(files, setImage3),
      accept: "image/*",
      multiple: false,
    });

  const handleRemoveImage = (e, setImage) => {
    e.stopPropagation();
    setImage(null);
  };

  const handleTaxCheckboxChange = (e) => {
    // If the checkbox is checked, set tax to a certain value, otherwise set it to an empty string
    const newTax = e.target.checked ? 1 : 0;
    setTax(newTax);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    setNewContent(editablePackageContent);
    formData.append("name", editablePackageContent);
    formData.append("description", description);
    formData.append("quantity", qty);
    formData.append("price", price);
    formData.append("category", category);
    formData.append("unit_option", selectedOption); // Add selected unit option
    formData.append("tax", tax); // Add tax value
    if (image1) formData.append("image1", image1);
    if (image2) formData.append("image2", image2);
    if (image3) formData.append("image3", image3);

    try {
      const response = await axios.post(
        `${backendUrl}/api/packages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Package added successfully!",
      });
      handlePackageContentChange("Untitled Package");
      navigate("/packages");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = Object.values(error.response.data.errors)
          .flat()
          .join("\n");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessages,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error adding the package!",
        });
      }
      console.error("There was an error adding the package!", error);
    }
  };

  return (
    <div className='container-fluid'>
      <div
        id='product-window'
        className='row g-lg-5 g-md-5 g-3 justify-content-center'>
        <div className='product-window-container col-sm-12 col-md-9 col-xl-9 bg-white rounded p-lg-5 p-md-3 p-2 shadow-sm'>
          <div className='product-window-title-container d-flex'>
            <div className='product-window-heading w-50'>
              <h5>Package Item</h5>
            </div>
            <div className='item-cell-container w-50 d-flex justify-content-around'>
              <div className='item-cell'>QTY</div>
              <div className='item-cell'>UNIT</div>
              <div className='item-cell'>TAX</div>
              <div className='item-cell'>PRICE</div>
            </div>
          </div>
          <hr className='my-3' />
          <div
            className='product-body rounded p-lg-3 p-md-2 p-2 my-4'
            id='p-details'
            style={{ background: "#f5f5f5", border: "2px solid #e6e6e6" }}>
            <div className='title-container d-flex mb-3 flex-column flex-md-row'>
              <div className='item-heading w-100'>
                <div onClick={handleClick}>
                  {isEditing ? (
                    <input
                      type='text'
                      className='border rounded outline-none'
                      value={editablePackageContent}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      onBlur={handleBlur}
                      autoFocus
                    />
                  ) : (
                    <h5>{editablePackageContent}</h5>
                  )}
                </div>
              </div>
              <div className='item-cell-container w-100 w-md-50 d-flex justify-content-around mt-2'>
                <div className='item-cell'>
                  <input
                    type='number'
                    value={qty}
                    onChange={handleQtyChange}
                    className='input-qyt'
                    placeholder='QTY'
                  />
                </div>
                <div className='item-cell position-relative'>
                  <UnitOption onChange={handleUnitOptionChange} />
                </div>
                <div className='item-cell'>
                  <input
                    className='form-check-input mt-0'
                    type='checkbox'
                    defaultValue=''
                    aria-label='Checkbox for following text input'
                    onChange={handleTaxCheckboxChange}
                  />
                </div>
                <div className='item-cell'>
                  <input
                    type='number'
                    value={price}
                    onChange={handlePriceChange}
                    className='input-qyt text-end'
                  />
                </div>
              </div>
            </div>
            <textarea
              type='text'
              value={description}
              onChange={handleDescriptionChange}
              placeholder='Product Description'
              className='border-0 text-black bg-transparent placeholder-font'
              style={{
                outline: "none",
                minHeight: "226px",
                whiteSpace: "pre-wrap",
                width: "100%",
              }}
            />
          </div>
          <div className='col-md-6'>
            <label className='mb-2'>Package Category</label>
            <CommonDropdownMenu
              options={options}
              onChange={handleCategoryChange}
            />
          </div>
          <div className='row g-lg-3 g-sm-3 g-2 justify-content-center mt-4'>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0' {...getRootProps1()}>
                {image1 ? (
                  <div className='position-relative'>
                    <img
                      src={image1.preview}
                      className='img-fluid'
                      alt='Preview'
                      draggable={false}
                      style={{ userSelect: "none", pointerEvents: "none" }}
                    />
                    <button
                      className='btn btn-danger btn-sm position-absolute top-0 end-0'
                      onClick={(e) => handleRemoveImage(e, setImage1)}>
                      X
                    </button>
                  </div>
                ) : (
                  <img
                    src='img/file-upload.png'
                    className='img-fluid'
                    alt='Upload Placeholder'
                  />
                )}
                <input {...getInputProps1()} className='input-upload-btn' />
              </div>
            </div>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0' {...getRootProps2()}>
                {image2 ? (
                  <div className='position-relative'>
                    <img
                      src={image2.preview}
                      className='img-fluid'
                      alt='Preview'
                      draggable={false}
                      style={{ userSelect: "none", pointerEvents: "none" }}
                    />
                    <button
                      className='btn btn-danger btn-sm position-absolute top-0 end-0'
                      onClick={(e) => handleRemoveImage(e, setImage2)}>
                      X
                    </button>
                  </div>
                ) : (
                  <img
                    src='img/file-upload.png'
                    className='img-fluid'
                    alt='Upload Placeholder'
                  />
                )}
                <input {...getInputProps2()} className='input-upload-btn' />
              </div>
            </div>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0' {...getRootProps3()}>
                {image3 ? (
                  <div className='position-relative'>
                    <img
                      src={image3.preview}
                      className='img-fluid'
                      alt='Preview'
                      draggable={false}
                      style={{ userSelect: "none", pointerEvents: "none" }}
                    />
                    <button
                      className='btn btn-danger btn-sm position-absolute top-0 end-0'
                      onClick={(e) => handleRemoveImage(e, setImage3)}>
                      X
                    </button>
                  </div>
                ) : (
                  <img
                    src='img/file-upload.png'
                    className='img-fluid'
                    alt='Upload Placeholder'
                  />
                )}
                <input {...getInputProps3()} className='input-upload-btn' />
              </div>
            </div>
          </div>
        </div>
        <div className='text-center pt-2'>
          <button
            id='submit-btn'
            type='button'
            className='px-5 rounded-pill btn btn-primary btn-lg text-uppercase'
            onClick={handleSubmit}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export { AddPackage };
