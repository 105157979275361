import { useState } from "react";
import BrandElement from "./BrandEelement";
import EmailSignature from "./EmailSignature";

export default function CompanyBrand() {
  const [formData, setFormData] = useState({
    companyEmail: "",
    phoneNumber: "",
    companyWebsite: "",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [companyName, setCompanyName] = useState("Company Name");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleBlur = (event) => {
    setCompanyName(event.target.value);
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <>
      <div className='tab-pane fade show active company_brand' id='content1'>
        <div className='justify-content-center my-3'>
          {/*================= Start: Company Brand Header Part =================*/}
          <p className='title fw-bolder fs30 my-4'>
            BUILD UP YOUR PROFESSIONAL PRESENCE{" "}
          </p>
          <p className=' fs16  m-0'>Only visible to admin</p>
          {/*================= End: Company Brand Header Part =================*/}

          {/* ================= Start:Company Brand Sections ================= */}
          <div className='companysetting-window-container '>
            <div className=' mt-2'>
              <div className='row'>
                {/*============== Start: Company Brand Section-1 ============== */}
                <div className='mb-3 row col-md-12 bg-white company-setting-box p-lg-3 p-md-2 p-2 my-4 company_brand_form'>
                  <div className='mb-3 col-md-4 pl-5 company_name'>
                    {isEditing ? (
                      <input
                        type='text'
                        value={companyName}
                        onBlur={handleBlur}
                        onChange={(e) => setCompanyName(e.target.value)}
                        autoFocus
                        className='editable'
                      />
                    ) : (
                      <>
                        <h5>{companyName}</h5>
                      </>
                    )}
                    <p> Ruben Esquivel </p>
                    <button
                      className='icon border-0 bg-transparent'
                      onClick={handleEditClick}>
                      <i className='fas fa-pen'></i>
                    </button>
                  </div>

                  <div className='mb-3 col-md-7'>
                    <div className='mb-3 '>
                      <label htmlFor='companyEmail' className='form-label'>
                        Company Email
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Company Email'
                        id='companyEmail'
                        name='companyEmail'
                        value={formData.companyEmail}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className='mb-3 '>
                      <label htmlFor='phoneNumber' className='form-label'>
                        Phone Number
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='phoneNumber'
                        name='phoneNumber'
                        placeholder='Phone Number'
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className='mb-3 '>
                      <label htmlFor='companyWebsite' className='form-label'>
                        Company Website
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='companyWebsite'
                        name='companyWebsite'
                        placeholder='Company Website'
                        value={formData.companyWebsite}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*============== End: Company Brand Section-1 ============== */}

                {/*============== Start: Company Brand Section-2 ============== */}

                <BrandElement />
                {/*============== End: Company Brand Section-2 ============== */}

                {/*============== Start: Company Brand Section-3 ============== */}

                <EmailSignature />
                {/*============== Emd: Company Brand Section-3 ============== */}
              </div>
            </div>
          </div>
          {/* ================= End:Company Brand Sections ================= */}
        </div>
      </div>
    </>
  );
}
