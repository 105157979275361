import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ContractHeader.css";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/titleSlice";

// ContactHeader component for displaying a header with navigation and buttons
const ContactHeader = ({ toggleSidebar }) => {
  // const [title, setTitle] = useState("Untitled Contract");
  const [isEditing, setIsEditing] = useState(false);

  const title = useSelector((state) => state.title.title);
  const dispatch = useDispatch();


  const handleTitleClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleTitleChange = (e) => {
    dispatch(setTitle(e.target.value));
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
    }
  };

  return (
    <nav className='navbar navbar-expand nav_mobile flex-column flex-md-row bottom-header-main px-lg-5 px-2 py-2'>
      <div className='d-flex align-items-center d-md-flex ms-md-2'>
        <div className='d-flex align-items-center bottom-header'>
          {/* ====== Hamburger Button to Toggle The side Bar =========== */}
          <button
            className='sidebar-toggler flex-shrink-0 bg-transparent border-0 me-3'
            onClick={toggleSidebar}>
            <i className='fa fa-bars' />
          </button>

          <Link to='/templates' onClick={(e) => e.preventDefault()}>
            <div className='title-container' onClick={handleTitleClick}>
              {/* Title */}
              {isEditing ? (
                <input
                  type='text'
                  value={title}
                  onChange={handleTitleChange}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  autoFocus
                  className='editable-title'
                />
              ) : (
                <h5 className='untitled-product-title'>{title}</h5>
              )}
            </div>
            {/* Folder icon and preview text */}
            <p className='d-flex align-items-center'>
              <i className='far fa-folder'></i>
              <span className='preview-text'>Templates</span>
            </p>
          </Link>
        </div>
      </div>
      {/* <div className='navbar-nav align-items-center ms-md-auto'>
        <div className='py-3 button_group gy-3'>
          <button className='bg-white'>PREVIEW</button>
          <button>USE TEMPLATE</button>
        </div>
      </div> */}
    </nav>
  );
};

export default ContactHeader;
