import axios from "axios";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useEditableContent } from "../../Context/EditableContentContext";
import UnitOption from "../CreateInvoice/UnitOption";
import CommonDropdownMenu from "../Shared/CommonDropdownMenu";

function EditProduct() {
  const { productId } = useParams();
  const { editableProductContent, handleProductContentChange } =
    useEditableContent();
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [options, setOptions] = useState([]);
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState(0);
  const [category, setCategory] = useState(options[0]);
  const [cnategory, setCnategory] = useState(options[0]);
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); // State for unit option
  const [tax, setTax] = useState(""); // State for tax
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setNewContent(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleProductContentChange(editableProductContent);
      setIsEditing(false);
    }
  };

  const handleClick = () => {
    handleProductContentChange(editableProductContent);
    setIsEditing(true);
  };

  const handleBlur = () => {
    handleProductContentChange(editableProductContent);
    setIsEditing(false);
  };

  const handleQtyChange = (e) => {
    setQty(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (value) => {
    setCnategory(value);
  };

  const onDrop = (acceptedFiles, setImage) => {
    const file = acceptedFiles[0];
    setImage(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  };

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: (files) => onDrop(files, setImage1),
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: (files) => onDrop(files, setImage2),
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } =
    useDropzone({
      onDrop: (files) => onDrop(files, setImage3),
      accept: "image/*",
      multiple: false,
    });

  const handleRemoveImage = (e, setImage) => {
    e.stopPropagation();
    setImage(null);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", editableProductContent);
    formData.append("description", description);
    formData.append("quantity", qty);
    formData.append("price", price);
    formData.append("price", price);
    formData.append("unit_option", selectedValue);
    formData.append("category", selectedCategory ? selectedCategory : category);
    if (tax) formData.append("tax", tax); // Add tax value

    if (image1 && image1 instanceof File) formData.append("image1", image1);
    if (image2 && image2 instanceof File) formData.append("image2", image2);
    if (image3 && image3 instanceof File) formData.append("image3", image3);

    try {
      const response = await axios.post(
        `${backendUrl}/api/products/${productId}/edit`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Product updated successfully!",
      });
      navigate("/products");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = Object.values(error.response.data.errors)
          .flat()
          .join("\n");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessages,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error updating the product!",
        });
      }
      console.error("There was an error updating the product!", error);
    }
  };

  const handleTaxCheckboxChange = (e) => {
    // If the checkbox is checked, set tax to a certain value, otherwise set it to an empty string
    const newTax = e.target.checked ? 1 : 0;
    setTax(newTax);
  };

  // Callback function to receive selected category from CommonDropdownMenu
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  // Callback function to update the selected value
  const handleSelectedValueChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    axios
      .get(`${backendUrl}/api/categories`)
      .then((response) => {
        setOptions(response.data);
        if (response.data.length > 0) {
          //setCategory(response.data[0].name);
          fetchProductDetails();
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the categories!", error);
      });

    const fetchProductDetails = async () => {
      try {
        // Fetch product details from the API
        const response = await axios.get(
          `${backendUrl}/api/products/${productId}/edit`
        );
        const productData = response.data.product; // Assuming the response data is wrapped in a 'product' key
        // Update state variables with product details
        setNewContent(productData.name);
        setQty(productData.quantity);
        setPrice(productData.price);
        setDescription(productData.description);
        setCategory(productData.category);
        //setSelectedCategory(productData.category);
        setSelectedOption(productData.unit_option); // Set unit option
        setSelectedValue(productData.unit_option); // Set unit option
        setTax(productData.tax); // Set tax
        handleProductContentChange(productData.name);
        // Assuming you have URLs for images in the response data
        if (productData.image1) {
          setImage1({
            preview: `${backendUrl}/storage/${productData.image1}`,
          });
        }
        if (productData.image2) {
          setImage2({
            preview: `${backendUrl}/storage/${productData.image2}`,
          });
        }
        if (productData.image3) {
          setImage3({
            preview: `${backendUrl}/storage/${productData.image3}`,
          });
        }
        
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };
  }, [productId]);

  return (
    <div className='container-fluid'>
      <div
        id='product-window'
        className='row g-lg-5 g-md-5 g-3 justify-content-center'>
        <div className='product-window-container col-sm-12 col-md-9 col-xl-9 bg-white rounded p-lg-5 p-md-3 p-2 shadow-sm'>
          <div className='product-window-title-container d-flex'>
            <div className='product-window-heading w-50'>
              <h5>Product Item</h5>
            </div>
            <div className='item-cell-container w-50 d-flex justify-content-around'>
              <div className='item-cell'>QTY</div>
              <div className='item-cell'>UNIT</div>
              <div className='item-cell'>TAX</div>
              <div className='item-cell'>PRICE</div>
            </div>
          </div>
          <hr className='my-3' />
          <div
            className='product-body rounded p-lg-3 p-md-2 p-2 my-4'
            id='p-details'
            style={{ background: "#f5f5f5", border: "2px solid #e6e6e6" }}>
            <div className='title-container d-flex mb-3 flex-column flex-md-row'>
              <div className='item-heading w-100'>
                <div onClick={handleClick}>
                  {isEditing ? (
                    <input
                      type='text'
                      className='border rounded outline-none'
                      value={editableProductContent}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      onBlur={handleBlur}
                      autoFocus
                    />
                  ) : (
                    <h5>{editableProductContent}</h5>
                  )}
                </div>
              </div>
              <div className='item-cell-container w-100 w-md-50 d-flex justify-content-around mt-2'>
                <div className='item-cell'>
                  <input
                    type='number'
                    value={qty}
                    onChange={handleQtyChange}
                    className='input-qyt'
                    placeholder='QTY'
                  />
                </div>
                <div className='item-cell position-relative'>
                  {selectedOption && (
                    <UnitOption
                      onChange={handleSelectedValueChange}
                      rawselectedOption={selectedOption}
                    />
                  )}
                </div>
                <div className='item-cell'>
                  <input
                    className='form-check-input mt-0'
                    type='checkbox'
                    checked={tax == 1}
                    defaultValue=''
                    aria-label='Checkbox for following text input'
                    onChange={handleTaxCheckboxChange}
                  />
                </div>
                <div className='item-cell'>
                  <input
                    type='number'
                    value={price}
                    onChange={handlePriceChange}
                    className='input-qyt text-end'
                  />
                </div>
              </div>
            </div>
            <textarea
              type='text'
              value={description}
              onChange={handleDescriptionChange}
              placeholder='Product Description'
              className='border-0 text-black bg-transparent placeholder-font'
              style={{
                outline: "none",
                minHeight: "226px",
                whiteSpace: "pre-wrap",
                width: "100%",
              }}
            />
          </div>
          <div className='col-md-6'>
            <label className='mb-2'>Product Category</label>

            {category && !selectedCategory ? (
              <CommonDropdownMenu
                rawSelectedValue={category}
                onCategorySelect={handleCategorySelect}
                options={options}
              />
            ) : (
              selectedCategory && (
                <CommonDropdownMenu
                  onCategorySelect={handleCategorySelect}
                  options={options}
                />
              )
            )}
          </div>
          <div className='row g-lg-3 g-sm-3 g-2 justify-content-center mt-4'>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0' {...getRootProps1()}>
                {image1 ? (
                  <div className='position-relative'>
                    <img
                      src={`${image1.preview}`}
                      className='img-fluid'
                      alt='Preview'
                      draggable={false}
                      style={{ userSelect: "none", pointerEvents: "none" }}
                    />
                    <button
                      className='btn btn-danger btn-sm position-absolute top-0 end-0'
                      onClick={(e) => handleRemoveImage(e, setImage1)}>
                      X
                    </button>
                  </div>
                ) : (
                  <img
                    src='/img/file-upload.png'
                    className='img-fluid'
                    alt='Upload Placeholder'
                  />
                )}
                <input {...getInputProps1()} className='input-upload-btn' />
              </div>
            </div>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0' {...getRootProps2()}>
                {image2 ? (
                  <div className='position-relative'>
                    <img
                      src={`${image2.preview}`}
                      className='img-fluid'
                      alt='Preview'
                      draggable={false}
                      style={{ userSelect: "none", pointerEvents: "none" }}
                    />
                    <button
                      className='btn btn-danger btn-sm position-absolute top-0 end-0'
                      onClick={(e) => handleRemoveImage(e, setImage2)}>
                      X
                    </button>
                  </div>
                ) : (
                  <img
                    src='/img/file-upload.png'
                    className='img-fluid'
                    alt='Upload Placeholder'
                  />
                )}
                <input {...getInputProps2()} className='input-upload-btn' />
              </div>
            </div>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0' {...getRootProps3()}>
                {image3 ? (
                  <div className='position-relative'>
                    <img
                      src={`${image3.preview}`}
                      className='img-fluid'
                      alt='Preview'
                      draggable={false}
                      style={{ userSelect: "none", pointerEvents: "none" }}
                    />
                    <button
                      className='btn btn-danger btn-sm position-absolute top-0 end-0'
                      onClick={(e) => handleRemoveImage(e, setImage3)}>
                      X
                    </button>
                  </div>
                ) : (
                  <img
                    src='/img/file-upload.png'
                    className='img-fluid'
                    alt='Upload Placeholder'
                  />
                )}
                <input {...getInputProps3()} className='input-upload-btn' />
              </div>
            </div>
          </div>
        </div>
        <div className='text-center pt-2'>
          <button
            id='submit-btn'
            type='button'
            className='px-5 rounded-pill btn btn-primary btn-lg text-uppercase'
            onClick={handleSubmit}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export { EditProduct };
