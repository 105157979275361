import React, { useEffect, useRef, useState } from "react";
import CommonDropdownMenuTwo from "../Shared/CommonDropdownMenuTwo";
import "./CreateInvoice.css";
import UnitOption from "./UnitOption";

export default function CreateInvoiceContent() {
  const options = ["Custom Date", "1 Week after", "2 Weeks", "1 month"];
  // State variables
  const [services, setServices] = useState([
    {
      showServiceInfo: false,
    },
  ]);
  const [showDueDateBox, setShowDueDateBox] = useState(null);
  const [isSelectItemBoxOpen, setIsSelectItemBoxOpen] = useState(false);
  const selectItemBoxRef = useRef(null);
  const [showTaxBox, setShowTaxBox] = useState(false);
  const [showDiscountBox, setShowDiscountBox] = useState(false);

  // const [selectedDueDate, setSelectedDueDate] = useState("");
  const [payments, setPayments] = useState([
    {
      amount: 0,
      dueDate: new Date().toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }),
      paymentId: "Due Today",
    },
  ]);
  // Current date setup
  const currentDate = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${
    months[currentDate.getMonth()]
  } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

  // Functions to handle toggling of various components
  const toggleHandleServiceInfo = (index) => {
    const updatedServices = [...services];
    updatedServices[index].showServiceInfo =
      !updatedServices[index].showServiceInfo;
    setServices(updatedServices);
    setIsSelectItemBoxOpen(!isSelectItemBoxOpen);
  };
  // Handle add new service
  const addNewService = () => {
    setServices([
      ...services,
      {
        showServiceInfo: false,
      },
    ]); // Add a new empty service object
  };

  // Handle add new payment schedule
  const addNewPayment = () => {
    const newPayment = {
      amount: 0,
      dueDate: "jan 27, 23",
      paymentId: "Due Today",
    };
    setPayments([...payments, newPayment]);
  };
  // Functions to handle toggling of various components
  const toggleTaxBox = () => {
    setShowTaxBox(!showTaxBox);
    setShowDiscountBox(false);
  };
  // Functions to handle toggling of various components
  const toggleDiscountBox = () => {
    setShowDiscountBox(!showDiscountBox);
    setShowTaxBox(false);
  };
  // handle delete service
  const deleteService = (index) => {
    const updatedServices = [...services];
    updatedServices.splice(index, 1);
    setServices(updatedServices);
  };
  // handle delete payment
  const deletePayment = (index) => {
    const updatedPayments = [...payments];
    updatedPayments.splice(index, 1);
    setPayments(updatedPayments);
  };
  const handlePropagation = (e) => {
    e.stopPropagation();
  };
  const toggleDueDateBox = (index) => {
    setShowDueDateBox((prevIndex) => (prevIndex !== index ? index : null));
  };

  const handleDateChange = (event, index) => {
    const inputDate = event.target.value;
    const newDueDate = new Date(inputDate);
    if (isNaN(newDueDate)) {
      console.error("Invalid date:", inputDate);
      return;
    }
    const formattedDueDate = newDueDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const updatedPayments = [...payments];
    updatedPayments[index].dueDate = formattedDueDate;
    setPayments(updatedPayments);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectItemBoxRef.current &&
        !selectItemBoxRef.current.contains(event.target)
      ) {
        setIsSelectItemBoxOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectItemBoxRef]);
  return (
    <>
      {" "}
      <section className='section_container'>
        {/* Customer Details here */}
        <div className='customer_details'>
          {/* Customer info */}
          <div className='logo text-center'>
            <h4>
              Customer <br />
              Logo
            </h4>
          </div>
          <div className='title'>
            <h4>Company Photo Booth Name</h4>
          </div>
          <div className='item'>
            <span>Tom Jones </span>
            <span>214-555-5555</span>
            <span>name@companyinfo.com</span>
            <span>Company Address</span>
          </div>
        </div>

        {/* Invoice content */}
        <div className='invoice'>
          <h3 style={{ margin: "0px 40px" }}>Invoice</h3>

          {/* invoice table */}
          <div className='invoice-table d-flex align-items-center flex-wrap justify-content-between mt-4'>
            <div
              className='table_item hover_effect invoice_td position-relative'
              type='button'>
              <span>Bill to</span>

              <div className='tooltip_table'>
                <div className='tooltip_content'>
                  <p>
                    Client name is auto-added after clicking USE TEMPLATE. The
                    Bill to Field can be edited.
                  </p>
                </div>
                <div className='arrow_tool_tip'></div>
              </div>
            </div>
            <div
              className='table_item hover_effect invoice_td position-relative'
              type='button'>
              <span>invoice #</span>

              <div className='tooltip_table'>
                <div className='tooltip_content'>
                  <p>
                    An invoice number will be generated after clicking USE
                    TEMPLATE.
                  </p>
                </div>
                <div className='arrow_tool_tip'></div>
              </div>
            </div>

            <div className='table_item hover_effect purchase_td position-relative'>
              <span>Purchase order #</span>
              <div className='tooltip_table'>
                <div className='tooltip_content'>
                  <p>You can enter P0# only after clicking USE TEMPLATE</p>
                </div>
                <div className='arrow_tool_tip'></div>
              </div>
            </div>
          </div>

          <div className='invoice-table d-flex align-items-center flex-wrap justify-content-between mt-4'>
            <div></div>
            <div className='table_item table_item_date_issued position-relative'>
              <span>Date Issued</span> <br />
              <div
                className='date position-absolute'
                style={{ fontSize: "14px" }}>
                {formattedDate}
              </div>
            </div>
            <div className='table_item position-relative'>
              <span>Next Payment Due</span>
              <br />
              <div
                className='date position-absolute'
                style={{ fontSize: "14px" }}>
                {formattedDate}
              </div>
            </div>
          </div>

          {/* service area */}
          <div className='service_area d-flex align-items-center justify-content-between py-3'>
            <div className='service_item'>
              <div className='service_info_top'>
                <span>SERVICE INFO</span>
              </div>
            </div>
            <div className='service_item d-flex align-items-center'>
              <span className='me-5'>QTY</span>
              <span>UNIT</span>
            </div>
            <div className='service_item'>
              <span className='me-3'>TAX</span>
              <span>PRICE</span>
            </div>
          </div>

          {/* create new service */}
          {/* Render dynamic services */}
          {services.map((service, index) => (
            <div
              key={index}
              className='create_new_service flex-wrap d-flex align-items-center justify-content-between py-3 position-relative'>
              <div className='service_item position-relative'>
                <div className='service_info_box' style={{ marginTop: "41px" }}>
                  <div
                    className='service_info'
                    onClick={() => toggleHandleServiceInfo(index)}>
                    <input type='text' placeholder='Type or Select a Service' />
                  </div>
                  <div className='desc mt-2'>
                    <input type='text' placeholder='Enter a description' />
                  </div>
                </div>

                {/* Select item */}
                {service?.showServiceInfo && (
                  <div className='select_item_box' ref={selectItemBoxRef}>
                    {/* nav item */}
                    <ul
                      className='nav nav-pills justify-content-between mb-3'
                      id='pills-tab'
                      role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link active'
                          id='pills-home-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-home'
                          type='button'
                          role='tab'
                          aria-controls='pills-home'
                          aria-selected='true'>
                          All
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link'
                          id='pills-profile-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-profile'
                          type='button'
                          role='tab'
                          aria-controls='pills-profile'
                          aria-selected='false'>
                          Package
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link'
                          id='pills-contact-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-contact'
                          type='button'
                          role='tab'
                          aria-controls='pills-contact'
                          aria-selected='false'>
                          Items
                        </button>
                      </li>
                    </ul>

                    {/* Tab content */}
                    <div className='tab-content' id='pills-tabContent'>
                      <div
                        className='tab-pane fade show active'
                        id='pills-home'
                        role='tabpanel'
                        aria-labelledby='pills-home-tab'
                        tabIndex='0'>
                        <div className='tab_box_item px-3 py-3'>
                          <div className='row'>
                            {/* single item */}
                            <div className='col-md-12'>
                              <div className='d-flex justify-content-between mt-3'>
                                <div className='single_item'>
                                  <h5>
                                    Firefly Photo Booth <br /> 2 Hour Rental
                                  </h5>

                                  <div className='d-flex align-items-center mt-2 cart_item'>
                                    <i
                                      className='fa fa-cart-plus me-2'
                                      aria-hidden='true'></i>
                                    <p className='m-0'>package template</p>
                                  </div>
                                </div>

                                {/* Price area */}
                                <div>
                                  <h6>$400</h6>
                                </div>
                              </div>
                            </div>

                            {/* single item */}
                            <div className='col-md-12 mt-3'>
                              <div className='d-flex justify-content-between mt-3'>
                                <div className='single_item'>
                                  <h5>
                                    Firefly Photo Booth <br /> 2 Hour Rental
                                  </h5>

                                  <div className='d-flex align-items-center mt-2 cart_item'>
                                    <i
                                      className='fa fa-cart-plus me-2'
                                      aria-hidden='true'></i>
                                    <p className='m-0'>package template</p>
                                  </div>
                                </div>

                                {/* Price area */}
                                <div>
                                  <h6>$400</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-profile'
                        role='tabpanel'
                        aria-labelledby='pills-profile-tab'
                        tabIndex='0'>
                        <div className='tab_box_item px-3 py-3'>
                          <div className='row'>
                            {/* single item */}
                            <div className='col-md-12'>
                              <div className='d-flex justify-content-between mt-3'>
                                <div className='single_item'>
                                  <h5>
                                    Firefly Photo Booth <br /> 2 Hour Rental
                                  </h5>

                                  <div className='d-flex align-items-center mt-2 cart_item'>
                                    <i
                                      className='fa fa-cart-plus me-2'
                                      aria-hidden='true'></i>
                                    <p className='m-0'>package template</p>
                                  </div>
                                </div>

                                {/* Price area */}
                                <div>
                                  <h6>$400</h6>
                                </div>
                              </div>
                            </div>

                            {/* single item */}
                            <div className='col-md-12 mt-3'>
                              <div className='d-flex justify-content-between mt-3'>
                                <div className='single_item'>
                                  <h5>
                                    Firefly Photo Booth <br /> 2 Hour Rental
                                  </h5>

                                  <div className='d-flex align-items-center mt-2 cart_item'>
                                    <i
                                      className='fa fa-cart-plus me-2'
                                      aria-hidden='true'></i>
                                    <p className='m-0'>package template</p>
                                  </div>
                                </div>

                                {/* Price area */}
                                <div>
                                  <h6>$400</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-contact'
                        role='tabpanel'
                        aria-labelledby='pills-contact-tab'
                        tabIndex='0'>
                        <div className='tab_box_item px-3 py-3'>
                          <div className='row'>
                            {/* single item */}
                            <div className='col-md-12'>
                              <div className='d-flex justify-content-between mt-3'>
                                <div className='single_item'>
                                  <h5>Memory Book</h5>

                                  <div className='d-flex align-items-center mt-2 cart_item'>
                                    <i
                                      className='fa fa-cart-plus me-2'
                                      aria-hidden='true'></i>
                                    <p className='m-0'>package template</p>
                                  </div>
                                </div>

                                {/* Price area */}
                                <div>
                                  <h6>$80</h6>
                                </div>
                              </div>
                            </div>
                            {/* single item */}
                            <div className='col-md-12'>
                              <div className='d-flex justify-content-between mt-3'>
                                <div className='single_item'>
                                  <h5>Animated GIF</h5>

                                  <div className='d-flex align-items-center mt-2 cart_item'>
                                    <i
                                      className='fa fa-cart-plus me-2'
                                      aria-hidden='true'></i>
                                    <p className='m-0'>package template</p>
                                  </div>
                                </div>

                                {/* Price area */}
                                <div>
                                  <h6>$40</h6>
                                </div>
                              </div>
                            </div>

                            {/* single item */}
                            <div className='col-md-12 mt-3'>
                              <div className='d-flex justify-content-between mt-3'>
                                <div className='single_item'>
                                  <h5>Stand by time</h5>

                                  <div className='d-flex align-items-center mt-2 cart_item'>
                                    <i
                                      className='fa fa-cart-plus me-2'
                                      aria-hidden='true'></i>
                                    <p className='m-0'>package template</p>
                                  </div>
                                </div>

                                {/* Price area */}
                                <div>
                                  <h6>$25</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='service_item d-flex align-items-center'>
                {/* QYT field */}
                <span className='me-2 qyt'>
                  <input type='number' placeholder='QYT' />
                </span>

                {/* UNIT Field */}
                <span className='position-relative'>
                  <UnitOption />
                </span>
              </div>
              <div className='service_item d-flex align-items-center'>
                <div className='form-check me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='tax'
                    value=''
                    id='flexCheckDefault'
                  />
                </div>
                <span className=' qyt'>
                  <input type='number' placeholder='Price' />
                </span>
              </div>

              {/* Delete button */}
              <div className='position-absolute delete_btn'>
                <button onClick={() => deleteService(index)}>
                  <i className='fas fa-trash-alt'></i>
                </button>
              </div>
            </div>
          ))}

          {/* add new item button  */}
          <div className='create_new_service py-5'>
            <button onClick={addNewService}>+ Add item / package</button>
          </div>

          {/* Summary*/}
          <div className='card-body'>
            <div className='row flex-column-reverse flex-md-row row-gap-4 row-gap-md-0'>
              <div className='col-md-5 d-flex align-items-end'></div>

              <div className='col-md-7 ml-auto'>
                <table className='table create_invoice_table table-clear'>
                  <tbody>
                    <tr>
                      <td className='left'>
                        <span>Subtotal</span>
                      </td>
                      <td className='right'>$0</td>
                    </tr>
                    {/* Tax box */}
                    <tr>
                      <td className='left position-relative'>
                        <span onClick={toggleTaxBox}>
                          Tax <span>8.5%</span>
                        </span>
                        {showTaxBox && (
                          <div className='edit_discount_box edit_tax_box position-relative'>
                            <div className='box_header d-flex align-items-center justify-content-between mb-2'>
                              <h5>Edit Tax</h5>
                              <span onClick={() => setShowTaxBox(false)}>
                                <i className='fas fa-close'></i>
                              </span>
                            </div>
                            <div className='box-content mt-2 d-flex align-items-center'>
                              <input
                                type='number'
                                className='me-2'
                                placeholder='Tax'
                              />
                              <input
                                type='number'
                                className='me-2'
                                placeholder='Tax'
                              />

                              <div className='input-group'>
                                <span
                                  className='input-group-text'
                                  id='basic-addon1'>
                                  %
                                </span>
                                <span
                                  className='input-group-text'
                                  id='basic-addon1'>
                                  $
                                </span>
                              </div>

                              {/* delete button */}
                              <div>
                                {" "}
                                <i className='fas fa-trash text-danger'></i>
                              </div>
                            </div>

                            <div className='discount_arrow'></div>
                          </div>
                        )}
                      </td>
                      <td className='right'>$0</td>
                    </tr>

                    {/* add discount box */}
                    <tr>
                      <td className='left position-relative'>
                        {showDiscountBox ? (
                          <span>Discount</span>
                        ) : (
                          <span onClick={toggleDiscountBox}>
                            + Add Discount
                          </span>
                        )}

                        {/* edit discount box */}
                        {showDiscountBox && (
                          <div className='edit_discount_box position-relative'>
                            <div className='box_header d-flex align-items-center justify-content-between mb-2'>
                              <h5>Edit Discount</h5>
                              <span onClick={() => setShowDiscountBox(false)}>
                                <i className='fas fa-close'></i>
                              </span>
                            </div>
                            <div className='box-content mt-2 d-flex align-items-center'>
                              <input type='number' className='me-2' />

                              <div className='input-group'>
                                <span
                                  className='input-group-text'
                                  id='basic-addon1'>
                                  %
                                </span>
                                <span
                                  className='input-group-text'
                                  id='basic-addon1'>
                                  $
                                </span>
                              </div>

                              {/* delete button */}
                              <div>
                                {" "}
                                <i className='fas fa-trash text-danger'></i>
                              </div>
                            </div>

                            <div className='discount_arrow'></div>
                          </div>
                        )}
                      </td>
                      <td className='right'>
                        <strong>0</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className='left'>
                        <span>Total USD</span>
                      </td>
                      <td className='right'>
                        <strong>0</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* PAYMENT SCHEDULE */}
      <section className='section_container my-4 px-2 px-md-5 py-5'>
        <div className='title'>
          <h4>PAYMENT SCHEDULE</h4>
        </div>
        {/* <div className='table-responsive mt-5'>
          {" "}
          <table className='table invoice_table'>
            <thead>
              <tr>
                <th scope='col'>AMOUNT</th>
                <th scope='col'>DUE DATE</th>
                <th scope='col'>PAYMENT DATE</th>
                <th scope='col'>PAYMENT ID</th>
                <th scope='col'>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment, index) => (
                <tr key={index}>
                  <th scope='row ' className='hover_payment'>
                    <span>${payment.amount}</span>
                  </th>
                  <td
                    className='due_date hover_payment'
                    style={{ zIndex: 9999, position: "relative" }}>
                    <span
                      style={{ fontSize: "16px" }}
                      onClick={() => toggleDueDateBox(index)}>
                      {payment.dueDate}
                    </span>
                    Due date box
                  </td>
                  <td>-</td>
                  <td>{payment.paymentId}</td>
                  Delete payment
                  <td
                    className='text-center position-relative'
                    style={{ cursor: "pointer" }}>
                    <i
                      className='fas fa-trash text-danger'
                      onClick={() => deletePayment(index)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        <div className='payment_schedule_container mt-2'>
          <table>
            <thead>
              <tr>
                <th scope='col' style={{ width: "74px" }}>
                  AMOUNT
                </th>
                <th scope='col'>DUE DATE</th>
                <th scope='col'>PAYMENT DATE</th>
                <th scope='col'>PAYMENT ID</th>
                <th scope='col' style={{ width: "74px" }}>
                  STATUS
                </th>
              </tr>
            </thead>
            <tbody>
              {payments.map((data, i) => (
                <tr key={i}>
                  <td data-label='Amount'>
                    <span className='hover_item'> ${data.amount}</span>
                  </td>
                  <td
                    data-label='DUE DATE'
                    className='due_date hover_payment position-relative'>
                    <span
                      className='hover_item'
                      onClick={() => toggleDueDateBox(i)}>
                      {data.dueDate}

                      {showDueDateBox === i && (
                        <div
                          className='due_date_box'
                          onClick={handlePropagation}>
                          <div className='due_date_box_header d-flex align-items-center justify-content-between'>
                            <h4>Set Payment Due</h4>
                            <span onClick={() => toggleDueDateBox(null)}>
                              <i className='fas fa-close'></i>
                            </span>
                          </div>
                          <div className='arrow'></div>

                          <div className='due_date_box_content mt-2'>
                            <CommonDropdownMenuTwo options={options} />
                            <div className='mt-3 date_input'>
                              <input
                                type='date'
                                onChange={(event) => handleDateChange(event, i)}
                                value={payments?.dueDate}
                              />
                            </div>
                            <div className='mt-3 text-end'>
                              <button onClick={() => toggleDueDateBox(null)}>
                                Save
                              </button>
                            </div>
                          </div>

                          <div className='arrow'></div>
                        </div>
                      )}
                    </span>
                  </td>
                  <td data-label='PAYMENT DATE'>-</td>
                  <td data-label='PAYMENT ID'>{data.paymentId}</td>
                  <td
                    data-label='STATUS'
                    className='text-end position-relative'
                    style={{ cursor: "pointer" }}>
                    <i
                      className='fas fa-trash text-danger'
                      onClick={() => deletePayment(i)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* add new payment button  */}
        <div className='add-new-payment py-5'>
          <button onClick={addNewPayment}>+ Add Another Payment</button>
        </div>
      </section>
      {/* hidden div for style */}
      <div className=' my-2 px-5 py-2 invisible'></div>
    </>
  );
}
