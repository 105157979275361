import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import axios from "axios";

// Move formatTime12Hour function outside of the DayCalendar component
const formatTime12Hour = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
  const formattedMinutes = minutes.toString().padStart(2, '0'); // Add leading zero if needed
  return `${formattedHours}:${formattedMinutes} ${period}`;
};

function DayCalendar() {
  const { date } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [currentDate, setCurrentDate] = useState(date); // Initialize with URL param date
  const [events, setEvents] = useState([]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Check if user data exists in localStorage
    const udata = localStorage.getItem('user');
    if (!udata) {
      // Redirect to login page if user data does not exist
      navigate('/sign-in');
      return;
    }
    const user_data = JSON.parse(udata);
    setUserId(user_data.user.id);
  }, [navigate]);

  useEffect(() => {
    fetchEvents();
  }, [currentDate, userId]); // Fetch events whenever currentDate or userId changes

  useEffect(() => {
    setCurrentDate(date); // Update currentDate when the URL parameter changes
  }, [date]);

  console.log(currentDate);
  const fetchEvents = () => {
    if (userId) {
      axios.post(`${backendUrl}/api/get-curr-date-events`, { date: currentDate, created_by: userId })
        .then(response => {
          const fetchedEvents = response.data.events.map(event => {
            let startDateTime = new Date(`${event.date}T${event.from_time}`);
            let endDateTime = new Date(`${event.date}T${event.to_time}`);
            
            // Handle time adjustments if needed
            if (event.from_time === "12:00:00") {
              startDateTime.setHours(12, 0, 0); // Set hours, minutes, seconds for noon
            }
            if (event.to_time === "00:00:00") {
              endDateTime.setDate(endDateTime.getDate() + 1);
            }
            console.log(startDateTime);
            console.log(endDateTime);
            return {
              id: event.id,
              title: event.name,
              start: startDateTime,
              end: endDateTime,
              backgroundColor: '#3788d8',
              borderColor: '#3788d8',
              extendedProps: {
                address: event.address,
                event_category: event.get_event_category.name,
                lead_source: event.get_lead_source.name,
                client_name: `${event.cl_firstname} ${event.cl_lastname}`,
                start_time: event.from_time,
                end_time: event.to_time,
              }
            };
          });

          setEvents(fetchedEvents); // Update events state
        })
        .catch(error => {
          console.error("Error fetching events:", error);
        });
    }
  };

  const handleDatesSet = (arg) => {
    setCurrentDate(arg.startStr); // Update currentDate when the calendar view changes
  };

  const handleEventClick = (info) => {
    const eventId = info.event.id;
    const eventUrl =`/customer-events/${eventId}`;
    window.open(eventUrl, '_blank');
  };

  return (
    <div className='container-fluid p-lg-5 p-md-5 p-2'>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView='timeGridDay'
        weekends={true}
        events={events}
        eventContent={renderEventContent}
        headerToolbar={{
          start: "prev,next today",
          center: "title",
          end: "",
        }}
        className='bg-white'
        datesSet={handleDatesSet} // Update currentDate when dates are set in the calendar
        initialDate={currentDate}  // Pass currentDate as initialDate to FullCalendar
        eventClick={handleEventClick}  
      />
    </div>
  );
}

export { DayCalendar };

// Render function for event content
function renderEventContent(eventInfo) {
  console.log(eventInfo);
  return (
    <div style={{ textAlign: 'left' }}>
      <i className="text-white text-left">
        Event Time : {formatTime12Hour(eventInfo.event.extendedProps.start_time)}-{formatTime12Hour(eventInfo.event.extendedProps.end_time)}
      </i>
      <p className="m-0"><i className="text-white text-left">Event: {eventInfo.event.title}</i></p>
    </div>
  );
}
