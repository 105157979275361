import React from "react";
import { GoQuestion } from "react-icons/go";
import { TbChartCircles } from "react-icons/tb";
import Select from "react-select";

export default function SideCanvas({
  setSelectedOption,
  selectedOption,
  handleSmartTextInputChange, // Receive the function to handle smart text input change
}) {
  const options = [
    { value: "suggested", label: "Suggested" },
    { value: "project name", label: "Project Name" },
    { value: "first client first name", label: "First client first name" },
    { value: "first client last name", label: "First client last name" },
    { value: "first client email", label: "First client email" },
    { value: "project", label: "Project" },
    { value: "project type", label: "Project type" },
  ];

  const handleFirstContentChange = (event) => {
    handleSmartTextInputChange(event.target.value); // Call the function to update the smart text input value
  };

  return (
    <div className='create-contract'>
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='offcanvasRight'
        aria-labelledby='offcanvasRightLabel'
        data-bs-backdrop='false'>
        <div className='w-100 text-start header'>
          <button
            type='button'
            className='btn-close mt-3 ms-4 text-black'
            data-bs-dismiss='offcanvas'
            aria-label='Close'></button>
          <h4 className='text-center py-3'>Smart Text</h4>
        </div>

        <div className='offcanvas-body'>
          <form className='form-area px-2'>
            <div className='row'>
              <div className='col-12 mb-3'>
                <label className='form-label fw-normal d-flex align-items-center '>
                  Field content types
                  <GoQuestion className='ms-3' />
                </label>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </div>

              <div className='col-12 mb-3'>
                <label className='form-label fw-normal'>
                  Client permissions
                </label>
                <div
                  className='btn-group'
                  role='group'
                  aria-label='Basic radio toggle button group'>
                  <input
                    type='radio'
                    className='btn-check'
                    name='btnradio'
                    id='btnradio1'
                    autocomplete='off'
                  />
                  <label className='btn tab-btn' htmlFor='btnradio1'>
                    Can Edit
                  </label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='btnradio'
                    id='btnradio2'
                    autocomplete='off'
                  />
                  <label className='btn tab-btn' htmlFor='btnradio2'>
                    Must fill
                  </label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='btnradio'
                    id='btnradio3'
                    autocomplete='off'
                  />
                  <label className='btn tab-btn' htmlFor='btnradio3'>
                    Can’t edit
                  </label>
                </div>
              </div>

              <div className='col-12 mb-3'>
                <label className='form-label fw-normal'>Placeholder text</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder={selectedOption?.value}
                />
              </div>

              <div className='col-12 mb-3'>
                <label className='form-label fw-normal d-flex align-items-center '>
                  First content
                  <TbChartCircles className='ms-3' />
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Ex. Jane Smith'
                  onChange={handleFirstContentChange} // Add onChange handler to update the smart text input value
                />
              </div>

              <div className='note mt-3'>
                <p>
                  Note: Select a link for a source of info or have you or your
                  client fill the field content based on the provided
                  placeholder.
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
