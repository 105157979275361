import React from "react";
// Import Routing Components For Linking to Web pages
import { Link } from "react-router-dom";

// COMPONENTS

function Proposals() {
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    //  ================= *****  TEMPLATES BODY JSX ***** =================
    <div className='container-fluid p-lg-5 p-md-5 p-3'>
      <div className='row g-lg-5 g-md-5 g-3'>
        <div className='col-sm-12 col-md-12 col-xl-12'>
          {/* Product Templates Description about this page */}
          <div className='mb-4'>
            <h3 className='mb-1'>Proposal Templates</h3>
            <p>
              This isn’t your average “proposal”… instead, collect payments and
              e-signatures through this all-in-one booking document by combining
              an invoice (a set of services, prices, and a payment schedule)
              with the associated Contract.
            </p>
          </div>
        </div>
        {/* ========= Create a New Product Button ========= */}
        <div className='col-sm-12 col-md-6 col-xl-4 product-items'>
          <div className='h-100 bg-white rounded p-3 shadow-sm'>
            <Link
              to='/create-proposals'
              id=''
              className='h-100 d-flex align-items-center justify-content-center px-2 py-5 p-urls'
              style={{ border: "1px dashed #534dbb" }}>
              <div className='mb-4 text-center'>
                <i
                  className='fa-solid fa-plus'
                  style={{ fontSize: "70px" }}></i>
                <h6 className='mb-0 fw-bold text-uppercase'>
                  CREATE A NEW PROPOSAL
                </h6>
              </div>
            </Link>
          </div>
        </div>

        {/* ========= Basic Photo Booth Contract Button ========= */}
        <div id='' className='col-sm-12 col-md-6 col-xl-4 contact-items'>
          <div className='h-100 bg-white d-flex align-items-center justify-content-center rounded p-4 shadow-sm position-relative'>
            <div className='three-dot-btn'>
              <div className='position-absolute top-0 end-0 me-3 mt-2'>
                <button className='border-0 bg-transparent ' type='button'>
                  <i className='fa-solid fa-ellipsis fs-3'></i>
                </button>
              </div>
              <div className='text-center edit-btn'>
                {/* File With Signature Icon */}

                <button className='border-0 bg-transparent' type='button'>
                  <i className='fa-solid fa-file-signature'></i>
                </button>
                <p className='basic-photo'>
                  PROPOSAL - BASIC CONTRACT <br /> WITH INVOICE
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* ========= Product Template - END ========= */}
      </div>
    </div>

    // ================= *****  TEMPLATES BODY JSX ***** =================
  );
}

export { Proposals }; /* ========================= We had to remove default. */
