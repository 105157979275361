import React from "react";

export default function TextEditorToSection() {
  return (
    <div className='editor_top_section position-relative'>
      <div className='row text-center editor_top_section_content pt-5'>
        <div className='col-md-4'>
          <div className='header'>Header</div>
        </div>
        <div className='col-md-4 d-flex justify-content-center'>
          <div className='Logo'>
            <h6>
              Customer <br /> LOGO
            </h6>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='image'>Image</div>
        </div>
        <div className='col-12 text-center mb-5 mt-4'>
          <h5 className='mb-2'>Company Photo Booth Name</h5>
          <span>Ruben Esquive | 214-555-5555 | name@companyinfo.com</span>
        </div>
        <hr className='m-0' />
        <div className='text-start note top-note'>
          <p>
            The contract is ready for review and signature. If you have any
            questions, just ask!
          </p>
        </div>
      </div>
    </div>
  );
}
