import React from "react";
import { Outlet } from "react-router-dom";
import "./CompanySetting.css";
import TabNav from "./Tab-Nav/TabNav";

export default function CompanySetting() {
  return (
    <>
      {/* ================= *****  BODY JSX ***** ================= */}

      <div className='container-fluid'>
        {/* ========== SETTINGS NAVBAR ======== */}
        <div id='settings-navbar' className='py-5 my-2'>
          <div className='container '>
            <div className='setting_title'>
              <h2>Settings</h2>
            </div>
          </div>
        </div>

        <div className='container mt-1'>
          {/* ========== 2 TABS ========== */}
          <TabNav />

          <div className='tab-content'>
            {/* ========= My Account Tab ========= */}

            <Outlet />

            {/* ========== Company setting   =================== */}
          </div>
        </div>
      </div>

      {/* ================= *****  TEMPLATES BODY JSX ***** ================= */}
    </>
  );
}
