// src/redux/titleSlice.js
const SET_TITLE = 'SET_TITLE';

export const setTitle = (title) => ({
  type: SET_TITLE,
  payload: title,
});

const initialState = {
  title: 'Untitled Contract',
};

const titleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    default:
      return state;
  }
};

export default titleReducer;
