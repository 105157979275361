import EditEmailSignatureModal from "./EditEmailSignatureModal";

export default function EmailSignature() {
  return (
    <>
      {" "}
      <EditEmailSignatureModal />
      <div className='mb-3 col-md-12  bg-white company-setting-box rounded p-lg-3 p-md-2 p-2 my-4'>
        <div className='mt-3 '>
          <p className='fw-bolder fs-4 '>Email Signature </p>
          <p>
            Your personalized signature will apper on all communication and
            email Templates{" "}
          </p>
        </div>

        <div className=' col-md-6'>
          <p className='m-0 fs-6'>john jones </p>
          <p className='m-0 fs-6'>Rentals / Photo booths </p>
          <p className='m-0 fs-6'>John Photo Booth Company </p>
          <p className='m-0 fs-6'> (972) 123-55555 </p>

          <div className=' mt-4  pt-2'>
            <button
              id='submit-btn'
              type='button'
              data-bs-toggle='modal'
              data-bs-target='#exampleModal2'
              className='px-5 rounded-pill btn btn-primary btn-lg text-uppercase'>
              EDIT EMAIL SIGNATURE
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
