import React, { useState } from "react";

export default function Team() {
  // Define initial state for attendants
  const [attendants, setAttendants] = useState([
    {
      firstName: "",
      lastName: "",
      phoneNumber: "214-555-5555",
      email: "name@companyinfo.com",
    },
  ]);

  // Function to handle add new attendant
  const handleAddAttendant = () => {
    setAttendants([
      ...attendants,
      { firstName: "", lastName: "", phoneNumber: "", email: "" },
    ]);
  };

  // Function to handle attendant inputs
  const handleAttendantChange = (index, key, value) => {
    const updatedAttendants = [...attendants];
    updatedAttendants[index][key] = value;
    setAttendants(updatedAttendants);
  };

  // Define initial state for selected information checkboxes
  const [infoSelection, setInfoSelection] = useState({
    customerName: true,
    eventAddress: true,
    customerPhoneNumber: true,
    customerBalance: true,
  });

  // Function to handle checkbox toggling and update state
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setInfoSelection((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  return (
    <>
      <div className='tab-pane fade' id='content4'>
        <div className='row my-3  g-lg-5 g-md-5 g-3'>
          {/* ============= Start: Build Up Your Professional Presence Header ============= */}
          <p className=' fw-bolder fs-4 m-0 p-0'>
            BUILD UP YOUR PROFESSIONAL PRESENCE{" "}
          </p>
          <p className=' fs-5 p-0 m-0'>Only visible to admin</p>
          {/* ============= End: Build Up Your Professional Presence Header ============= */}

          {/* ============= Start: Photo Booth Attendants ============= */}
          <div className='mb-3 my-3 row col-md-12  company-setting-box rounded my-4 bg-white'>
            <h3 className='mt-5'>Photo Booth Attendants </h3>

            {/* =================== Start: Dynamically Adding From =================== */}
            {attendants.map((attendant, index) => (
              <form className='row g-3 needs-validation' key={index}>
                <b>Attendant {index + 1}</b>
                <div className='col-md-6'>
                  <label htmlFor={`firstName${index}`} className='form-label'>
                    First Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id={`firstName${index}`}
                    name={`firstName${index}`}
                    value={attendant.firstName}
                    onChange={(e) =>
                      handleAttendantChange(index, "firstName", e.target.value)
                    }
                    required
                  />
                  <div className='valid-feedback'>Looks good!</div>
                </div>
                <div className='col-md-6'>
                  <label htmlFor={`lastName${index}`} className='form-label'>
                    Last name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id={`lastName${index}`}
                    name={`lastName${index}`}
                    value={attendant.lastName}
                    onChange={(e) =>
                      handleAttendantChange(index, "lastName", e.target.value)
                    }
                    required
                  />
                  <div className='valid-feedback'>Looks good!</div>
                </div>

                <div className='col-md-6'>
                  <label htmlFor={`phoneNumber${index}`} className='form-label'>
                    Phone #
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id={`phoneNumber${index}`}
                    name={`phoneNumber${index}`}
                    placeholder='241-555-5555'
                    value={attendant.phoneNumber}
                    onChange={(e) =>
                      handleAttendantChange(
                        index,
                        "phoneNumber",
                        e.target.value
                      )
                    }
                    required
                  />
                  <div className='valid-feedback'>Looks good!</div>
                </div>
                <div className='col-md-6'>
                  <label htmlFor={`email${index}`} className='form-label'>
                    Email
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id={`email${index}`}
                    name={`email${index}`}
                    placeholder='name@companyinfo.com'
                    value={attendant.email}
                    onChange={(e) =>
                      handleAttendantChange(index, "email", e.target.value)
                    }
                    required
                  />
                  <div className='valid-feedback'>Looks good!</div>
                </div>

                <hr />
              </form>
            ))}
            {/* =================== End: Dynamically Adding From =================== */}

            {/* Add New Attendant Button */}
            <div className='col-12 my-5' onClick={handleAddAttendant}>
              <div className='addAttendant_btn' to='#' role='button'>
                <i className='fa-solid fa-plus'></i>
                <span>Add Attendant</span>
              </div>{" "}
            </div>
          </div>
          {/* ============= End: Photo Booth Attendants ============= */}

          {/* ============= Start:Select What Info To Share With Attendants ============= */}
          <div className='mb-3 col-md-12 border bg-white company-setting-box rounded p-lg-3 p-md-2 p-2 my-4'>
            <div className='mb-3 col-md-12 my-5'>
              <h5 className='bold'>
                Select What Info To Share With Attendants
              </h5>
            </div>
            <hr />
            {/* Map over the keys of infoSelection state to render checkboxes */}
            {Object.keys(infoSelection).map((key) => (
              <div className='card border-0 ' key={key}>
                <div className='row d-flex d-inline align-items-center my-1'>
                  <div>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id={key}
                        name={key}
                        checked={infoSelection[key]}
                        onChange={handleCheckboxChange} // Call handleCheckboxChange function on change
                      />
                      <div className='form-check-label' htmlFor={key}>
                        <span className='fs-15'>
                          {key === "customerName" && "Customer Name"}
                          {key === "eventAddress" && "Event Address"}
                          {key === "customerPhoneNumber" &&
                            "Customer's Phone Number"}
                          {key === "customerBalance" &&
                            "Customer balance due on the day of the event."}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
          {/* ============= Start:Select What Info To Share With Attendants ============= */}
        </div>
      </div>
    </>
  );
}
