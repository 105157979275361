import React from "react";
import { NavLink } from "react-router-dom";

export default function TabNav() {
  return (
    <>
      <ul
        className='nav nav-tabs border-0 border-primary  setting_tab_nav'
        id='myTabs'>
        {/* ========= My Account ========= */}
        <NavLink to='/company-setting/my-account' className='px-3 py-2'>
          My Account
        </NavLink>

        <NavLink to='/company-setting/my-company' className='px-3 py-2'>
          Company Settings
        </NavLink>
        {/* <li className='nav-item '>
          <a
            className='nav-link fs-5 active  border-0 '
            id='home-tab'
            data-bs-toggle='tab'
            href='#MyAccount_Info'
            >
            MY ACCOUNT
          </a>
        </li> */}
        {/* ========= COMPANY ========= */}
        {/* <li className='nav-item'>
          <a
            className='nav-link fs-5  border-0  border-bottom-1  '
            id='profile-tab'
            data-bs-toggle='tab'
            href='#COMPANY_Info'>
            COMPANY
          </a>
        </li> */}
      </ul>
    </>
  );
}
