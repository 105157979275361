import { useState } from "react";
import CommonDropdownMenu from "../../../Shared/CommonDropdownMenu";

function EventProducts() {
  const options = [
    "Photo Booth",
    "Photo Booth Templates",
    "Backdrop",
    "Props",
    "Additional Items",
  ];
  // State variables to manage showing the add category section and selected category
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);

  // Callback function to handle category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory([...selectedCategory, category]);
    setShowAddCategory(false); // Hide the add category section
  };
  // Function to handle click event for adding a category
  const handleAddCategoryClick = () => {
    setShowAddCategory(true);
  };

  return (
    <>
      <div className='container-fluid back_color'>
        <div className='row bg-white p-3'>
          <div className='col-sm-12'>
            <h4>Approved Products for Event</h4>
            <div className='mt-3'>
              <p>
                Never forget which products you need to bering to an event.
                <br />
                Choose manually or sent Customer a product Toue and have them do
                the woprk
              </p>
            </div>
          </div>
        </div>

        <div className='mt-4 bg-white p-3'>
          <div className='row'>
            <div className='col-sm-6'>
              <h4>Photo Booth</h4>
            </div>
            <div className='col-sm-6 d-flex justify-content-end mt-2'>
              <div className='text-dark singleEllipsis'>
                <i className='fas fa-ellipsis-h fa-rotate-by fa-xl icon_size'></i>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-10'>
              <div className='row g-lg-3 g-sm-3 g-2  mt-1'>
                <div className='col-md-4 col-sm-4 col-12'>
                  <div className='upload-btn-wrapper text-center p-0'>
                    <img
                      src='/img/photo-gallery.png'
                      className='img-fluid'
                      alt=''
                    />
                    <input
                      className='input-upload-btn'
                      type='file'
                      name='myfile'
                    />
                    <span>Select Product</span>
                  </div>
                </div>
                <div className='col-md-4 col-sm-4 col-12'>
                  <div className='upload-btn-wrapper p-0'>
                    <img
                      src='/img/photo-gallery.png'
                      className='img-fluid'
                      alt=''
                    />
                    <input
                      className='input-upload-btn'
                      type='file'
                      name='myfile'
                    />
                    <span>Select Product</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  icon_size me-2'></i>
                <i className='fas fa-ellipsis-v fa-xl  icon_size'></i>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-4 bg-white p-3'>
          <div className='row'>
            <div className='col-sm-6'>
              <h4>Photo Booth Template Design</h4>
            </div>
            <div className='col-sm-6 d-flex justify-content-end mt-2'>
              <div className='text-dark singleEllipsis' href='#'>
                <i className='fas fa-ellipsis-h fa-rotate-by fa-xl icon_size'></i>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-10'>
              <div className='row g-lg-3 g-sm-3 g-2  mt-1'>
                <div className='col-md-4 col-sm-4 col-12'>
                  <div className='upload-btn-wrapper p-0'>
                    <img
                      src='/img/photo-gallery.png'
                      className='img-fluid'
                      alt=''
                    />
                    <input
                      className='input-upload-btn'
                      type='file'
                      name='myfile'
                    />
                    <span>Select Product</span>
                  </div>
                </div>
                <div className='col-md-4 col-sm-4 col-12'>
                  <div className='upload-btn-wrapper p-0'>
                    <img
                      src='/img/photo-gallery.png'
                      className='img-fluid'
                      alt=''
                    />
                    <input
                      className='input-upload-btn'
                      type='file'
                      name='myfile'
                    />
                    <span>Select Product</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  icon_size me-2'></i>
                <i className='fas fa-ellipsis-v fa-xl  icon_size'></i>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-4 bg-white p-3'>
          <div className='row'>
            <div className='col-sm-6'>
              <h4>Props</h4>
            </div>
            <div className='col-sm-6 d-flex justify-content-end mt-2'>
              <div className='text-dark singleEllipsis' href='#'>
                <i className='fas fa-ellipsis-h fa-rotate-by fa-xl icon_size'></i>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-10'>
              <div className='row g-lg-3 g-sm-3 g-2  mt-1'>
                <div className='col-md-4 col-sm-4 col-12'>
                  <div className='upload-btn-wrapper p-0'>
                    <img
                      src='/img/photo-gallery.png'
                      className='img-fluid'
                      alt=''
                    />
                    <input
                      className='input-upload-btn'
                      type='file'
                      name='myfile'
                    />
                    <span>Select Product</span>
                  </div>
                </div>
                <div className='col-md-4 col-sm-4 col-12'>
                  <div className='upload-btn-wrapper p-0'>
                    <img
                      src='/img/photo-gallery.png'
                      className='img-fluid'
                      alt=''
                    />
                    <input
                      className='input-upload-btn'
                      type='file'
                      name='myfile'
                    />
                    <span>Select Product</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  icon_size me-2'></i>
                <i className='fas fa-ellipsis-v fa-xl  icon_size'></i>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-4 bg-white p-3'>
          <div className='row'>
            <div className='col-sm-6'>
              <h4>Backdrops</h4>
            </div>
            <div className='col-sm-6 d-flex justify-content-end mt-2'>
              <div className='text-dark singleEllipsis' href='#'>
                <i className='fas fa-ellipsis-h fa-rotate-by fa-xl icon_size'></i>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-10'>
              <div className='row g-lg-3 g-sm-3 g-2  mt-1'>
                <div className='col-md-4 col-sm-4 col-12'>
                  <div className='upload-btn-wrapper p-0'>
                    <img
                      src='/img/photo-gallery.png'
                      className='img-fluid'
                      alt=''
                    />
                    <input
                      className='input-upload-btn'
                      type='file'
                      name='myfile'
                    />

                    <span>Select Product</span>
                  </div>
                </div>
                <div className='col-md-4 col-sm-4 col-12'>
                  <div className='upload-btn-wrapper p-0'>
                    <img
                      src='/img/photo-gallery.png'
                      className='img-fluid'
                      alt=''
                    />
                    <input
                      className='input-upload-btn'
                      type='file'
                      name='myfile'
                    />
                    <span>Select Product</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  icon_size me-2'></i>
                <i className='fas fa-ellipsis-v fa-xl  icon_size'></i>
              </div>
            </div>
          </div>
        </div>
        {/* Conditional rendering for selected category */}
        {selectedCategory &&
          selectedCategory.map((category, i) => (
            <div className='mt-4 bg-white p-3' key={i}>
              <div className='row'>
                <div className='col-sm-6'>
                  <h4>{category}</h4>
                </div>
                <div className='col-sm-6 d-flex justify-content-end mt-2'>
                  <div className='text-dark singleEllipsis' href='#'>
                    <i className='fas fa-ellipsis-h fa-rotate-by fa-xl icon_size'></i>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-10'>
                  <div className='row g-lg-3 g-sm-3 g-2  mt-1'>
                    <div className='col-md-4 col-sm-4 col-12'>
                      <div className='upload-btn-wrapper p-0'>
                        <img
                          src='/img/photo-gallery.png'
                          className='img-fluid'
                          alt=''
                        />
                        <input
                          className='input-upload-btn'
                          type='file'
                          name='myfile'
                        />

                        <span>Select Product</span>
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-4 col-12'>
                      <div className='upload-btn-wrapper p-0'>
                        <img
                          src='/img/photo-gallery.png'
                          className='img-fluid'
                          alt=''
                        />
                        <input
                          className='input-upload-btn'
                          type='file'
                          name='myfile'
                        />
                        <span>Select Product</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
                  <div className='text-dark doubleEllipsis' href='#'>
                    <i className='fas fa-ellipsis-v fa-xl  icon_size me-2'></i>
                    <i className='fas fa-ellipsis-v fa-xl  icon_size'></i>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {/* Section for adding a new category */}
        <div className='row mt-5'>
          {/* Conditionally rendering add category button */}
          {!showAddCategory && (
            <div
              className='d-flex align-items-center'
              onClick={handleAddCategoryClick}>
              <button
                type='button'
                className='btn btn-primary d-flex align-items-center justify-content-center rounded-circle'>
                <h4 className='text-white fs-4'>+</h4>
              </button>
              <h4 className='ms-3'>Add Product Category</h4>
            </div>
          )}
          {showAddCategory && (
            <div className='col-md-4'>
              <label className='form-label'>Product Category</label>
              <CommonDropdownMenu
                options={options}
                onCategorySelect={handleCategorySelect}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { EventProducts }; /* ========================= We had to remove default. */
