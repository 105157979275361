
import React, { useState, useEffect } from "react";
import axios from "axios";
import CreateNewClientForm from "../../AddCustomerInfoModal/CreateNewClientForm";
import ExistingClient from "../../AddCustomerInfoModal/ExistingClient";
import { useNavigate } from 'react-router-dom'; 
 
function NewEventModal({ isOpen, onClose,curDate,reset}) {
    const [activeTab, setActiveTab] = useState("createNewClient");
    const [showUserInfoModal, setShowUserInfoModal] = useState(true);
    const [isTabChanging, setIsTabChanging] = useState(false);
    const [eventTypeOptions, setEventTypeOptions] = useState([]);
    const [leadSourceOptions, setLeadSourceOptions] = useState([]);
    const [eventType, setEventType] = useState("");
    const [eventTypeId, setEventTypeId] = useState("");
    const [leadSource, setLeadSource] = useState("");
    const [leadSourceId, setLeadSourceId] = useState("");
    const [eventTypeOptionsOpen, setEventTypeOptionsOpen] = useState(false);
    const [leadSourceOptionsOpen, setLeadSourceOptionsOpen] = useState(false);
    const [newCategory, setNewCategory] = useState("");
    const [newLeadSource, setNewLeadSource] = useState("");
    const [showAddCategoryInput, setShowAddCategoryInput] = useState(false);
    const [showAddLeadSourceInput, setShowAddLeadSourceInput] = useState(false);
    const [eventName, setEventName] = useState("");
    const [eventAddress, setEventAddress] = useState("");
    const [eventDate, setEventDate] = useState(curDate);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [clientsDetailes, setClientdetailes] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [EventDetailes, setEventDetailes] = useState([]);
    const [NewEventId, setNewEventId] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);

    console.log(curDate);
    
    useEffect(() => {
        // Check if user data exists in localStorage
        const udata = localStorage.getItem('user');
        if (!udata) {
            // Redirect to login page if user data does not exist
            navigate('/sign-in');
            return;
        }
        const user_data = JSON.parse(udata);
        setUserId(user_data.user.id);
    }, [navigate]);


  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    // Update state if selected date is today or in the future
    setEventDate(selectedDate);
  };

  const isStartTimeBeforeEndTime = (startTime, endTime) => {
    const startTimeInMinutes = convertTimeToMinutes(startTime);
    const endTimeInMinutes = convertTimeToMinutes(endTime);
    return startTimeInMinutes < endTimeInMinutes;
  };
  
  const convertTimeToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  }; 

  const fetchEventTypeOptions = async () => {
    if(userId){
      try {
        const response = await axios.get(`${backendUrl}/api/events-category-list/${userId}`);
        if (response.data.success === true) {
          // Map id to name for display and store id in state
          const options = response.data.event_category.map((option) => ({
            id: option.id,
            name: option.name,
          }));
          setEventTypeOptions(options);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    }

  }

  const fetchLeadSourceOptions = async () => {
    if(userId){
    try {
      const response = await axios.get(`${backendUrl}/api/lead-source-list/${userId}`);
      if (response.data.success === true) {
        const options = response.data.lead_source_data.map((option) => ({
          id: option.id,
          name: option.name,
        }));
        setLeadSourceOptions(options);
      }
    } catch (error) {
      console.error('Error fetching lead sources:', error);
    }
   }
  };
 
  const handleCreateCategory = () => {
    if(userId){
      if (newCategory.trim() !== "") {
        axios.post(`${backendUrl}/api/events-category-add`, { name: newCategory, created_by: userId })
          .then(response => {
            fetchEventTypeOptions();
            setEventType(response.data.id); // Store id instead of name
            setNewCategory("");
            // setEventTypeOptionsOpen(false);
            setShowAddCategoryInput(false);
          })
          .catch(error => {
            console.error("Error creating category:", error);
          });
      }
    }
  };

  const handleCreateLeadSource = () => {
    if(userId){
      if (newLeadSource.trim() !== "") {
        axios.post(`${backendUrl}/api/lead-source-add`, { name: newLeadSource, created_by: userId })
          .then(response => {
            fetchLeadSourceOptions();
            setLeadSource(response.data.id); // Store id instead of name
            setNewLeadSource("");
            // setLeadSourceOptionsOpen(false);
            setShowAddLeadSourceInput(false);
          })
          .catch(error => {
            console.error("Error creating lead source:", error);
          });
      }
    }
  };

  const handleTabChange = (tabId) => {
    setIsTabChanging(true);
    setActiveTab(tabId);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 100);
  };

const handleCreateEventClick = () => {
  if (eventName && eventAddress && eventType && leadSource && eventDate && startTime && endTime) {
    // Check if start time is before end time
    if (!isStartTimeBeforeEndTime(startTime, endTime)) {
      alert("End time must be after start time.");
      return;
    }

    // Validate event date
    // const eventDateObj = new Date(eventDate);
    const eventDateObj = new Date(eventDate + 'T00:00:00');
    const currentDate = new Date();

    console.log("eventDateObj");
    console.log(eventDateObj);
    console.log(currentDate);
  
    if (eventDateObj < currentDate.setHours(0, 0, 0, 0)) {
      window.alert("Event date cannot be a past date.");
      return;
    }

    // All required fields and time validation passed, proceed to create event
    const eventData = {
      eventName: eventName,
      eventAddress: eventAddress,
      eventType: eventTypeId,
      leadSource: leadSourceId,
      eventDate: eventDate,
      startTime: startTime,
      endTime: endTime,
    };
    setEventDetailes(eventData);
    setShowUserInfoModal(false);
  } else {
    // Handle case where not all required fields are filled
    alert("Please fill in all required fields.");
  }
};


  // const onClose = () => {
  //   setShowUserInfoModal(true);
  // };

  const handlemodalclose=()=>{
    setEventTypeOptionsOpen(false);
    setLeadSourceOptionsOpen(false);
  }

  const toggleAddCategoryInput = () => {
    setShowAddCategoryInput(!showAddCategoryInput);
  };

  const toggleAddLeadSourceInput = () => {
    setShowAddLeadSourceInput(!showAddLeadSourceInput);
  };

  const resetModalFields = () => {
    setEventName("");
    setEventAddress("");
    setEventDate("");
    setStartTime("");
    setEndTime("");
    setEventType("");
    setEventTypeId("");
    setLeadSource("");
    setLeadSourceId("");
    setNewCategory("");
    setNewLeadSource("");
  };
  
  useEffect(() => {
    fetchEventTypeOptions();
    fetchLeadSourceOptions();

    const handleModalShow = () => {
      resetModalFields();
    };
  }, [userId]);
 

  useEffect(() => {
    const handlesubmitevent = () => {
      if (EventDetailes.length!=0) {
        const cl_detailes=clientsDetailes?clientsDetailes:selectedClient;
        axios.post(`${backendUrl}/api/event-add`, { clentdetailes: cl_detailes, eventdetailes: EventDetailes,created_by: userId })
          .then(response => {
            if(response.data.success==true){
              navigate(`/customer-events/${response.data.id}`);
              setNewEventId(response.data.id); 

            }
          })
          .catch(error => {
            console.error("Error creating category:", error);
          });
          setShowUserInfoModal(true);
      }
    };
    handlesubmitevent();
  }, [clientsDetailes]);
  
  useEffect(() => {
    setEventDate(curDate);
  }, [curDate]); 

  useEffect(() => {
    resetModalFields();
  }, [reset]); 

  return (
      <>
      <div className={`modal  custom-modal-open fade m-0 p-0 ${isOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" >
        <div className='modal-dialog newEventsModal_dialog modal-dialog-centered'>
        {showUserInfoModal ? (
          <div className={`modal-content event_modal}`}>
            <div className='modal-header' style={{ backgroundColor: "#4f46e5" }}>
              <h5 className='modal-title text-center w-100' id='exampleModalLabel' style={{ color: "#fff", fontSize: "22px" }}>
                New Event
              </h5>
              <button type='button' onClick={onClose} className='close' data-bs-dismiss='modal'>
                <span aria-hidden='true' className='modal_button'>
                  <i className='fas fa-close'></i>
                </span>
                <span className='sr-only'>Close</span>
              </button>
            </div>
            <div className='modal-body px-5 event_modal_cont'>
              <form>
                <div className='row'>
                  <div className='col-md-12'>
                    <label htmlFor='eventName'>Event Name</label>
                    <input name='eventName' id='eventName' type='text' className='form-control' placeholder='Event Name' value={eventName} onChange={(e) => setEventName(e.target.value)} required />
                  </div>
                  <div className='col-md-12 mt-4'>
                    <label htmlFor='eventAddress'>Event Address</label>
                    <input name='eventAddress' id='eventAddress' type='text' className='form-control' placeholder='Event Address' value={eventAddress} onChange={(e) => setEventAddress(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-4'>
                    <label>Event Type</label>
                    <div className='accordion position-relative'>
                      <div className={`accordion-header w-100 ${eventTypeOptionsOpen ? "active" : ""}`} onClick={() => {setEventTypeOptionsOpen(!eventTypeOptionsOpen);fetchEventTypeOptions()}}>
                        <span className='selected-option' eventid={eventTypeId}>{eventType ? eventType : "Select Event Type"}</span>
                        <span className='icon overflow-hidden'>
                          <i className={eventTypeOptionsOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} />
                        </span>
                      </div>
                      <div className={`accordion-content ${eventTypeOptions.length>4 ? "category_scroll" : ""} ${eventTypeOptionsOpen ? "active" : ""}`}>
                        {eventTypeOptions.map((option) => (
                          <div key={option.id} className='option' onClick={() => { setEventTypeId(option.id); setEventType(option.name); setEventTypeOptionsOpen(false); }}>
                            {option.name}
                          </div>
                        ))}
                        {showAddCategoryInput ? (
                          <div className='input-with-button d-flex align-items-center'>
                            <input
                              type='text'
                              placeholder=' New Category..'
                              value={newCategory}
                              onChange={(e) => setNewCategory(e.target.value)}
                            />
                            <button type="button" className='add_item_btn' onClick={() => handleCreateCategory()}>
                              Create
                            </button>
                          </div>
                        ) : (
                          <button type="button" id='button' className='add_category_btn' onClick={() => toggleAddCategoryInput()}>
                            + Add Category
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 mt-4'>
                    <label>Lead Source</label>
                    <div className='accordion position-relative'>
                      <div className={`accordion-header w-100 ${leadSourceOptionsOpen ? "active" : ""}`} onClick={() => {setLeadSourceOptionsOpen(!leadSourceOptionsOpen);fetchLeadSourceOptions()}}>
                        <span className='selected-option' eventid={leadSourceId}>{leadSource ? leadSource : "Select Lead Source"}</span>
                        <span className='icon overflow-hidden'>
                          <i className={leadSourceOptionsOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} />
                        </span>
                      </div>
                      <div className={`accordion-content  ${leadSourceOptions.length>4 ? "category_scroll" : ""} ${leadSourceOptionsOpen ? "active" : ""}`}>
                        {leadSourceOptions.map((option) => (
                          <div key={option.id} className='option' onClick={() => { setLeadSourceId(option.id);  setLeadSource(option.name); setLeadSourceOptionsOpen(false); }}>
                            {option.name}
                          </div>
                        ))}
                        {showAddLeadSourceInput ? (
                          <div className='input-with-button d-flex align-items-center'>
                            <input
                              type='text'
                              placeholder=' New Lead Source..'
                              value={newLeadSource}
                              onChange={(e) => setNewLeadSource(e.target.value)}
                            />
                            <button type="button" className='add_item_btn' onClick={() => handleCreateLeadSource()}>
                              Create
                            </button>
                          </div>
                        ) : (
                          <button type="button" id='button' className='add_category_btn' onClick={() => toggleAddLeadSourceInput()}>
                            + Add Lead Source
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12 mt-4'>
                    <label htmlFor='eventDate'>Event Date</label>
                    <input
                      name='eventDate'
                      id='eventDate'
                      type='date'
                      className='form-control'
                      placeholder='Event Date'
                      value={eventDate}
                      onChange={handleDateChange}
                      required
                    />
                  </div>
                  <div className='col-md-6 mt-4'>
                    <label htmlFor='startTime'>Start Time</label>
                    <input name='startTime' id='startTime' type='time' className='form-control' placeholder='Start Time' value={startTime} onChange={(e) => setStartTime(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-4'>
                    <label htmlFor='endTime'>End Time</label>
                    <input name='endTime' id='endTime' type='time' className='form-control' placeholder='End Time' value={endTime} onChange={(e) => setEndTime(e.target.value)} required />
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' onClick={onClose}>
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleCreateEventClick}>
                Create Event
              </button>
            </div>
          </div>):

          (<div className='modal-content userInfoModal-content'>
              {/* Modal header */}
              <div className='modal-header' style={{ backgroundColor: "#4f46e5" }}>
                <h5 className='modal-title text-center w-100' id='exampleModalLabel' style={{ color: "#fff", fontSize: "22px" }}>
                  Customer Info
                </h5>
                <button type='button' className='close' onClick={onClose} data-bs-dismiss='modal'>
                  <span aria-hidden='true' className='modal_button'>
                    <i className='fas fa-close'></i>
                  </span>
                  <span className='sr-only'>Close</span>
                </button>
              </div>
              <div className='modal-body px-3 px-md-5 modal_tabs event_modal_cont'>
                <div className=''>
                  <div className='d-flex align-items-center tabs_btn'>
                    <div>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='createNewClient1'
                        name='tab'
                        onClick={() => !isTabChanging && handleTabChange("createNewClient")}
                        checked={activeTab === "createNewClient"}
                        disabled={isTabChanging}
                      />
                      <label htmlFor='createNewClient1' className='ms-1'>
                        Create New Client
                      </label>
                    </div>
                    <div className='ms-2 ms-md-5'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='existingClient1'
                        name='tab'
                        onClick={() => !isTabChanging && handleTabChange("existingClient")}
                        checked={activeTab === "existingClient"}
                        disabled={isTabChanging}
                      />
                      <label htmlFor='existingClient1' className='ms-1'>
                        Existing Client
                      </label>
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-12'>
                      <div id='v-pills-home' className={`tab-pane ${activeTab === "createNewClient" ? "show active" : "fade d-none"}`}>
                        <CreateNewClientForm clientsDetailes={clientsDetailes} setClientdetailes={setClientdetailes} onClose={onClose}/>
                        
                      </div>
                    </div>
                    <div className='col-12'>
                      <div id='v-pills-profile' className={`tab-pane ${activeTab === "existingClient" ? "show active" : "fade d-none"}`}>
                        <ExistingClient clientsDetailes={clientsDetailes} setClientdetailes={setClientdetailes} onClose={onClose} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)
        }
        </div>
      </div>
      </>
  );
}

export default NewEventModal;
 