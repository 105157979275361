import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import "../All-Events/AllEvents.css";
import SendToCustomerFormModal from "../All-Events/SendToCustomerFormModal/SendToCustomerFormModal";
import { EventOverview } from "./EventOverview/EventOverview";
import { EventProducts } from "./EventProducts/EventProducts";
import { Payments } from "./Payments/Payments";
import { useNavigate } from 'react-router-dom'; 
export default function CustomerEvent() {
  let { state } = useLocation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { event } = state || {};

  const { eventid } = useParams();
  const [EventData, setEventData] = useState({});
  const navigate = useNavigate();

  const [userId, setUserId] = useState(null);

  useEffect(() => {
      // Check if user data exists in localStorage
      const udata = localStorage.getItem('user');
      if (!udata) {
          // Redirect to login page if user data does not exist
          navigate('/sign-in');
          return;
      }
      const user_data = JSON.parse(udata);
      setUserId(user_data.user.id);
  }, [navigate]);
 
  useEffect(() => {
    fetchEventDetails();
  }, [eventid]);

  const fetchEventDetails = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/event/${eventid}`);
      if (response.data.success === true) {
        setEventData(response.data.events);
      }
    } catch (error) {
      console.error('Error fetching event details:', error);
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, []);
 
  const handleUpdate = () => {
    // Re-fetch event details to reflect updates
    fetchEventDetails();
    console.log('hiii');
  };

  return (
    <>
      <SendToCustomerFormModal />
      <div className='container-fluid p-lg-5 p-md-5 p-2'>
        <div className='row'>
          <div className='col-lg-10 col-md-10 col-12 mx-auto'>
            <div className='top_title d-flex align-items-start mb-5'>
              <div>
                <h4>
                  {EventData.name}
                </h4>
                <p className='mb-0 mt-3'>
                  {EventData.address}
                </p>
              </div>

              <button
                className=' but-primary border-bottom-5 mx-auto'
                data-bs-toggle='modal'
                data-bs-target='#exampleModal'>
                Send Customer
              </button>
            </div>
            <nav>
              <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                <button
                  className='nav-link active fs-5'
                  id='nav-home-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-home'
                  type='button'
                  role='tab'
                  aria-controls='nav-home'
                  aria-selected='true'>
                  Event Overview
                </button>
                <button
                  className='nav-link fs-5'
                  id='nav-profile-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-profile'
                  type='button'
                  role='tab'
                  aria-controls='nav-profile'
                  aria-selected='false'>
                  Payment
                </button>
                <button
                  className='nav-link fs-5 border-bottom-5'
                  id='nav-contact-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-contact'
                  type='button'
                  role='tab'
                  aria-controls='nav-contact'
                  aria-selected='false'>
                  Event Products
                </button>
                <button
                  className='nav-link fs-5 border-bottom-5'
                  id='nav-contact-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-contact1'
                  type='button'
                  role='tab'
                  aria-controls='nav-contact'
                  aria-selected='false'>
                  Files
                </button>
              </div>
            </nav>
            <div className='tab-content mt-5' id='nav-tabContent'>
              <div
                className='tab-pane fade  show active'
                id='nav-home'
                role='tabpanel'
                aria-labelledby='nav-home-tab'>
                <EventOverview onUpdate={handleUpdate} />
              </div>
              <div
                className='tab-pane fade'
                id='nav-profile'
                role='tabpanel'
                aria-labelledby='nav-profile-tab'>
                <Payments />
              </div>
              <div
                className='tab-pane fade'
                id='nav-contact'
                role='tabpanel'
                aria-labelledby='nav-contact-tab'>
                <EventProducts />
              </div>
              <div
                className='tab-pane fade'
                id='nav-contact1'
                role='tabpanel'
                aria-labelledby='nav-contact-tab'>
                <div className='container-fluid py-5 px-2 bg-white'>
                  <div className='row'>
                    <div className='col-lg-10 col-md-10  col-12 mx-auto my-5'>
                      {event?.recentActivity === "NOT SENT" ? (
                        <div className='files_content'>
                          <div className='image'>
                            <img src='/img/files_image.png' alt='FILES' />
                          </div>

                          <div className='mt-5 text-center '>
                            <h4>Any Files Sent to Customer Will be Here</h4>
                            <p className=''>
                              Keep track of your event files that was used for
                              this event, All files such contracts, invoices,
                              proposals that was sent for This event will be
                              seen here
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h4>Proposal {event?.hours} Hours Event Sent</h4>

                          <div className='mt-4'>
                            <p className='m-0'>Proposal Sent</p>
                            <p className='m-0'>Client has not yet view</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
