import React from "react";
import StateSelectOption from "./StateSelectOption";

export default function CreatePayContent() {
  return (
    <section className='section_container my-4'>
      {/* Customer Details here */}
      <div className='customer_details details_top d-flex align-items-center justify-content-between flex-column flex-md-row column-gap-2'>
        <div className='text-center d-flex align-items-center'>
          <div className='logo'>
            {" "}
            <h4>
              Customer <br />
              Logo
            </h4>
          </div>

          <div className='details_text'>
            <h6>Payment 1 of 2</h6>
            <span>Due: Jul 12, 2023</span>
          </div>
        </div>
        <div className='title'>
          <h4>View Invoice</h4>
        </div>
      </div>

      {/* payment form area */}
      <div className='payment_form_container px-4 py-5'>
        <div className='due_amount mb-3'>
          <span>Amount Due</span>
          <h4>$200</h4>
        </div>

        <div className='way_to_pay'>
          <label>How do you want to pay?</label>
          <ul
            className='nav nav-pills mb-3 way_to_pay_nav'
            id='pills-tab'
            role='tablist'>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link active'
                id='pills-home-tab'
                data-bs-toggle='pill'
                data-bs-target='#pills-home'
                type='button'
                role='tab'
                aria-controls='pills-home'
                aria-selected='true'>
                Create Card
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                disabled
                className='nav-link'
                id='pills-profile-tab'
                data-bs-toggle='pill'
                data-bs-target='#pills-profile'
                type='button'
                role='tab'
                aria-controls='pills-profile'
                aria-selected='false'>
                Bank Transfer
              </button>
            </li>
          </ul>

          <div className='tab-content' id='pills-tabContent'>
            {/* Credit Form Area */}
            <div
              className='tab-pane fade show active mt-5'
              id='pills-home'
              role='tabpanel'
              aria-labelledby='pills-home-tab'>
              <form action=''>
                <div className='row'>
                  <div className='col-md-12 col-lg-6'>
                    <div className='row'>
                      {/* Card Number field */}
                      <div className='col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            Card Number
                          </label>
                          <input
                            type='number'
                            name='cardNumber'
                            className='form-control'
                            id='fullName'
                            required
                          />
                        </div>
                      </div>
                      {/* Name on card*/}
                      <div className='col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            Name on card
                          </label>
                          <input
                            type='number'
                            name='nameOnCard'
                            className='form-control'
                            id='fullName'
                            required
                          />
                        </div>
                      </div>
                      {/* Country*/}
                      <div className='col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            Country
                          </label>
                          <input
                            type='text'
                            name='country'
                            className='form-control'
                            id='fullName'
                            required
                          />
                        </div>
                      </div>
                      {/* Address*/}
                      <div className='col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            Address
                          </label>
                          <input
                            type='text'
                            name='address'
                            className='form-control'
                            id='fullName'
                            required
                          />
                        </div>
                      </div>

                      {/* City*/}
                      <div className='col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            City
                          </label>
                          <input
                            type='text'
                            name='city'
                            className='form-control'
                            id='fullName'
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12 col-lg-6'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            Expire
                          </label>
                          <input
                            type='text'
                            className='form-control input_height'
                            id='fullName'
                            required
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            CCV
                          </label>
                          <input
                            type='text'
                            className='form-control input_height'
                            id='fullName'
                            required
                          />
                        </div>
                      </div>
                      <div className='col-md-6 position-relative mt-md-4'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            State
                          </label>
                          <StateSelectOption />
                        </div>
                      </div>
                      <div className='col-md-6 mt-md-4'>
                        <div className='mb-3'>
                          <label htmlFor='fullName' className='form-label'>
                            ZIP code
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='fullName'
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex justify-content-end w-100'>
                  <button className='submit_button'>Pay $200.00</button>
                </div>
              </form>
            </div>

            <div
              className='tab-pane fade'
              id='pills-profile'
              role='tabpanel'
              aria-labelledby='pills-profile-tab'>
              <h4>Bank Transfer</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
