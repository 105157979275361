// src/redux/store.js
import { createStore } from 'redux';
import { combineReducers } from 'redux';
import titleReducer from './redux/titleSlice';

const rootReducer = combineReducers({
  title: titleReducer,
});

const store = createStore(rootReducer);

export default store;
