import React from "react";
// Import Routing Components For Linking to Web pages
import { Link } from "react-router-dom";
import "./Invoices.css";

export default function Invoices() {
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    //  ================= *****  INVOICE TEMPLATES BODY JSX ***** =================
    <>
      <div className='container-fluid  p-lg-5 p-md-5 p-3'>
        <div className='row g-lg-5 g-md-5 g-3'>
          <div className='col-sm-12 col-md-12 col-xl-12'>
            {/* Invoice Templates Description about this page */}
            <div className='mb-4'>
              <h3 className='invoice-heading'>Invoice Templates</h3>
              <p className='invoice-paragraph'>
                Product templates save time by streamlining the integration of
                individual items into various
                <br /> documents like brochures, invoices, and proposals,
                enabling efficient generation of consistent and
                <br />
                professional-looking files without starting from scratch for
                each new product.
              </p>
            </div>
          </div>
          {/* ========= Create a New Invoice Button ========= */}
          <div id='' className='col-sm-12 col-md-6 col-xl-4 invoice-items'>
            <div className='h-100 bg-white rounded p-3 shadow-sm'>
              <Link
                to='/create-invoice'
                id=''
                className='h-100 d-flex align-items-center justify-content-center px-2 py-5 p-urls'
                style={{ border: "1px dashed #534dbb" }}>
                <div className='mb-4 text-center create-invoice-btn'>
                  <i className='fa-solid fa-plus'></i>
                  <p className='mb-0 fw-light text-uppercase create-invoice-text'>
                    CREATE A <br /> NEW INVOICE
                  </p>
                </div>
              </Link>
            </div>
          </div>
          {/* ========= Firefly - 2 Hour Invoice Button ========= */}
          <div id='' className='col-sm-12 col-md-6 col-xl-4 invoice-items'>
            <div className='h-100 bg-white d-flex align-items-center justify-content-center rounded p-4 shadow-sm position-relative'>
              <div className='three-dot-btn'>
                <div className='position-absolute top-0 end-0 me-3 mt-2'>
                  <button className='border-0 bg-transparent ' type='button'>
                    <i className='fa-solid fa-ellipsis fs-3'></i>
                  </button>
                </div>
                <div className='text-center edit-btn'>
                  {/* File With Signature Icon */}

                  <button className='border-0 bg-transparent' type='button'>
                    <i className='fa-solid fa-file-signature'></i>
                  </button>
                  <p className='firefly-invoice'>Firefly - 2 Hour Invoice</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

    //  ================= *****  INVOICE TEMPLATES BODY JSX ***** =================
  );
}
