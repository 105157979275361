/* eslint-disable no-unused-vars */
import { useCallback, useRef, useState,useEffect} from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import CommonDropdownMenu from "../Shared/CommonDropdownMenu"; 
import axios from "axios";
const ItemTypes = {
  IMAGE: "image",
};

function ImagePreview({ src, index, moveImage, setPrimaryImage, removeImage }) {
  const ref = useRef(null);

  const [, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: { index },
  });
 
  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    hover: (item) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  drag(drop(ref));

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  useEffect(() => {
      // Check if user data exists in localStorage
      const udata = localStorage.getItem('user');
      if (!udata) {
          // Redirect to login page if user data does not exist
          navigate('/sign-in');
          return;
      }
      const user_data = JSON.parse(udata);
      setUserId(user_data.user.id);
  }, [navigate]);


  return (
    <div
      ref={ref}
      className='photo-wrapper photo_wrapper_right'
      style={{ cursor: "move", position: "relative" }}>
      <img
        src={src}
        alt='Preview'
        className='img-fluid'
        onDoubleClick={() => setPrimaryImage(index)}
      />
      <button
        type='button' // PrGallery form submission
        onClick={() => removeImage(index)}
        className='trash_btn position-absolute top-0 end-0 m-1'>
        &times;
      </button>
    </div>
  );
}

function AddGallerySettingForm() {
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [photos, setPhotos] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [primaryImageIndex, setPrimaryImageIndex] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [data, setData] = useState([]);
  const [GalleryTypeOptionsOpen, setGalleryTypeOptionsOpen] = useState(false);
  const [GalleryTypeId, setGalleryTypeId] = useState(0); 
  const [GalleryType, setGalleryType] = useState(""); // Updated to store id
  const [GalleryTypeOptions, setGalleryTypeOptions] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [showAddCategoryInput, setShowAddCategoryInput] = useState(false);
  const udata = localStorage.getItem('user');
  const user_data = JSON.parse(udata);
  const created_by = user_data.user.id;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const filesArray = Array.from(e.dataTransfer.files);
      setPhotos((prevPhotos) => [...prevPhotos, ...filesArray]);
      generatePreviews(filesArray);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const filesArray = Array.from(e.target.files);
      setPhotos((prevPhotos) => [...prevPhotos, ...filesArray]);
      generatePreviews(filesArray);
    }
  };

  const generatePreviews = (filesArray) => {
    const fileReaders = filesArray.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    });
  
    Promise.all(fileReaders).then((newPreviews) => {
      setPreviews((prevPreviews) => {
        // Combine existing previews with new previews
        const combinedPreviews = [...prevPreviews, ...newPreviews];
        
        if (combinedPreviews.length > 4) {
          return combinedPreviews.slice(0, 4);
        }
        
        // Return all previews if the total is 3 or less
        return combinedPreviews;
      });
    });
  };
  

  const onButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const moveImage = useCallback(
    (fromIndex, toIndex) => {
      const updatedPreviews = [...previews];
      const [movedImage] = updatedPreviews.splice(fromIndex, 1);
      updatedPreviews.splice(toIndex, 0, movedImage);
      setPreviews(updatedPreviews);

      // Adjust the primary image index based on the move
      if (fromIndex === primaryImageIndex) {
        setPrimaryImageIndex(toIndex);
      } else if (
        fromIndex < primaryImageIndex &&
        toIndex >= primaryImageIndex
      ) {
        setPrimaryImageIndex((prev) => prev - 1);
      } else if (
        fromIndex > primaryImageIndex &&
        toIndex <= primaryImageIndex
      ) {
        setPrimaryImageIndex((prev) => prev + 1);
      }
    },
    [previews, primaryImageIndex]
  );

  const setPrimaryImage = (index) => {
    setPrimaryImageIndex(index);
  };

  const removeImage = (index) => {
    setPreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
    if (index === primaryImageIndex) {
      setPrimaryImageIndex(0); // Reset primary image if the current primary is removed
    } else if (index < primaryImageIndex) {
      setPrimaryImageIndex((prev) => prev - 1); // Adjust primary image index if an earlier image is removed
    }
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("notes", notes);
    formData.append("GalleryTypeId", GalleryTypeId); // Ensure this matches your backend expectation
    formData.append("created_by", created_by); // Ensure this matches your backend expectation
  
    // Append each photo to FormData
    previews.forEach((url, index) => {
      formData.append(`previews[${index}]`, url);
    });
  
    // Axios POST request to backend
    axios.post(`${backendUrl}/api/gallery-add`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Important for sending files
      },
    })
    .then(response => {
      navigate("/gallery", { state: { updatedData: response.data } }); // Navigate or handle success state
    })
    .catch(error => {
      console.error("Error creating gallery:", error); 
    })
    .finally(() => {
      setIsSubmitting(false); // Enable the button after request is complete
    });
  };

  
  const fetchGalleryTypeOptions = async () => {
    if(created_by){
      try {
        const response = await axios.get(`${backendUrl}/api/gallery-category-list/${created_by}`);
        if (response.data.success === true) {
          // Map id to name for display and store id in state
          const options = response.data.gallary_categories.map((option) => ({
            id: option.id,
            name: option.name,
          }));
          setGalleryTypeOptions(options);
        }
      } catch (error) {
        console.error('Error fetching gallery:', error);
      }
    }
  }

  const handleCreateCategory = () => {
    if (newCategory.trim() !== "") {
      axios.post(`${backendUrl}/api/gallery-category-add`, { name: newCategory, created_by: created_by })
        .then(response => {
          fetchGalleryTypeOptions();
          setGalleryType(response.data.id); // Store id instead of name
          setNewCategory("");
          // setGalleryTypeOptionsOpen(false);
          setShowAddCategoryInput(false);
        })
        .catch(error => {
          console.error("Error creating category:", error);
        });
    }
  };

  const toggleAddCategoryInput = () => {
    setShowAddCategoryInput(!showAddCategoryInput);
  };


  const options = ["All", "Wedding", "Sweet16", "Birthday", "Party"];

  useEffect(() => {
    fetchGalleryTypeOptions();
  }, []);

  return (
    <div className='container mt-5'>
      <form onSubmit={handleSubmit} className='upload-form p-5'>
        <div className='form-group'>
          <label htmlFor='title' className='mb-1'>
            Title *
          </label>
          <input
            type='text'
            className='form-control'
            id='title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className='form-group mt-3'>
          <label htmlFor='photos' className='mb-1'>
            Photos (Only square images are allowed, meaning images must have the same height and width dimensions, like 400x400 or 300x300 pixels.)
          </label>
          {!previews.length && (
            <div className='custom-file'>
              <div id='form-file-upload' onDragEnter={handleDrag}>
                <input
                  ref={inputRef}
                  type='file'
                  id='input-file-upload'
                  multiple={true}
                  onChange={handleChange}
                />
                <label
                  id='label-file-upload'
                  htmlFor='input-file-upload'
                  className={dragActive ? "drag-active" : ""}>
                  <div>
                    <p>Drag & Drop</p>
                    <button className='custom-btn' onClick={onButtonClick}>
                      Add Up to 4 Photos 
                    </button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id='drag-file-element'
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}></div>
                )}
              </div>
            </div>
          )}

          {previews.length > 0 && (
            <div className='preview_images mb-3 mt-3'>
              <div className='row'>
                <div className='col-md-6 mb-3'>
                  <div className='photo-wrapper primary_img position-relative'>
                    <DropTarget
                      primaryImageIndex={primaryImageIndex}
                      setPrimaryImage={setPrimaryImage}
                      moveImage={moveImage}
                      removeImage={removeImage}>
                      <img
                        src={previews[primaryImageIndex]}
                        alt='Primary'
                        className='img-fluid'
                      />
                      <span className='badge text-white position-absolute  m-1'>
                        Primary
                      </span>
                      <button
                        type='button'
                        onClick={() => removeImage(primaryImageIndex)}
                        className='trash_btn position-absolute top-0 end-0 m-1'>
                        &times;
                      </button>
                    </DropTarget>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    {previews.map(
                      (preview, i) =>
                        i !== primaryImageIndex && (
                          <div key={i} className='col-sm-6'>
                            <ImagePreview
                              src={preview}
                              index={i}
                              moveImage={moveImage}
                              setPrimaryImage={setPrimaryImage}
                              removeImage={removeImage}
                            />
                          </div>
                        )
                    )}
                    {previews.length < 4 &&
                      <div className='col-sm-6 '>
                        <div className='photo-wrapper-two photo_wrapper_right'>
                          <div id='form-file-upload-two' onDragEnter={handleDrag}>
                            <input
                              ref={inputRef}
                              type='file'
                              id='input-file-upload'
                              multiple={true}
                              onChange={handleChange}
                            />
                            <div onClick={onButtonClick}>
                              <label
                                id='label-file-upload'
                                htmlFor='input-file-upload'
                                className={dragActive ? "drag-active" : ""}>
                                <svg
                                  fill='#e0e0e0'
                                  viewBox='0 0 24 24'
                                  xmlns='http://www.w3.org/2000/svg'>
                                  <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'></g>
                                  <g id='SVGRepo_iconCarrier'>
                                    <path d='M19,13a1,1,0,0,0-1,1v.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.7L9.41,11.12a2.85,2.85,0,0,0-3.93,0L4,12.6V7A1,1,0,0,1,5,6h7a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V14A1,1,0,0,0,19,13ZM5,20a1,1,0,0,1-1-1V15.43l2.9-2.9a.79.79,0,0,1,1.09,0l3.17,3.17,0,0L15.46,20Zm13-1a.89.89,0,0,1-.18.53L13.31,15l.7-.7a.77.77,0,0,1,1.1,0L18,17.21ZM22.71,4.29l-3-3a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-3,3a1,1,0,0,0,1.42,1.42L18,4.41V10a1,1,0,0,0,2,0V4.41l1.29,1.3a1,1,0,0,0,1.42,0A1,1,0,0,0,22.71,4.29Z'></path>
                                  </g>
                                </svg>
                              </label>
                            </div>

                            {dragActive && (
                              <div
                                id='drag-file-element'
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}></div>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='form-group mt-3'>
          <label>Gallery Type</label>
          <div className='accordion position-relative w-100'>
            <div className={`accordion-header w-100 ${GalleryTypeOptionsOpen ? "active" : ""}`} onClick={() => setGalleryTypeOptionsOpen(!GalleryTypeOptionsOpen)}>
              <span className='selected-option' GalleryId={GalleryTypeId}>{GalleryType ? GalleryType : "Select Gallery Type"}</span>
              <span className='icon overflow-hidden'>
                <i className={GalleryTypeOptionsOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} />
              </span>
            </div>
            <div className={`accordion-content ${GalleryTypeOptions.length>4 ? "category_scroll" : ""} ${GalleryTypeOptionsOpen ? "active" : ""}`}>
              {GalleryTypeOptions.map((option) => (
                <div key={option.id} className='option' onClick={() => { setGalleryTypeId(option.id); setGalleryType(option.name); setGalleryTypeOptionsOpen(false); }}>
                  {option.name}
                </div>
              ))}
              {showAddCategoryInput ? (
                <div className='input-with-button d-flex align-items-center'>
                  <input
                    type='text'
                    placeholder=' New Category..'
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                  />
                  <button type="button" className='add_item_btn' onClick={() => handleCreateCategory()}>
                    Create
                  </button>
                </div>
              ) : (
                <button type="button" id='button' className='add_category_btn' onClick={() => toggleAddCategoryInput()}>
                  + Add Category
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='form-group mt-3'>
          <label htmlFor='notes' className='mb-1'>
            Notes / Directions For Customer 
          </label>
          <textarea
            className='form-control'
            id='notes'
            rows='5'
            value={notes}
            onChange={(e) => setNotes(e.target.value)}></textarea>
        </div>

        <button
          type='submit'
          className='custom-btn d-flex align-items-center justify-content-center mt-4 w-25 mx-auto'
          disabled={isSubmitting}>
          {isSubmitting ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "DONE"
          )}
        </button>

      </form>
    </div>
  );
}

function DropTarget({
  primaryImageIndex,
  setPrimaryImage,
  moveImage,
  removeImage,
  children,
}) {
  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    drop: (item) => {
      moveImage(item.index, 0);
      setPrimaryImage(0);
    },
  });

  return <div ref={drop}>{children}</div>;
}

export default function WrappedAddGallerySettingForm() {
  return (
    <DndProvider backend={HTML5Backend}>
      <AddGallerySettingForm />
    </DndProvider>
  );
}
