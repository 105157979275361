import React from "react";
import CreateEmailTemplate from "./CreateEmailTemplate";
import { useParams } from "react-router-dom";

const EditEmailTemplate = () => {
  const { id } = useParams(); // Get the email template ID from the URL

  return <CreateEmailTemplate isEdit={true} emailTemplateId={id} />;
};

export default EditEmailTemplate;
