import { Link } from "react-router-dom";
import "./AllEvents.css";
import NewEventModal from "./NewEventModal/NewEventModal";
import SendToCustomerFormModal from "./SendToCustomerFormModal/SendToCustomerFormModal";
import customerEvents from "./customerEvents";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
export default function AllEvents() {
  const [events, setEvents] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const [userId, setUserId] = useState(null);

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Open modal function
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close modal function
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
      // Check if user data exists in localStorage
      const udata = localStorage.getItem('user');
      if (!udata) {
          // Redirect to login page if user data does not exist
          navigate('/sign-in');
          return;
      }
      const user_data = JSON.parse(udata);
      setUserId(user_data.user.id);
  }, [navigate]);


  useEffect(() => {
    const fetchEvents = async () => {
      if(userId){
        try {
          const response = await axios.get(`${backendUrl}/api/events-list/${userId}`);
          if(response.data.success==true){
            setEvents(response.data.events); 
          }
        } catch (error) {
          console.error('Error fetching events:', error);
        }
      }
    };
    fetchEvents();
  }, [userId]);

  const formatDate = (dateString) => {
    // Parse the date string directly
    const [year, month, day] = dateString.split('-');
    
    // Create a new date object using the parsed values
    const date = new Date(year, month - 1, day);
    
    // Format the month and day
    const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
    const formattedDay = String(date.getDate()).padStart(2, '0');
    const formattedYear = date.getFullYear();
    
    return `${formattedMonth}/${formattedDay}/${formattedYear}`;
  };
  

const formatTime = (time) => {
  // Split the time into hours and minutes
  const [hours, minutes] = time.split(':').map(Number);

  // Determine AM or PM suffix
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format minutes to ensure two digits
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Combine hours, minutes, and AM/PM suffix
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};


  return (
    <>
      <div className='container-fluid p-lg-5 p-md-5 p-2'>
        <div className='row'>
          <div className='col-lg-12 col-md-10 col-12 mx-auto'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-6'>
                <button
                  type='button'
                  className='but-primary'
                  onClick={openModal}>
                  New Event
                </button>
              </div>
              <div className='col-lg-6 col-md-6 col-6 text-end'>
                <p className='fw-bold'>Video Instructions</p>
              </div>
            </div>

            <div className='stage_container d-flex align-items-center justify-content-between'>
              <div className='box active'>
                <p>7</p>
                <h6>
                  ACTIVE
                  <br /> PROJECTS{" "}
                </h6>
                <div className='arrow-right active'></div>
              </div>
              <div className='box'>
                <p>7</p>
                <h6>INQUIRY</h6>
                <div className='arrow-right'></div>
              </div>
              <div className='box'>
                <div className='arrow-left'></div>
                <p>0</p>
                <h6>
                  PROPOSAL <br />
                  SENT
                </h6>
                <div className='arrow-right'></div>
              </div>
              <div className='box'>
                <div className='arrow-left'></div>
                <p>0</p>
                <h6>
                  PROPOSAL <br />
                  SIGNED
                </h6>
                <div className='arrow-right'></div>
              </div>
              <div className='box'>
                <div className='arrow-left'></div>
                <p>0</p>
                <h6>
                  EVENT <br />
                  COMPLETED
                </h6>
                <div className='arrow-right'></div>
              </div>
              <div className='box'>
                <p>0</p>
                <h6>ARCHIVE</h6>
              </div>
            </div>
            <div className='row'>
              <div className='table-responsive-sm d-none d-sm-block'>
                <table className='table table_events mt-5 table-space table-border-less'>
                  <thead className='py-2'>
                    <tr>
                      <th scope='col'>Client</th>
                      <th scope='col'>Event Name</th>
                      <th scope='col'>Event Date</th>
                      <th scope='col'>Hours</th>
                      <th scope='col'>Address</th>
                      <th scope='col'>Event Type</th>
                      <th scope='col'>Lead Source</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((event, i) => (
                      <tr className='px-2 position-relative' key={i}>
                        <td>{event.cl_firstname} {event.cl_lastname}</td>
                        <td className='event_link'>
                          <Link to={`/customer-events/${event.id}`} state={{ event }} key={i}>
                            {event.name}
                          </Link>
                        </td>
                        <td>{formatDate(event.date)}</td>
                        <td>{formatTime(event.from_time)} to {formatTime(event.to_time)}</td>
                        <td>{event.address}</td>
                        <td>{event.get_event_category.name}</td>
                        <td>{event.get_lead_source.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* for mobile view */}
              <div className='col-12 d-block d-sm-none mt-4'>
                <div className='customer_box_container'>
                  {customerEvents.map((event, i) => {
                    return (
                      <Link to='/customer-events' state={{ event }} key={i}>
                        <div className='customer_box d-flex justify-content-between align-items-center bg-white shadow-lg'>
                          <div className='customer_info'>
                            <p className='m-0'>Customer Name</p>
                            <span>{event.name}</span>
                          </div>
                          <div>
                            <button
                              type='button'
                              className='dropdown-toggle bg-transparent border-0'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'>
                              <i className='fas fa-ellipsis-v fa-xl'></i>
                            </button>
                            <ul className='dropdown-menu'>
                              <li>
                                <Link className='dropdown-item' href='#'>
                                  Move Stage
                                </Link>
                              </li>
                              <li>
                                <Link className='dropdown-item' href='#'>
                                  Archive
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className='dropdown-item text-danger'
                                  href='#'>
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Send To Customer Form Modal Here */}
      <SendToCustomerFormModal />
      {/* New Event Modal Here */}
      <NewEventModal isOpen={isModalOpen} onClose={closeModal}  />
    </>
  );
}
