import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CreateInvoiceContent from "./CreateInvoiceContent";
import CreatePayContent from "./CreatePayContent";

export default function CreateInvoice() {
  useEffect(() => {
    const offcanvasRight = document.getElementById("offcanvasRight2");
    offcanvasRight.addEventListener("hidden.bs.offcanvas", () => {
      const invoiceTabButton = document.getElementById("v-pills-home-tab");
      invoiceTabButton.click();
    });
  }, []);

  return (
    <div className='container-fluid p-lg-5 p-md-5 p-3'>
      <div className='row g-lg-5 row_mobile g-md-5 g-3 px-lg-5'>
        {/* Text Editor part */}
        <div className='col-md-9'>
          <div className='text-editor px-5 py-3'>
            <div
              className='tab-content tab_content_invoice'
              id='v-pills-tabContent'>
              <div
                className='tab-pane fade show active'
                id='v-pills-home'
                role='tabpanel'
                aria-labelledby='v-pills-home-tab'
                tabIndex='0'>
                <CreateInvoiceContent />
              </div>
              <div
                className='tab-pane fade'
                id='v-pills-profile'
                role='tabpanel'
                aria-labelledby='v-pills-profile-tab'
                tabIndex='0'>
                <CreatePayContent />
              </div>
              <div
                className='tab-pane h-100 fade'
                id='v-pills-disabled'
                role='tabpanel'
                aria-labelledby='v-pills-disabled-tab'
                tabIndex='0'>
                <div className='add-page text-center d-flex flex-column justify-content-center align-items-center h-100'>
                  <h2>Add pages from your templates</h2>
                  <p>
                    Browse your templates to add pages <br /> or select “Start
                    from blank” to build a page from scratch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contract Right Area */}
        <div className='col-md-3'>
          <span className='placeholder col-6'></span>

          <div className='row'>
            <div className='col-12'>
              <div className='right-side-area'>
                <div
                  className='nav flex-column nav-pills me-3'
                  id='v-pills-tab'
                  role='tablist'
                  aria-orientation='vertical'>
                  {/* Current Page Begin: Invoice Box */}
                  <button
                    className='nav-link active'
                    id='v-pills-home-tab'
                    data-bs-toggle='pill'
                    data-bs-target='#v-pills-home'
                    type='button'
                    role='tab'
                    aria-controls='v-pills-home'
                    aria-selected='true'>
                    {/* Current Page: Invoice Box */}
                    <div className='right-side-box'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <div
                            className='icon-box active bg-white'
                            role='button'>
                            {/* Skeletons */}
                            <div className='row gap-2 align-items-start m-2'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-two'></div>
                              <div className='col-12 skeleton-three'></div>
                              <div className='col-12 skeleton-four'></div>
                            </div>
                          </div>
                          <h5 className='box-title'>Invoice</h5>
                        </div>
                        {/* Dropdown */}
                        <div className='nav-item dropdown' id='top-user-nav'>
                          <div
                            href='#'
                            className='nav-link dropdown'
                            data-bs-toggle='dropdown'>
                            <div className='three-dot-btn'>
                              <button
                                className='border-0 bg-transparent'
                                type='button'>
                                <i className='fa-solid fa-ellipsis-vertical'></i>
                              </button>
                            </div>
                          </div>
                          {/* Dropdown Menu */}
                          <div className='dropdown-menu shadow-sm border-0 py-4 px-4'>
                            <Link to='#'>
                              <div className='link-btn mb-4'>
                                <i
                                  className='fa fa-pencil'
                                  aria-hidden='true'></i>
                                <span>Rename</span>
                              </div>
                            </Link>
                            <Link to='#'>
                              <div className='link-btn text-danger'>
                                <i className='fa-solid fa-trash'></i>
                                <span>Delete</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                  {/* Current Page End: Invoice Box */}

                  {/* Current Page begin: Pay Box */}
                  <button
                    className='nav-link'
                    id='v-pills-profile-tab'
                    data-bs-toggle='pill'
                    data-bs-target='#v-pills-profile'
                    type='button'
                    role='tab'
                    aria-controls='v-pills-profile'
                    aria-selected='false'>
                    {/* Current Page: Pay Box */}
                    <div className='right-side-box'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <div
                            className='icon-box active bg-white'
                            role='button'>
                            {/* Skeletons */}
                            <div className='row gap-2 align-items-start m-2'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-two'></div>
                              <div className='col-12 skeleton-three'></div>
                              <div className='col-12 skeleton-four'></div>
                            </div>
                          </div>
                          <h5 className='box-title'>Pay</h5>
                        </div>
                        {/* Dropdown */}
                        <div className='nav-item dropdown' id='top-user-nav'>
                          <div
                            href='#'
                            className='nav-link dropdown'
                            data-bs-toggle='dropdown'>
                            <div className='three-dot-btn'>
                              <button
                                className='border-0 bg-transparent'
                                type='button'>
                                <i className='fa-solid fa-ellipsis-vertical'></i>
                              </button>
                            </div>
                          </div>
                          {/* Dropdown Menu */}
                          <div className='dropdown-menu shadow-sm border-0 py-4 px-4'>
                            <Link to='#'>
                              <div className='link-btn mb-4'>
                                <i
                                  className='fa fa-pencil'
                                  aria-hidden='true'></i>
                                <span>Rename</span>
                              </div>
                            </Link>
                            <Link to='#'>
                              <div className='link-btn text-danger'>
                                <i className='fa-solid fa-trash'></i>
                                <span>Delete</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                  {/* Current Page end: Pay Box */}

                  {/* Add Page Start: New Page */}
                  <button
                    className='nav-link'
                    id='v-pills-disabled-tab'
                    data-bs-toggle='pill'
                    data-bs-target='#v-pills-disabled'
                    type='button'
                    role='tab'
                    aria-controls='v-pills-disabled'
                    aria-selected='false'>
                    {/* Add Page: New Page */}
                    <div className='right-side-box'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div
                          data-bs-toggle='offcanvas'
                          data-bs-target='#offcanvasRight2'
                          aria-controls='offcanvasRight2'
                          className='d-flex align-items-center'>
                          <div
                            className='icon-box icon-box-add-page'
                            role='button'>
                            <span>
                              <i className='fa-solid fa-plus'></i>
                            </span>
                          </div>
                          <h5 className='box-title'>Add Page</h5>
                        </div>
                        {/* Dropdown */}
                        <div
                          className='nav-item dropdown'
                          id='top-user-nav'
                          aria-disabled='true'>
                          <div
                            href='#'
                            className='nav-link dropdown'
                            data-bs-toggle='dropdown'></div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>

                {/* Add page canvas */}
                <div className='create-contract '>
                  <div
                    className='offcanvas offcanvas-end'
                    tabIndex='-1'
                    id='offcanvasRight2'
                    data-bs-scroll='true'
                    aria-labelledby='offcanvasRightLabel'
                    data-bs-backdrop='false'>
                    <div className='w-100 text-start header'>
                      <button
                        type='button'
                        className='btn-close mt-3 ms-4 text-black'
                        data-bs-dismiss='offcanvas'
                        aria-label='Close'></button>

                      <div className='text-center py-3'>
                        <h5>Add Page</h5>
                        <span style={{ color: "#727272" }}>
                          Select from the list below
                        </span>
                      </div>
                    </div>
                    <div className='w-100 text-start header'>
                      <div className='text-center py-3'>
                        <span>+ Page With Text Only</span>
                      </div>
                    </div>

                    <div className='offcanvas-body p-0'>
                      <h5 className='px-4 py-2'>Contracts</h5>

                      <div className='single_box mt-3'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div>
                            <h6>New Contract</h6>
                            <div className='d-flex align-items-center mt-1'>
                              <i className='far fa-folder'></i>
                              <span className='ms-2'>Contract</span>
                            </div>
                          </div>
                          <div className='icon'>
                            <i className='fa fa-chevron-right'></i>
                          </div>
                        </div>
                      </div>

                      <div className='single_box mt-4'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div>
                            <h6>Basic Booth Contract</h6>
                            <div className='d-flex align-items-center mt-1'>
                              <i className='far fa-folder'></i>
                              <span className='ms-2'>Contract</span>
                            </div>
                          </div>
                          <div className='icon'>
                            <i className='fa fa-chevron-right'></i>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h5 className='py-4 px-4'>Invoice</h5>
                        <div className='single_box mt-3'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div>
                              <h6>New Invoice</h6>
                              <div className='d-flex align-items-center mt-1'>
                                <i className='far fa-folder'></i>
                                <span className='ms-2'>Invoice</span>
                              </div>
                            </div>
                            <div className='icon'>
                              <i className='fa fa-chevron-right'></i>
                            </div>
                          </div>
                        </div>
                        <div className='single_box mt-4'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div>
                              <h6>Click Firefly - 2 Hour Invoice</h6>
                              <div className='d-flex align-items-center mt-1'>
                                <i className='far fa-folder'></i>
                                <span className='ms-2'>Invoice</span>
                              </div>
                            </div>
                            <div className='icon'>
                              <i className='fa fa-chevron-right'></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
