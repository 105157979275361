import React, { useState } from "react"; // Removed `useEffect` since it's not used in this component

import { Link, NavLink } from "react-router-dom";
import NewEventModal from "../../Events/All-Events/NewEventModal/NewEventModal";
import "./Sidebar.css";

const Sidebar = ({ sidebarOpen }) => {
  const currentDate = new Date(); // Moved currentDate inside the functional component

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reset, setReset] = useState(false);

  // Open modal function
  const openModal = () => {
    setIsModalOpen(true);
    setReset(prevState => !prevState);
  };

  // Close modal function
  const closeModal = () => {
    setIsModalOpen(false);
  };


  const handleClick = () => {
    window.location.href = `/day-calendar/${currentDate.toISOString().split('T')[0]}`;
  };
  
  return (
    <>
      <div id='sidebar' className={`sidebar pe-0 pt-4 bg-blue ${sidebarOpen ? "open" : ""}`}>
        <nav className='navbar bg-light bg-blue'>
          <a href='index.html' className='navbar-brand mx-5 mb-3'>
            <h3 className='text-white'>LOGO</h3>
          </a>
          <div className='navbar-nav w-100 pt-5'>
            {/* Dashboard Link */}
            <NavLink to={"/"} className='nav-item nav-link text-uppercase' activeClassName='active'>
              <i className='fa fa-home me-3' /> Dashboard
            </NavLink>

            {/* Calendar Dropdown */}
            <div className='nav-item dropdown'>
              <Link to='#' className='nav-link dropdown-toggle text-uppercase' data-bs-toggle='dropdown'>
                <i className='fa-solid fa-calendar-days me-3' /> Calendar
              </Link>
              <div className='dropdown-menu border-0 m-0 p-0' id='bg-dropdown'>
                <Link to='/month-calendar' className='dropdown-item nav-link'>
                  Month View
                </Link>
                <Link onClick={handleClick} className='dropdown-item nav-link'>
                    Day View
                </Link>
              </div>
            </div>

            {/* Templates Link */}
            <NavLink to={"/templates"} className='nav-item nav-link text-uppercase' activeClassName='active'>
              <i className='fa-solid fa-briefcase me-3' /> Templates
            </NavLink>

            {/* Events Dropdown */}
            <div className='nav-item dropdown'>
              <Link to='#' className='nav-link dropdown-toggle text-uppercase' data-bs-toggle='dropdown'>
                <i className='fa-solid fa-calendar-plus me-3' /> Events
              </Link>
              <div className='dropdown-menu border-0 m-0 py-0' id='bg-dropdown'>
                <Link to={"/events"} className='dropdown-item nav-link text-uppercase'>
                  View Events
                </Link>
                <div className='dropdown-item nav-link new_event_btn text-center justify-content-start'>
                  <button onClick={openModal} type='button'>
                    New Event
                  </button>
                </div>
              </div>
            </div>

            {/* Gallery Link */}
            <Link to='/gallery' className='nav-item nav-link text-uppercase'>
              <i className='fa-solid fa-images me-3' /> Gallery
            </Link>
          </div>
        </nav>
      </div>

      {/* New Event Modal */}
      <NewEventModal isOpen={isModalOpen} onClose={closeModal} curDate={""} reset={reset}/>
    </>
  );
};

export default Sidebar;
