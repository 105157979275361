import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { deleteProduct, fetchProducts } from "../../services/productsService"; // Import your service functions
import { deleteEmail } from '../../services/emailsService';
import { useEditableContent } from '../../Context/EditableContentContext';

function Email() {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [newTemplate, setNewTemplate] = useState({ title: '', content: '' });
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(`${backendUrl}/api/email-templates`)
      .then(response => setEmailTemplates(response.data))
      .catch(error => console.error(error));
  }, []);

  const handleEdit = (emailId) => {
    // Navigate to the edit page
    console.log(`Editing email with ID: ${emailId}`);
    // Assuming your edit page route is '/edit-email/:id', replace it with your actual route
    // You can pass the emailId as a parameter in the URL
    // For example, if your edit page route is '/edit-email/:id', the Link will be:
    window.location.href = `/edit-email-template/${emailId}`;
  };

  const handleDelete = async (emailId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await deleteEmail(emailId);
        setEmailTemplates(emailTemplates.filter((email) => email.id !== emailId));
        Swal.fire("Deleted!", "Your email has been deleted.", "success");
        //handleProductContentChange("Untitled Item");
      }
    } catch (error) {
      console.error(`Failed to delete email with ID ${emailId}:`, error);
      Swal.fire("Error", "Failed to delete email.", "error");
    }
  };
  const createTemplate = () => {
    axios.post(`${backendUrl}/api/email-templates`, newTemplate)
      .then(response => {
        setEmailTemplates([...emailTemplates, response.data]);
        setNewTemplate({ title: '', content: '' });
      })
      .catch(error => console.error(error));
  };

  const updateTemplate = (id, updatedTemplate) => {
    axios.put(`/api/email-templates/${id}`, updatedTemplate)
      .then(response => {
        const updatedTemplates = emailTemplates.map(template => 
          template.id === id ? response.data : template
        );
        setEmailTemplates(updatedTemplates);
      })
      .catch(error => console.error(error));
  };

  const toggleMenu = (email) => {
    setSelectedEmail(email);
    setMenuVisible(!menuVisible);
  };

  return (
    <div>
      <div className='container-fluid'>
        <div>Templates &gt; Emails</div>
      </div>

      <div className='container-fluid p-lg-5 p-md-5 p-3'>
        <div className='row g-lg-5 g-md-5 g-3'>
          <div className='col-sm-12 col-md-12 col-xl-12'>
            <div className='mb-4'>
              <h3 className='mb-1'>Emails</h3>
              <p>
                Do you often find yourself repeating the information to clients?
                Why not create an email <br /> template? You can write email
                templates for any language you commonly use throughout your
              </p>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-xl-4 product-items'>
            <div className='h-100 bg-white rounded p-3 shadow-sm'>
              <Link
                to='/create-email-template'
                id=''
                className='h-100 d-flex align-items-center justify-content-center px-2 py-5 p-urls'
                style={{ border: "1px dashed #534dbb" }}>
                <div className='mb-4 text-center'>
                  <i
                    className='fa-solid fa-plus'
                    style={{ fontSize: "70px" }}></i>
                  <h6 className='mb-0 fw-bold text-uppercase'>
                    CREATE A EMAIL TEMPLATE
                  </h6>
                </div>
              </Link>
            </div>
          </div>
          {emailTemplates.map(template => (
            <div key={template.id} className='col-sm-12 col-md-6 col-xl-4 product-items'>

              
              <div className='h-100 bg-white d-flex align-items-center justify-content-center rounded p-4 shadow-sm position-relative product-items2' onClick={ () =>  handleEdit(template.id)}>
                
                
                <div className='three-dot-btn' >
                  
                  <div className='position-absolute top-0 end-0 me-3 mt-2'>
                    <button className='border-0 bg-transparent ' type='button'>
                      <div id='p-dot' style={{ position: "relative", zIndex: 10000 }}>
                      <FaEllipsisV
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent click event from propagating to the parent div
                          toggleMenu(template);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      {menuVisible && selectedEmail === template && (
                        <div
                          className='position-absolute bg-white shadow-sm rounded'
                          style={{
                            top: "20px",
                            right: "0",
                            zIndex: "10",
                          }}>
                          <button
                            className='dropdown-item'
                            onClick={() => handleEdit(template.id)}>
                            Edit
                          </button>
                          <button
                            className='dropdown-item'
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(template.id)}
                              }>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                    </button>
                  </div>

                  <p style={{ fontSize: 12 }}>
                    Created at{" "}
                    {new Date(template.created_at).toLocaleDateString()}
                  </p>
                  <div className='text-center edit-btn'>
                    <button className='border-0 bg-transparent' type='button'>
                      <i className='fa-solid fa-file-signature'></i>
                    </button>
                    <p className='basic-photo'>
                      {template.title.toUpperCase()}
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Email;
