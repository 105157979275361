import React from "react";
// Import Routing Components For Linking to Web pages

import CalendarGrid from "./CalendarGrid";
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth()+1;
console.log(currentMonth);
const MonthCalendar = ({ initialYear, initialMonth }) => {
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    //  ================= *****  TEMPLATES BODY JSX ***** =================
    <>
      <div className='container-fluid p-lg-5 p-md-5 p-2'>
        <CalendarGrid initialYear={currentYear} initialMonth={currentMonth} />
      </div>
    </>

    // ================= *****  TEMPLATES BODY JSX ***** =================
  );
};

// export { MonthCalendar }; /* ========================= We had to remove default. */
export default MonthCalendar;
