import React, { useState } from "react";
import "./Proposal.css";

export default function CreateProposal() {
  const [showSideCanvas, setShowSideCanvas] = useState(true);

  const handleClose = () => {
    setShowSideCanvas(false);
  };

  const handleAddPage = () => {
    setShowSideCanvas(true);
  };

  return (
    <>
      <div className='container-fluid p-lg-5 p-md-5 p-3'>
        <div className='row g-lg-5 g-md-5 g-3 px-lg-5'>
          {/* Add Page part */}
          <div className='col-lg-8'>
            <div
              className='text-editor px-2 px-md-5 py-3'
              style={{ minHeight: "710px", height: "100%" }}>
              <div className='tab-content h-100' id='v-pills-tabContent'>
                {/* add page */}
                <div
                  onClick={handleAddPage}
                  className='add-page add-proposal  d-flex flex-column justify-content-center align-items-center text-center h-100'
                  type='button'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasRight'
                  aria-controls='offcanvasRight'>
                  <img src='/img/edit_icon.png' alt='ICON' />
                  <h2 className='mt-4'>Add page</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Add Page Right Area */}

          <div className='col-lg-4'>
            <div className='create-contract proposal_right_side'>
              <div
                className={`offcanvas offcanvas-end ${
                  showSideCanvas ? "show" : ""
                }`}
                tabIndex='-1'
                id='offcanvasRight'
                data-bs-scroll='true'
                aria-labelledby='offcanvasRightLabel'
                data-bs-backdrop='false'>
                <div className='w-100 text-start header'>
                  <button
                    type='button'
                    className='btn-close mt-3 ms-4 text-black'
                    data-bs-dismiss='offcanvas'
                    aria-label='Close'
                    onClick={handleClose}></button>

                  <div className='text-center py-3 proposal-header'>
                    <h5>Add Page</h5>
                    <span style={{ color: "#727272" }}>
                      Select from the list below
                    </span>
                  </div>
                </div>
                <div className='w-100 text-start header proposal-header'>
                  <div className='text-center py-3'>
                    <span>+ Page With Text Only</span>
                  </div>
                </div>

                <div className='offcanvas-body p-0'>
                  <h5 className='px-4 py-2'>Contracts</h5>

                  <div className='single_box mt-3'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div>
                        <h6>New Contract</h6>
                        <div className='d-flex align-items-center mt-1'>
                          <i className='far fa-folder'></i>
                          <span className='ms-2'>Contract</span>
                        </div>
                      </div>
                      <div className='icon'>
                        <i className='fa fa-chevron-right'></i>
                      </div>
                    </div>
                  </div>

                  <div className='single_box mt-4'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div>
                        <h6>Basic Booth Contract</h6>
                        <div className='d-flex align-items-center mt-1'>
                          <i className='far fa-folder'></i>
                          <span className='ms-2'>Contract</span>
                        </div>
                      </div>
                      <div className='icon'>
                        <i className='fa fa-chevron-right'></i>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h5 className='py-4 px-4'>Invoice</h5>
                    <div className='single_box mt-3'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div>
                          <h6>New Invoice</h6>
                          <div className='d-flex align-items-center mt-1'>
                            <i className='far fa-folder'></i>
                            <span className='ms-2'>Invoice</span>
                          </div>
                        </div>
                        <div className='icon'>
                          <i className='fa fa-chevron-right'></i>
                        </div>
                      </div>
                    </div>
                    <div className='single_box mt-4'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div>
                          <h6>Click Firefly - 2 Hour Invoice</h6>
                          <div className='d-flex align-items-center mt-1'>
                            <i className='far fa-folder'></i>
                            <span className='ms-2'>Invoice</span>
                          </div>
                        </div>
                        <div className='icon'>
                          <i className='fa fa-chevron-right'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
