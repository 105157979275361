import React, { useState } from 'react';

export default function PreviewTabBtn({ GalleryTypeOptions,currentCategory,setCurrentCategory}) {
 

  const handleCategoryClick = (name) => {
    setCurrentCategory(name); // Update state with clicked category name
  };

  return (
    <ul className='nav border-2 border-right flex-column preview_tab'>
      <li className='nav-item'>
        <h4 className='px-3 mb-3'>Categories</h4>
      </li>
      <li className='nav-item'>
        <a
          className={`nav-link ${currentCategory === 'All' ? 'active' : ''}`}
          id='tab1'
          data-bs-toggle='pill'
          href='#content1'
          onClick={() => handleCategoryClick(0)}
        >
          All
        </a>
      </li>
      {GalleryTypeOptions.map((option, index) => (
        <li className='nav-item' key={index}>
          <a
            className={`nav-link ${currentCategory === option.name ? 'active' : ''}`}
            id={`tab${index + 2}`}
            data-bs-toggle='pill'
            href={`#content${index + 2}`}
            onClick={() => handleCategoryClick(option.id)}
          >
            {option.name}
          </a>
        </li>
      ))}
    </ul>
  );
}
