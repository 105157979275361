import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Contracts.css";
import { FaEllipsisV } from "react-icons/fa";
import { deleteContract } from "../../services/contractService";
import Swal from "sweetalert2";

export default function Contracts() {
  const [contracts, setContracts] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    // Fetch the contracts data from the API
    const fetchContracts = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/contracts`);
        setContracts(response.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    };

    fetchContracts();
  }, []);

  const handleEdit = (emailId) => {
    window.location.href = `/edit-contract/${emailId}`;
  };

  const handleDelete = async (contractId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await deleteContract(contractId);
        setContracts(contracts.filter((contract) => contract.id !== contractId));
        Swal.fire("Deleted!", "Contract has been deleted.", "success");
        //handleProductContentChange("Untitled Item");
      }
    } catch (error) {
      console.error(`Failed to delete Contract with ID ${contractId}:`, error);
      Swal.fire("Error", "Failed to delete Contract.", "error");
    }
  };
  const toggleMenu = (email) => {
    setSelectedEmail(email);
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <div className='container-fluid p-lg-5 p-md-5 p-3'>


      
        
        <div className='row g-lg-5 g-md-5 g-3'>
          <div className='col-sm-12 col-md-12 col-xl-12'>
            <div className='mb-4'>
              <h3 className='contract-heading'>Contracts Templates</h3>
              <p className='contract-paragraph'>
                Product templates save time by streamlining the integration of
                individual items into various
                <br /> documents like brochures, invoices, and proposals,
                enabling efficient generation of consistent and
                <br />
                professional-looking files without starting from scratch for
                each new product.
              </p>
            </div>
          </div>
          <div id='' className='col-sm-12 col-md-6 col-xl-4 contact-items'>
            <div className='h-100 bg-white rounded p-3 shadow-sm'>
              <Link
                to='/create-contract'
                id=''
                className='h-100 d-flex align-items-center justify-content-center px-2 py-5 p-urls'
                style={{ border: "1px dashed #534dbb" }}>
                <div className='mb-4 text-center create-contract-btn'>
                  <i className='fa-solid fa-plus'></i>
                  <p className='mb-0 fw-light text-uppercase create-contract-text'>
                    CREATE A <br /> NEW CONTRACT
                  </p>
                </div>
              </Link>
            </div>
          </div>
          {contracts.map((contract) => (
            <div key={contract.id} id='' className='col-sm-12 col-md-6 col-xl-4 contact-items'>


            
                    
              <div className='h-100 bg-white d-flex align-items-center justify-content-center rounded p-4 shadow-sm position-relative product-items2' onClick={ () =>  handleEdit(contract.id)}>
                <div className='three-dot-btn'>
                <div className='position-absolute top-0 end-0 me-3 mt-2'>
              <button className='border-0 bg-transparent ' type='button'>
                <div id='p-dot' style={{ position: "relative", zIndex: 10000 }}>
                <FaEllipsisV
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from propagating to the parent div
                    toggleMenu(contract);
                  }}
                  style={{ cursor: "pointer" }}
                />
                {menuVisible && selectedEmail === contract && (
                  <div
                    className='position-absolute bg-white shadow-sm rounded'
                    style={{
                      top: "20px",
                      right: "0",
                      zIndex: "10",
                    }}>
                    <button
                      className='dropdown-item'
                      onClick={() => handleEdit(contract.id)}>
                      Edit
                    </button>
                    <button
                      className='dropdown-item'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(contract.id)}
                        }>
                      Delete
                    </button>
                  </div>
                )}
              </div>
              </button>
            </div>
                  <div className='text-center edit-btn'>
                    <button className='border-0 bg-transparent' type='button'>
                      <i className='fa-solid fa-file-signature'></i>
                    </button>
                    <p className='basic-photo'>
                      {contract.title.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
