import { useLocation } from "react-router-dom";
function Payments() {
  let { state } = useLocation();

  const { event } = state || {};

  return (
    <>
      <div className='container-fluid p-lg-5 p-md-5 p-4 bg-white'>
        <div className='row'>
          {event?.recentActivity === "NOT SENT" ? (
            <div className='col-lg-11 col-md-11 mx-auto text-center payment_tab'>
              <span>
                Send an invoice or proposal with a do-able payment schedule by
                clicking on the "send customer" button. Then, Track payments
                with the help of reminders so everyone's always on the same
                page.
              </span>
            </div>
          ) : (
            <div className='col-lg-11 col-md-11 mx-auto'>
              <h4> Proposal-2 Hour Event</h4>
              <h5 className='mt-3'>Payment Schedule</h5>
              <div className='mt-4 table-responsive'>
                <table className='table '>
                  <thead>
                    <tr>
                      <th scope='col' className='amou_nt'>
                        AMOUNT{" "}
                      </th>
                      <th scope='col' className='amou_nt'>
                        DUE DATE
                      </th>
                      <th scope='col' className='amou_nt'>
                        PAYMENT DATE
                      </th>
                      <th scope='col' className='amou_nt'>
                        PAYMENT ID
                      </th>
                      <th scope='col' className='amou_nt'>
                        STATUS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope='row'>$200</th>
                      <td>Aug 15, 2023</td>
                      <td></td>
                      <td>#00001-001</td>
                      <td>OVERDUE</td>
                    </tr>
                    <tr>
                      <th scope='row'>$200</th>
                      <td>Sep 15, 2023</td>
                      <td></td>
                      <td>#00001-001</td>
                      <td>UPCOMING </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='d-flex justify-content-end py-4 '>
                <button
                  type='button'
                  className='btn btn-primary rounded-pill px-4 py-2'>
                  {" "}
                  VIEW INVOICE
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { Payments }; /* ========================= We had to remove default. */
