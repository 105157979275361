import React, { useState } from "react";
import "./ContractForm.css";
import Form from "./Form";
export default function ContractForm() {
  const [showCustomURLInput, setShowCustomURLInput] = useState(true);

  const handleRadioChange = (event) => {
    setShowCustomURLInput(event.target.value === "custom");
  };

  const [expanded, setExpanded] = useState(false);

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className='container-fluid p-lg-0 py-lg-3 p-md-5 p-3'>
        <div className='row g-lg-5 g-md-5 g-3 px-lg-5'>
          {/*begin: Main form area */}
          <div className='col-md-8'>
            <div className='form_container'>
              <Form />
            </div>
          </div>
          {/*end: Main form area */}
          {/*begin: Sidebar area */}
          <div className='col-lg-4'>
            <div
              className={`side_bar_contract_form shadow  ${
                expanded ? "aside-menu--expanded" : ""
              }`}>
              <div className='sidebar_header'>
                {!expanded ? (
                  <button
                    type='button'
                    className='mt-3 ms-4 text-black hamburger_side'
                    onClick={toggleSidebar}>
                    <i className='fas fa-times'></i>
                  </button>
                ) : (
                  <button
                    type='button'
                    className='mt-3 ms-4 text-black hamburger_side hamburger_side_mobile'
                    onClick={toggleSidebar}>
                    <i className='fas fa-bars hamburger_side_icon'></i>
                  </button>
                )}
                <h5
                  className={`mb-2 ${
                    expanded ? "aside-menu--expanded-body-content" : ""
                  }`}>
                  Setting
                </h5>
              </div>
              <div
                className={`sidebar_content_body px-2 ${
                  expanded ? "aside-menu--expanded-body-content" : ""
                }`}>
                <div className='form_sidebar'>
                  <div className='sidebar_main'>
                    <div className='sidebar-content'>
                      <h5 className='mb-3'>
                        After Form Complication Redirect To
                      </h5>
                      <div className='form_group position-relative'>
                        <div className='form-check mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='redirectOption'
                            id='flexRadioDefault1'
                            value='default'
                            onChange={handleRadioChange}
                            defaultChecked
                          />
                          <div>
                            <label
                              className='form-check-label form-check-label_sidebar label_tooltip'
                              htmlFor='flexRadioDefault1'>
                              Default Thank You Message
                              <span className='ques_arrow'>
                                <i className='fa-solid fa-question'></i>
                              </span>
                            </label>
                            <div className='toolTipContent d-none'>
                              <span>
                                When your client submits a form, well show a
                                default Thank You message to confirm receipt
                              </span>
                              <div className='arrowForm'></div>
                            </div>
                          </div>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='redirectOption'
                            id='flexRadioDefault2'
                            value='custom'
                            onChange={handleRadioChange}
                          />
                          <label
                            className='form-check-label form-check-label_sidebar'
                            htmlFor='flexRadioDefault2'>
                            Your Own Custom URL
                          </label>

                          {showCustomURLInput && (
                            <div className='link_input'>
                              <input
                                type='text'
                                placeholder='https://mywebsite.com/thanks'
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='sidebar-bottom'>
                      <h5 className='mb-3'>SPAM PROTECTION</h5>
                      <p>
                        PB Web App uses Google as your Contact Form Spam
                        Protection
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end: Sidebar area */}
        </div>
      </div>
    </>
  );
}
