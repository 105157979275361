import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NewEventModal from "../Events/All-Events/NewEventModal/NewEventModal";
import axios from "axios";

const truncateText = (text, maxLength) => {
  return text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;
};

const CalendarGrid = ({ initialYear, initialMonth }) => {
  const [currentDate, setCurrentDate] = useState(new Date(initialYear, initialMonth, 1));
  const [selectedDate, setSelectedDate] = useState(null);
  const [curDate, setCurDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [events, setEvents] = useState({});
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const udata = localStorage.getItem('user');
    if (!udata) {
      navigate('/sign-in');
      return;
    }
    const user_data = JSON.parse(udata);
    setUserId(user_data.user.id);
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      axios.post(`${backendUrl}/api/get-curr-month-events`, {
        date: `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}`, // Local month and year
        created_by: userId
      })
      .then(response => {
        const eventsByDate = response.data.events.reduce((acc, event) => {
          const eventDate = new Date(event.date).toISOString().split('T')[0]; // Use ISO date format
          if (!acc[eventDate]) {
            acc[eventDate] = [];
          }
          acc[eventDate].push(event);
          return acc;
        }, {});
        setEvents(eventsByDate);
      })
      .catch(error => {
        console.error("Error fetching events:", error);
      });
    }
  }, [currentDate, userId, backendUrl]);

  useEffect(() => {
    const now = new Date();
    setCurrentDate(new Date(now.getFullYear(), now.getMonth(), 1));
  }, [initialYear, initialMonth]);

  const goToPrevious = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
  };

  const goToNext = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
  };

  const handleDateClick = (day, index) => {
    const dateStr = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).toISOString().split('T')[0];
    if (selectedDates.includes(dateStr)) {
      setSelectedDates(selectedDates.filter(date => date !== dateStr));
      setSelectedDate(null);
      setExpandedIndex(null);
    } else {
      setSelectedDates([dateStr]);
      setSelectedDate(dateStr);
      setExpandedIndex(index);
    }
  };

  const renderMonthHeader = () => (
    <div className='d-flex align-items-center calender_header'>
      <div>
        <div className='btn-group me-2' role='group' aria-label='Basic example'>
          <button className='btn btn-primary' onClick={goToPrevious}>
            <i className='fa fa-angle-left' aria-hidden='true'></i>
          </button>
          <button className='btn btn-primary' onClick={goToNext}>
            <i className='fa fa-angle-right' aria-hidden='true'></i>
          </button>
        </div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <h2>
          {currentDate.toLocaleString("default", { month: "long" })} {currentDate.getFullYear()}
        </h2>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <button className='btn btn-primary' onClick={() => {
          const now = new Date();
          setCurrentDate(new Date(now.getFullYear(), now.getMonth(), 1));
        }}>
          This Month
        </button>
      </div>
    </div>
  );

  const renderMonthView = () => {
    const numDaysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const startingDay = firstDayOfMonth.getDay();
    const numRows = Math.ceil((startingDay + numDaysInMonth) / 7);
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0]; // Use ISO date format

    return (
      <>
        <table className='table table-border mt-3 bg-white text-center month_calender'>
          <thead>
            <tr>
              <th>Sun</th>
              <th>Mon</th>
              <th>Tues</th>
              <th>Wed</th>
              <th>Thu</th>
              <th>Fri</th>
              <th>Sat</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(numRows)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(7)].map((_, colIndex) => {
                  const index = rowIndex * 7 + colIndex;
                  const day = index - startingDay + 1;
                  const isCurrentMonthDay = day > 0 && day <= numDaysInMonth;
                  const isToday = isCurrentMonthDay && new Date(currentDate.getFullYear(), currentDate.getMonth(), day).toISOString().split('T')[0] === todayStr;
                  const isSelected = selectedDates.includes(new Date(currentDate.getFullYear(), currentDate.getMonth(), day).toISOString().split('T')[0]);
                  const isExpanded = expandedIndex === index;

                  const dateStr = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).toISOString().split('T')[0];
                  const hasEvents = events[dateStr] && events[dateStr].length > 0;
                  const eventCount = hasEvents ? events[dateStr].length : 0;

                  return (
                    <td
                      key={colIndex}
                      onClick={() => isCurrentMonthDay && handleDateClick(day, index)}
                      className={`p-0 cal-space ${hasEvents ? 'event-day' : ''}`}
                    >
                      {isCurrentMonthDay && (
                        <>
                          <div className="text-end px-3 py-1">
                            <span className={isToday ? 'highlight-today' : ''} style={{fontSize:"16px"}}>{day}</span>
                          </div>
                          {hasEvents && (
                            <div className='event-count'>
                              {events[dateStr].slice(0, 2).map((event, i) => (
                                <Link
                                  to={`/customer-events/${event.id}`} // Replace with your actual path
                                  style={{ fontSize: "12px" }}
                                  className="text-white"
                                  key={event.id}
                                >
                                  <div className="cal-events-name cal-event-title">
                                    {truncateText(event.name, 20)} {/* Adjust maxLength as needed */}
                                  </div>
                                </Link>
                              ))}
                              {eventCount > 2 && (
                                <Link
                                  to={`/day-calendar/${dateStr}`}
                                  style={{ fontSize: "12px" }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div className="text-start">
                                    <i className='fa fa-calendar' aria-hidden='true'></i>{" "}
                                    Show All Events
                                  </div>
                                </Link>
                              )}
                            </div>
                          )}
                          {isSelected && isExpanded && (
                            <div className='box-cell-grid'>
                              <h5 style={{ fontSize: "12px" }}>
                                {day}
                                <span style={{ float: "right" }}>
                                  <i className='fa fa-times' aria-hidden='true'></i>
                                </span>
                              </h5>
                              <Link
                                to={`/day-calendar/${dateStr}`}
                                style={{ fontSize: "12px" }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <i className='fa fa-calendar' aria-hidden='true'></i>{" "}
                                View Day
                              </Link>
                              <Link
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                  openModal();
                                  setCurDate(dateStr);
                                }}
                              >
                                <i className='fa fa-briefcase' aria-hidden='true'></i>{" "}
                                Create Event
                              </Link>
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <NewEventModal isOpen={isModalOpen} onClose={closeModal} curDate={curDate} />
      </>
    );
  };

  return (
    <div>
      {renderMonthHeader()}
      {renderMonthView()}
    </div>
  );
};

export default CalendarGrid;
