import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const fetchProducts = async () => {
  try {
    const response = await axios.get(`${backendUrl}/api/products`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const deleteProduct = async (productId) => {
    try {
        await axios.delete(`${backendUrl}/api/products/${productId}`);
    } catch (error) {
        throw new Error(`Failed to delete product with ID ${productId}: ${error.message}`);
    }
};
export { fetchProducts };
