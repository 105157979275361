import { Link } from "react-router-dom";
import PreviewTabBtn from "./PreviewTabBtn";
import {useState,useEffect} from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom'; 
export default function PreviewImages() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [GalleryData, SetGalleryData] = useState([]);
  const [GalleryTypeOptions, setGalleryTypeOptions] = useState([]); 
  const [currentCategory, setCurrentCategory] = useState(0); 
  const [created_by, setCreatedBy] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
      // Check if user data exists in localStorage
      const udata = localStorage.getItem('user');
      if (!udata) {
          // Redirect to login page if user data does not exist
          navigate('/sign-in');
          return;
      }
      const user_data = JSON.parse(udata);
      setCreatedBy(user_data.user.id);
  }, [navigate]);


  useEffect(() => {

    const filterGallery = async () => {
      if(created_by!=null){
      try {
        const response = await axios.post(`${backendUrl}/api/gallery-fetch`, {
          GalleryTypeId:currentCategory,
          searchTerm: "",
          created_by: created_by  // Assuming created_by is defined somewhere
        });
  
        if (response.data.success === true) {
          SetGalleryData(response.data.gallery);
        }
    
      } catch (error) {
        console.error('Error fetching gallery:', error);
      }
    }
    };

  
    filterGallery();
  }, [created_by,currentCategory]); 

  
  const fetchGalleryTypeOptions = async () => {
   if(created_by){
    try {
      const response = await axios.get(`${backendUrl}/api/gallery-category-list/${created_by}`);
      if (response.data.success === true) {
        // Map id to name for display and store id in state
        const options = response.data.gallary_categories.map((option) => ({
          id: option.id,
          name: option.name,
        }));
        setGalleryTypeOptions(options);
      }
    } catch (error) {
      console.error('Error fetching gallery:', error);
    }
   }
  }

  useEffect(() => {
    fetchGalleryTypeOptions();
  }, [created_by]); 

  return (
    <div className='container mt-5 px-5'>
      <div className=' p-lg-5 p-md-3 p-2'>
        <div className='gallery_title'>
          <h2 className='mb-1'>Photo Booth Template Gallery</h2>
          <span>
            Select the Photo Booth template you want use at your event and write
            down the message you want the template <br /> to say. Also let me
            know if you want to use the 4x6 or 2x6 size template design.
          </span>
        </div>

        <div className='row g-lg-5 g-md-5 g-3 justify-content-center'>
          <div className='companysetting-window-container '>
            <div className='container mt-5'>
              <div className='row'>
                {/* =============== Tab Nav Links =============== */}
                <div className='col-md-3 '>
                  <>
                    <PreviewTabBtn GalleryTypeOptions={GalleryTypeOptions} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory}  />
                  </>
                </div>

                <div className='col-md-9 '>
                  <div className='tab-content '>
                    {/* =============== Start: Company Brand =============== */}
                    <div
                      className='tab-pane fade show active company_brand'
                      id='content1'>
                      <div className='row'>
                        {GalleryData.length!=0?(
                          GalleryData.map((galleryItem) => (
                          <div key={galleryItem.id} className="col-md-3">
                            <Link to={`/gallery/image-gallery/${galleryItem.id}`}>
                              <div className="uploaded_box_preview">
                              {galleryItem.gallery_media.length > 0 && (
                                  // Find the 'primary' image directly in JSX
                                  <img
                                    src={`${backendUrl}${galleryItem.gallery_media.find(item => item.type === 'primary').path}`}
                                    alt="Gallery Preview"
                                  />
                                )}
                                <span className="text-content fw-bold d-block">
                                  {galleryItem.title}
                                </span>
                              </div>
                            </Link>
                          </div>
                        )))
                      :(<p className="text-center text-danger my-5">No Gallery Found</p>)
                      }
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* Company tab left end  */}
          </div>
        </div>
      </div>
    </div>
  );
}
