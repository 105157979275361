import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom'; 
export default function ExistingClient({ clientsDetailes, setClientdetailes,onClose }) {
  const [ClientData, setClientData] = useState([]);
  const [curclientsDetailes, setCurClientsDetailes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAddAllowed, setIsAddAllowed] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const [userId, setUserId] = useState(null);

  useEffect(() => {
      // Check if user data exists in localStorage
      const udata = localStorage.getItem('user');
      if (!udata) {
          // Redirect to login page if user data does not exist
          navigate('/sign-in');
          return;
      }
      const user_data = JSON.parse(udata);
      setUserId(user_data.user.id);
  }, [navigate]);
  useEffect(() => {
    if (userId && searchQuery !== "") {
      fetchClientDetails();
    }
  }, [userId, searchQuery]);

  const fetchClientDetails = async () => {
    try {
      const response = await axios.post(`${backendUrl}/api/clients-search-list`, {
        searchQuery: searchQuery,
        userId: userId
      });
      if (response.data.success === true) {
        setClientData(response.data.clients);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  }
 
  const fetchClient = async () => {
    if(userId){
      try {
        const response = await axios.get(`${backendUrl}/api/clients-list/${userId}`);
        if (response.data.success === true) {
          setClientData(response.data.client);
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    }
  }

  const handleClientClick = (client) => {
    if (clientsDetailes && clientsDetailes.id === client.id) {
      setCurClientsDetailes([]);
      setIsAddAllowed(false);
    } else {
      setCurClientsDetailes([client]);
      setIsAddAllowed(true);
    }
  };

  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value); // Update searchQuery state
    if(value==""){
      setClientData([]);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      fetchClientDetails(); // Call fetchClientDetails on Enter key press
    }
  };

  const handleAddClick = () => {
    if (curclientsDetailes.length > 0) {
      const selectedClient = curclientsDetailes[0]; // Assuming you want to add only the first selected client
      setClientdetailes(selectedClient);
      setCurClientsDetailes([]);
      setIsAddAllowed(false);
      onClose();
    } else {
      alert("Please select a client to add.");
    }
  };

  useEffect(() => {
    fetchClient();
  }, [userId]);
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <form className='search-input'>
            <i className='fa fa-search' aria-hidden='true'></i>
            <input
              type='text'
              className='form-control'
              placeholder='Type to search client email/first name/last name'
              value={searchQuery}
              onChange={handleSearchInputChange}
              onKeyUp={handleKeyUp} // Add onKeyUp event handler
            />
          </form>
        </div>
        <div className='col-12 mt-4'>
          <div className='search-result'>
            {ClientData && ClientData.length > 0 ? (
              ClientData.map((userInfo, i) => {
                const isSelected = curclientsDetailes.length > 0 && curclientsDetailes[0].id === userInfo.id;
                const highlightClass = isSelected ? 'client_selected' : '';
                const user_avatar_name = userInfo.first_name + userInfo.last_name;
                const displayName = user_avatar_name ? user_avatar_name.substring(0, 2) : 'Unknown';

                return (
                  <div
                    className={`single_user d-flex align-items-center ${highlightClass}`}
                    key={i}
                    onClick={() => handleClientClick(userInfo)}
                  >
                    <div className='avatar'>
                      {userInfo.avatar ? (
                        <img src={userInfo.avatar} alt='Avatar' />
                      ) : (
                        <span>{displayName}</span>
                      )}
                    </div>
                    <div className='info'>
                      <h6>{userInfo.name}</h6>
                      <span>{userInfo.email}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-results">No clients found</div>
            )}
          </div>
        </div>
        <div className='col-md-12 mt-3 p-4 text-end'>
          <button
            type='button'
            className={`but-primary ${isAddAllowed ? '' : 'disabled'}`}
            data-bs-dismiss={curclientsDetailes.length > 0 ? 'modal' : ''}
            onClick={handleAddClick}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
