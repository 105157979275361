import { useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
export default function EditEmailSignatureModal() {
  const editorRef = useRef(null);
  // Data for the default content of the SunEditor
  const data = `<p className='m-0 fs-6'>john jones </p>
          <p className='m-0 fs-6'>Rentals / Photo booths </p>
          <p className='m-0 fs-6'>John Photo Booth Company </p>
          <p className='m-0 fs-6'> (972) 123-55555 </p>`;
  // Options for the SunEditor instance
  const editorOptionsShow = {
    height: 200,
    fontSize: ["12", "14", "16", "18", "20"],

    buttonList: [
      [
        "codeView",
        "paragraphStyle",
        "bold",
        "underline",
        "italic",
        "strike",
        "table",
        "link",
        "horizontalRule",
        "list",
        "align",
        "hiliteColor",
        "image",
        "video",
      ],
    ],
    imageRotation: false,
    colorList: [
      [
        "#828282",
        "#FF5400",
        "#676464",
        "#F1F2F4",
        "#FF9B00",
        "#F00",
        "#fa6e30",
        "#000",
        "rgba(255, 153, 0, 0.1)",
        "#FF6600",
        "#0099FF",
        "#74CC6D",
        "#FF9900",
        "#CCCCCC",
      ],
    ],
  };
  return (
    <div
      className='modal fade m-0 p-0'
      id='exampleModal2'
      tabindex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'>
      <div className='modal-dialog newEventsModal_dialog modal-dialog-centered'>
        {/* New Event Modal */}
        <div className={`modal-content event_modal`}>
          <div className='modal-header'>
            <h5
              className='modal-title border-0 text-center w-100'
              id='exampleModalLabel'
              style={{ fontSize: "22px" }}>
              Edit Default Signature
            </h5>
          </div>
          <div className='modal-body px-2 px-md-5 '>
            <div class='email-template-container'>
              <SunEditor
                setOptions={editorOptionsShow}
                ref={editorRef}
                defaultValue={data}
                placeholder='You can edit your message...'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
