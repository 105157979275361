import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
export default class MyAccountTab extends Component {
    constructor(props) {
        super(props);
        const udata = localStorage.getItem('user');
        const odata = JSON.parse(udata);
        let loggedIN = true;
        if (udata == null) {
            loggedIN = false;
        }
        this.state = {
            user: odata.user,
            loggedIN,
            full_name: '',
            job_title: '',
            phone_num: '',
            email_id: '',
            paddress: '',
            loading: true,
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            errorMessages: {},
            formErrors: [],
            isFormModified: false,
            showModal: false, // State to manage modal visibility
        };

        this.onChangeFullName = this.onChangeFullName.bind(this);
        this.onChangeJobtitle = this.onChangeJobtitle.bind(this);
        this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
        this.onChangeEmailId = this.onChangeEmailId.bind(this);
        this.onChangePaddress = this.onChangePaddress.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.onChangeCurrentPassword = this.onChangeCurrentPassword.bind(this);
        this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
        this.onChangeConfirmNewPassword = this.onChangeConfirmNewPassword.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.fetchUserDetails();
    }

    fetchUserDetails() {
        const udata = localStorage.getItem('user');
        const jsonObj = JSON.parse(udata);
        const uid = jsonObj['user']['id'];
        const userid = { usersid: uid };

        axios.post(`${backendUrl}/api/personal_setting/fetchdeatils`, userid)
            .then((res) => {
                if (res.data.message === "fetch data success") {
                    const profiledata = res.data.usersdetails;
                    if (profiledata.length > 0) {
                        const pdata = profiledata[0];
                        this.setState({
                            full_name: pdata["full_name"],
                            job_title: pdata["job_title"],
                            phone_num: pdata["phone_num"],
                            email_id: pdata["email_id"],
                            paddress: pdata["paddress"],
                            loading: false,
                        });
                    }
                }
            }).catch((error) => {
                alert(error);
                alert("fetch data  failed.");
                this.setState({ loading: false });
            });
    }

    handleInputChange(field, value) {
        this.setState({ [field]: value, isFormModified: true });
    }

    onChangeFullName(e) {
        this.handleInputChange('full_name', e.target.value);
    }

    onChangeJobtitle(e) {
        this.handleInputChange('job_title', e.target.value);
    }

    onChangePhoneNumber(e) {
        this.handleInputChange('phone_num', e.target.value);
    }

    onChangeEmailId(e) {
        this.handleInputChange('email_id', e.target.value);
    }

    onChangePaddress(e) {
        this.handleInputChange('paddress', e.target.value);
    }

    onChangeCurrentPassword(e) {
        this.setState({ currentPassword: e.target.value });
    }

    onChangeNewPassword(e) {
        this.setState({ newPassword: e.target.value });
    }

    onChangeConfirmNewPassword(e) {
        this.setState({ confirmNewPassword: e.target.value });
    }

    onChangePassword(e) {
        e.preventDefault();

        const udata = localStorage.getItem('user');
        const jsonObj = JSON.parse(udata);
        const uid = jsonObj['user']['id'];

        const { currentPassword, newPassword, confirmNewPassword } = this.state;

        if (newPassword !== confirmNewPassword) {
            alert('New password and confirm new password must match');
            return;
        }

        axios.post(`${backendUrl}/api/personal_setting/change-password`, {
            current_password: currentPassword,
            new_password: newPassword,
            new_password_confirmation: confirmNewPassword,
            fk_customer_id: uid
        })
            .then((res) => {
                console.log(res, "success post");
                if (res.data.message === "Password changed successfully") {
                    alert("Password changed successfully");
                    this.setState({
                        currentPassword: '',
                        newPassword: '',
                        confirmNewPassword: ''
                    });
                }
            })
            .catch((error) => {
                alert("Failed to change password.");
            });
    }

    onSubmit(e) {
        e.preventDefault();
        const udata = localStorage.getItem('user');
        const jsonObj = JSON.parse(udata);
        const uid = jsonObj['user']['id'];

        const userObject = {
            full_name: this.state.full_name,
            job_title: this.state.job_title,
            phone_num: this.state.phone_num,
            email_id: this.state.email_id,
            paddress: this.state.paddress,
            fk_customer_id: uid,
        };

        axios.post(`${backendUrl}/api/personal_setting/adddetails`, userObject)
            .then((res) => {
                console.log(res, "success post");
                if (res.data.message === "Users info successfully update") {
                    this.setState({ formErrors: [], showModal: true });
                    this.fetchUserDetails();
                }
            }).catch((error) => {
                const errorResponse = JSON.parse(error.response.data);
                const formErrors = Object.keys(errorResponse).map(key => `${key}: ${errorResponse[key].join(' ')}`);
                this.setState({ formErrors });
            });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { errorMessages, formErrors, showModal } = this.state;

        if (this.state.loggedIN === false) {
            return <Navigate to="/sign-in" />
        }

        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            );
        }

        return (
            <div className='tab-pane margin-top-80 fade show active' id='MyAccount_Info'>
                <div className='container g-lg-5 g-md-5 g-3 justify-content-center'>
                    <div className='company-setting-box rounded p-lg-3 p-md-2 p-2 my-4 bg-white'>
                        {formErrors.length > 0 && (
                            <div className="alert alert-danger">
                                {formErrors.map((error, index) => <p key={index}>{error}</p>)}
                            </div>
                        )}
                        <form onSubmit={this.onSubmit}>
                            <div className='row'>
                                <div className='mb-3 col-md-6'>
                                    <label htmlFor='fullName' className='form-label'>
                                        Full Name
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='full_name'
                                        onChange={this.onChangeFullName}
                                        name="full_name"
                                        value={this.state.full_name}
                                        required
                                        placeholder='Full Name'
                                    />
                                </div>
                                <div className='mb-3 col-md-6 '>
                                    <label htmlFor='jobTitle' className='form-label'>
                                        Job Title
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        onChange={this.onChangeJobtitle}
                                        name="job_title"
                                        id="job_title"
                                        value={this.state.job_title}
                                        required
                                        placeholder='Job Title'
                                    />
                                </div>

                                <div className='mb-3 col-md-6 '>
                                    <label htmlFor='description' className='form-label'>
                                        Email Address{" "}
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        onChange={this.onChangeEmailId}
                                        name="email_id"
                                        id="email_id"
                                        value={this.state.email_id}
                                        required
                                        placeholder='Email Address'
                                    />
                                </div>

                                <div className='mb-3 col-md-6 '>
                                    <label htmlFor='description' className='form-label'>
                                        Phone Number{" "}
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        onChange={this.onChangePhoneNumber}
                                        name="phone_num"
                                        id="phone_num"
                                        value={this.state.phone_num}
                                        required
                                        placeholder='Phone Number'
                                    />
                                </div>

                                <div className='mb-3 col-md-6 '>
                                    <label htmlFor='description' className='form-label'>
                                        Personal Address{" "}
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        onChange={this.onChangePaddress}
                                        name="paddress"
                                        id="paddress"
                                        value={this.state.paddress}
                                        required
                                        placeholder='Personal Address'
                                    />
                                </div>
                                <div className='mb-3 col-md-2 justify-content-center '>
                                    <button
                                        id='submit-btn'
                                        type='submit'
                                        className='px-5 mt-4 rounded-pill btn btn-primary btn-lg text-uppercase'
                                        disabled={!this.state.isFormModified} // Disable if form is not modified
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='company-setting-box rounded p-lg-3 p-md-2 p-2 my-4 bg-white'>
                        <h5>Change Password</h5>
                        <form onSubmit={this.onChangePassword}>
                            <div className='row my-3'>
                                <div className='mb-3 col-md-6'>
                                    <label htmlFor='currentPassword' className='form-label'>
                                        Current Password
                                    </label>
                                    <input
                                        type='password'
                                        className='form-control'
                                        id='currentPassword'
                                        value={this.state.currentPassword}
                                        onChange={this.onChangeCurrentPassword}
                                        required
                                    />
                                </div>
                                {errorMessages.current_password && (
                                    <div className='text-danger'>{errorMessages.current_password[0]}</div>
                                )}

                                <div className='mb-3 col-md-6'>
                                    <label htmlFor='newPassword' className='form-label'>
                                        New Password
                                    </label>
                                    <input
                                        type='password'
                                        className='form-control'
                                        id='newPassword'
                                        value={this.state.newPassword}
                                        onChange={this.onChangeNewPassword}
                                        required
                                    />
                                </div>
                                {errorMessages.new_password && (
                                    <div className='text-danger'>{errorMessages.new_password[0]}</div>
                                )}

                                <div className='mb-3 col-md-6'>
                                    <label htmlFor='confirmNewPassword' className='form-label'>
                                        Confirm New Password
                                    </label>
                                    <input
                                        type='password'
                                        className='form-control'
                                        id='confirmNewPassword'
                                        value={this.state.confirmNewPassword}
                                        onChange={this.onChangeConfirmNewPassword}
                                        required
                                    />
                                </div>
                                {errorMessages.confirm_new_password && (
                                    <div className='text-danger'>{errorMessages.confirm_new_password[0]}</div>
                                )}

                                <div className='mb-3 col-md-6 justify-content-center'>
                                    <button
                                        type='submit'
                                        className='px-5 mt-4 rounded-pill btn btn-primary btn-lg text-uppercase'>
                                        Change Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Modal */}
                <div className={`modal fade ${showModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Success</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Your info has been saved</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.closeModal()}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
