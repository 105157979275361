import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const fetchEmails = async () => {
  try {
    const response = await axios.get(`${backendUrl}/email-templates`);
    return response.data;
  } catch (error) {
    console.error('Error fetching emails:', error);
    throw error;
  }
};

export const deleteEmail = async (emailId) => {
    try {
        await axios.delete(`${backendUrl}/email-templates/${emailId}`);
    } catch (error) {
        throw new Error(`Failed to delete email with ID ${emailId}: ${error.message}`);
    }
};
export { fetchEmails };
