import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';  
function EventOverview({ onUpdate }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [EventData, setEventData] = useState({});
  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [eventType, setEventType] = useState("");
  const [eventTypeId, setEventTypeId] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [leadSourceId, setLeadSourceId] = useState("");
  const [eventTypeOptionsOpen, setEventTypeOptionsOpen] = useState(false);
  const [leadSourceOptionsOpen, setLeadSourceOptionsOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [newLeadSource, setNewLeadSource] = useState("");
  const [showAddCategoryInput, setShowAddCategoryInput] = useState(false);
  const [showAddLeadSourceInput, setShowAddLeadSourceInput] = useState(false);
  const [attendantColumns, setAttendantColumns] = useState([{ showAddButton: false, items: [] }]);
  const [reminderColumns, setReminderColumns] = useState([{ showAddButton: false, items: [] }]);
  const { eventid } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  useEffect(() => {
      // Check if user data exists in localStorage
      const udata = localStorage.getItem('user');
      if (!udata) {
          // Redirect to login page if user data does not exist
          navigate('/sign-in');
          return;
      }
      const user_data = JSON.parse(udata);
      setUserId(user_data.user.id);
  }, [navigate]);

  const fetchEventDetails = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/event/${eventid}`);
      if (response.data.success === true) {
        setEventData(response.data.events);
        setEventType(response.data.events.get_event_category.name); // Set event type name
        setEventTypeId(response.data.events.get_event_category.id); // Set event type ID if needed
        setLeadSource(response.data.events.get_lead_source.name); // Set lead source name
        setLeadSourceId(response.data.events.get_lead_source.id); // Set lead source ID if needed
      }
    } catch (error) {
      console.error('Error fetching event details:', error);
    }
  };

  const fetchEventTypeOptions = async () => {
    if(userId){
      try {
        const response = await axios.get(`${backendUrl}/api/events-category-list/${userId}`);
        if (response.data.success === true) {
          const options = response.data.event_category.map((option) => ({
            id: option.id,
            name: option.name,
          }));
          setEventTypeOptions(options);
        }
      } catch (error) {
        console.error('Error fetching event types:', error);
      }
    }
  };

  const fetchLeadSourceOptions = async () => {
    if(userId){
    try {
      const response = await axios.get(`${backendUrl}/api/lead-source-list/${userId}`);
      if (response.data.success === true) {
        const options = response.data.lead_source_data.map((option) => ({
          id: option.id,
          name: option.name,
        }));
        setLeadSourceOptions(options);
      }
    } catch (error) {
      console.error('Error fetching lead sources:', error);
    }
   }
  };

  const handleCreateCategory = () => {
    if (newCategory.trim() !== "") {
      axios.post(`${backendUrl}/api/events-category-add`, { name: newCategory, created_by: userId })
        .then(response => {
          fetchEventTypeOptions();
          setEventType(response.data.name); // Set event type name
          setEventTypeId(response.data.id); // Set event type ID if needed
          setNewCategory("");
          setShowAddCategoryInput(false);
        })
        .catch(error => {
          console.error("Error creating category:", error);
        });
    }
  };

  const handleCreateLeadSource = () => {
    if (newLeadSource.trim() !== "") {
      axios.post(`${backendUrl}/api/lead-source-add`, { name: newLeadSource, created_by: userId })
        .then(response => {
          fetchLeadSourceOptions();
          setLeadSource(response.data.name); // Set lead source name
          setLeadSourceId(response.data.id); // Set lead source ID if needed
          setNewLeadSource("");
          setShowAddLeadSourceInput(false);
        })
        .catch(error => {
          console.error("Error creating lead source:", error);
        });
    }
  };

  const toggleAddCategoryInput = () => {
    setShowAddCategoryInput(!showAddCategoryInput);
  };

  const toggleAddLeadSourceInput = () => {
    setShowAddLeadSourceInput(!showAddLeadSourceInput);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Validate required fields
    const firstName = event.target.elements.firstName.value.trim();
    const lastName = event.target.elements.lastName.value.trim();
    const email = event.target.elements.email.value.trim();
    const phoneNumber = event.target.elements.phoneNumber.value.trim();
    const eventName = event.target.elements.eventName.value.trim();
    const eventAddress = event.target.elements.eventAddress.value.trim();
    const eventDate = event.target.elements.eventDate.value.trim();
    const startTime = event.target.elements.startTime.value.trim();
    const endTime = event.target.elements.endTime.value.trim();
  
    // Validate start time and end time
    const startTimeObj = new Date(`2000-01-01 ${startTime}`);
    const endTimeObj = new Date(`2000-01-01 ${endTime}`);

    if (startTimeObj >= endTimeObj) {
      window.alert("End time must be after start time.");
      return;
    }
  
     // Validate event date
     const eventDateObj = new Date(eventDate);
     const currentDate = new Date();
   
     if (eventDateObj < currentDate.setHours(0, 0, 0, 0)) {
       window.alert("Event date cannot be a past date.");
       return;
     }

    // All required fields are filled, proceed to submit the form
    const formData = {
      created_by: userId,
      id: eventid,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      eventName: eventName,
      eventAddress: eventAddress,
      eventDate: eventDate,
      startTime: startTime,
      endTime: endTime,
      eventTypeId: eventTypeId,
      leadSourceId: leadSourceId,
    };

    if (
      firstName == "" ||
      lastName == "" ||
      email == "" ||
      phoneNumber == "" ||
      eventName == "" ||
      eventAddress == "" ||
      eventDate == "" ||
      startTime == "" ||
      endTime == ""||
      eventTypeId == ""||
      leadSourceId == ""||
      eventTypeId == undefined||
      leadSourceId == undefined
    ) {
      window.alert("Please fill in all required fields.");
      return;
    }

    // Example axios post request to save data
    axios.post(`${backendUrl}/api/event-update`, formData)
      .then(response => {
          if(response.data.success==true){
            alert("Event updated successfully");
            if (onUpdate) onUpdate(); 
          }
      })
      .catch(error => {
        console.error('Error saving data:', error);
        // Add error handling here
      });
  };
  

  useEffect(() => {
    fetchEventDetails();
    fetchEventTypeOptions();
    fetchLeadSourceOptions();
  }, [userId]);

  return (
    <>
      <div className='container-fluid py-5 px-2 bg-white'>
        <div className='row'>
          <form onSubmit={handleSubmit}>
            <div className='col-lg-10 col-md-10 col-12 mx-auto'>
              <h4>Customer Info</h4>
              <div className="row mt-4">
                <div className="col-12">
                  <div id="v-pills-home" className="tab-pane show active">
                    <div className="row">
                      <div className="col-md-6">
                        <label>First Name</label>
                        <input type="text" name="firstName" className="form-control" placeholder="First name" defaultValue={EventData.cl_firstname} />
                      </div>
                      <div className="col-md-6">
                        <label>Last Name</label>
                        <input type="text" name="lastName" className="form-control" placeholder="Last name" defaultValue={EventData.cl_lastname} />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Email</label>
                        <input type="email" name="email" className="form-control" placeholder="Email Address" defaultValue={EventData.cl_email} />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>Phone Number</label>
                        <input type="text" name="phoneNumber" className="form-control" placeholder="Phone Number" defaultValue={EventData.cl_phone_num} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-10 col-md-10 col-12 mx-auto'>
              <h4 className='mt-4'>Event Info</h4>
              <div className='row'>
                <div className='col-md-12'>
                  <label htmlFor='eventName'>Event Name</label>
                  <input name='eventName' id='eventName' type='text' className='form-control' placeholder='Event Name' defaultValue={EventData.name} required />
                </div>
                <div className='col-md-12 mt-4'>
                  <label htmlFor='eventAddress'>Event Address</label>
                  <input name='eventAddress' id='eventAddress' type='text' className='form-control' placeholder='Event Address' defaultValue={EventData.address} required />
                </div>
                <div className='col-md-6 mt-4'>
                  <label>Event Type</label>
                  <div className='accordion position-relative'>
                    <div className={`accordion-header w-100 ${eventTypeOptionsOpen ? "active" : ""}`} onClick={() => setEventTypeOptionsOpen(!eventTypeOptionsOpen)}>
                      <span className='selected-option'>{eventType || "Select Event Type"}</span>
                      <span className='icon overflow-hidden'>
                        <i className={eventTypeOptionsOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} />
                      </span>
                    </div>
                    <div className={`accordion-content ${eventTypeOptions.length>4 ? "category_scroll" : ""} ${eventTypeOptionsOpen ? "active" : ""}`}>
                      {eventTypeOptions.map(option => (
                        <div key={option.id} className='option' onClick={() => { setEventTypeId(option.id); setEventType(option.name); setEventTypeOptionsOpen(false); }}>
                          {option.name}
                        </div>
                      ))}
                      {showAddCategoryInput ? (
                        <div className='input-with-button d-flex align-items-center'>
                          <input
                            type='text'
                            placeholder=' New Category..'
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                          />
                          <button type="button" className='add_item_btn' onClick={handleCreateCategory}>
                            Create
                          </button>
                        </div>
                      ) : (
                        <button type="button" id="button" className='add_category_btn' onClick={toggleAddCategoryInput}>
                          + Add Category
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-md-6 mt-4'>
                  <label>Lead Source</label>
                  <div className='accordion position-relative'>
                    <div className={`accordion-header w-100 ${leadSourceOptionsOpen ? "active" : ""}`} onClick={() => setLeadSourceOptionsOpen(!leadSourceOptionsOpen)}>
                      <span className='selected-option'>{leadSource || "Select Lead Source"}</span>
                      <span className='icon overflow-hidden'>
                        <i className={leadSourceOptionsOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} />
                      </span>
                    </div>
                    <div className={`accordion-content ${leadSourceOptions.length>4 ? "category_scroll" : ""} ${leadSourceOptionsOpen ? "active" : ""}`}>
                      {leadSourceOptions.map(option => (
                        <div key={option.id} className='option' onClick={() => { setLeadSourceId(option.id); setLeadSource(option.name); setLeadSourceOptionsOpen(false); }}>
                          {option.name}
                        </div>
                      ))}
                      {showAddLeadSourceInput ? (
                        <div className='input-with-button d-flex align-items-center'>
                          <input
                            type='text'
                            placeholder=' New Lead Source..'
                            value={newLeadSource}
                            onChange={(e) => setNewLeadSource(e.target.value)}
                          />
                          <button type="button" className='add_item_btn' onClick={handleCreateLeadSource}>
                            Create
                          </button>
                        </div>
                      ) : (
                        <button type="button" id="button" className='add_category_btn' onClick={toggleAddLeadSourceInput}>
                          + Add Lead Source
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-md-12 mt-4'>
                  <label htmlFor='eventDate'>Event Date</label>
                  <input
                    name='eventDate'
                    id='eventDate'
                    type='date'
                    className='form-control'
                    placeholder='Event Date'
                    defaultValue={EventData.date}
                    required
                  />
                </div>
                <div className='col-md-6 mt-4'>
                  <label htmlFor='startTime'>Start Time</label>
                  <input name='startTime' id='startTime' type='time' className='form-control' placeholder='Start Time' defaultValue={EventData.from_time} required />
                </div>
                <div className='col-md-6 mt-4'>
                  <label htmlFor='endTime'>End Time</label>
                  <input name='endTime' id='endTime' type='time' className='form-control' placeholder='End Time' defaultValue={EventData.to_time} required />
                </div>
              </div>
            </div>
            <div className='col-lg-10 col-md-10 col-12 my-2 mx-auto text-md-end'>
              <button type='submit' className='btn btn-primary save-btn'>Save</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export { EventOverview };
