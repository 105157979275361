// Importing necessary modules
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux"; // Import Provider from react-redux
import { EditableContentProvider } from "./Context/EditableContentContext.js";
import { router } from "./Route/Route.js";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store.js"; // Import the Redux store

// Connecting to the root element in the index.html file
const root = ReactDOM.createRoot(document.getElementById("root"));

// Pushing the Dashboard component to the root element which is the "ID Root" div in the index.html file
root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Wrap with Provider and pass the store */}
      <EditableContentProvider>
        {/* ====================================== Developer comment this code. */}
        <RouterProvider router={router} />
        {/* ========================== Created a Route.js and import it here. */}
      </EditableContentProvider>
    </Provider>
  </React.StrictMode>
);

// Calling the function to measure and report web performance metrics
reportWebVitals();
