import React from "react";

export default function DashboardHome() {
  return (
    <>
      <div className='col-sm-12 col-md-12 col-xl-5 box-wrap-main'>
        <div className='h-100 bg-white rounded p-4 shadow box-wrap' id=''>
          <div className='d-flex align-items-center justify-content-between mb-4'>
            <h6 className='mb-0'>To Do List - DONE - Jan 22 @ 7:16PM</h6>
          </div>
          <div className='d-flex mb-2 px-lg-3 px-0'>
            <input
              className='form-control bg-transparent'
              type='text'
              placeholder='What Need to be done?'
            />
          </div>
          <div className='d-flex align-items-center py-2 px-lg-3 px-0'>
            <input className='form-check-input m-0' type='checkbox' />
            <div className='w-100 ms-3'>
              <div className='d-flex w-100 align-items-center justify-content-between'>
                <span>View tutorials page on how to setup this software?</span>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center py-2 px-lg-3 px-0'>
            <input className='form-check-input m-0' type='checkbox' />
            <div className='w-100 ms-3'>
              <div className='d-flex w-100 align-items-center justify-content-between'>
                <span>Lorem Ipsum is simply dummy text of the printing</span>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center py-2 px-lg-3 px-0'>
            <input className='form-check-input m-0' type='checkbox' />
            <div className='w-100 ms-3'>
              <div className='d-flex w-100 align-items-center justify-content-between'>
                <span>Lorem Ipsum is simply dummy text of the printing</span>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center py-2 px-lg-3 px-0'>
            <input className='form-check-input m-0' type='checkbox' />
            <div className='w-100 ms-3'>
              <div className='d-flex w-100 align-items-center justify-content-between'>
                <span>Lorem Ipsum is simply dummy text of the printing</span>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center py-2 px-lg-3 px-0'>
            <input className='form-check-input m-0' type='checkbox' />
            <div className='w-100 ms-3'>
              <div className='d-flex w-100 align-items-center justify-content-between'>
                <span>Lorem Ipsum is simply dummy text of the printing</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-12 col-xl-5'>
        <div className='h-100 bg-white rounded p-4 shadow box-wrap' id=''>
          <div className='d-flex align-items-center justify-content-between mb-4'>
            <h6 className='mb-0'>Upcoming Events</h6>
          </div>
          <table className='table table-borderless'>
            <thead>
              <tr>
                <th scope='col'>Event Date:</th>
                <th scope='col'>Event Name:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>10/10/23</td>
                <td>
                  <u>Priscilla Wedding Coprate event</u>
                </td>
              </tr>
              <tr>
                <td>10/10/23</td>
                <td>
                  <u>Priscilla Wedding Coprate event</u>
                </td>
              </tr>
              <tr>
                <td>10/10/23</td>
                <td>
                  <u>Priscilla Wedding Coprate event</u>
                </td>
              </tr>
              <tr>
                <td>10/10/23</td>
                <td>
                  <u>Priscilla Wedding Coprate event</u>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
