import { useState } from "react";
import { Link } from "react-router-dom";
import { useEditableContent } from "../../Context/EditableContentContext";

// ContactHeader component for displaying a header with navigation and buttons
const ProductHeader = ({ toggleSidebar }) => {
  const { editableProductContent, handleProductContentChange } =
    useEditableContent();
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(editableProductContent);

  /* const handleInputChange = (e) => {
    setNewContent(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleProductContentChange(newContent);
      setIsEditing(false);
    }
  };

  const handleClick = () => {
    setIsEditing(true);
    handleProductContentChange(newContent);
  };

  const handleBlur = () => {
    handleProductContentChange(newContent);
    setIsEditing(false);
  }; */

  return (
    <nav className='navbar navbar-expand nav_mobile flex-column flex-md-row bottom-header-main  px-lg-5 px-2 py-2'>
      <div className=' d-flex align-items-center d-md-flex ms-md-2'>
        <div className='d-flex align-items-center bottom-header'>
          {/* ====== Hamburger Button to Toggle The side Bar =========== */}
          <button
            className='sidebar-toggler flex-shrink-0 bg-transparent border-0 me-3'
            onClick={toggleSidebar}>
            <i className='fa fa-bars' />
          </button>

          <div>
            {/* Title */}

            {/*   <div onClick={handleClick}>
              {isEditing ? (
                <input
                  type='text'
                  className='border-0 outline-none'
                  value={newContent}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  onBlur={handleBlur}
                  autoFocus // Automatically focuses on the input field when it appears
                />
              ) : (
                <h5 className='untitled-product-title'>
                  {editableProductContent}
                </h5>
              )}
            </div> */}

            {/* Folder icon and preview text */}
            <Link to='/templates'>
              <p className='d-flex align-items-center'>
                <i className='far fa-folder'></i>
                <span className='preview-text'>Templates</span>
              </p>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default ProductHeader;
