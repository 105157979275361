// Import Routing Components For Linking to Web pages

import { Link } from "react-router-dom";

function Templates() {
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    <>
      <div className='container-fluid p-lg-5 p-md-5 p-3'>
        <div className='row g-lg-0 g-md-0 g-0'>
          <div className='col-sm-12 col-md-12 col-xl-12'>
            <div className='mb-0'>
              <h3 className='mb-1'>Templates</h3>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-xl-4'>
            <div className='h-100 p-2'>
              <Link to='/products' id='' className='d-block px-2 py-2 p-urls2'>
                <div className='mb-4 text-center'>
                  <img className='w-50' src='img/product.png' alt='PRODUCT' />
                  <h6
                    className='mb-0'
                    style={{ fontSize: "24px", fontWeight: 500 }}>
                    Products
                  </h6>
                </div>
              </Link>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-xl-4'>
            <div className='h-100 p-2'>
              <Link to='/packages' id='' className='d-block px-2 py-2 p-urls2'>
                <div className='mb-4 text-center'>
                  <img className='w-50' src='img/packages.png' alt='packages' />
                  <h6
                    className='mb-0'
                    style={{ fontSize: "24px", fontWeight: 500 }}>
                    Packages
                  </h6>
                </div>
              </Link>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-xl-4'>
            <div className='h-100 p-2'>
              <Link to='/contracts' id='' className='d-block px-2 py-2 p-urls2'>
                <div className='mb-4 text-center'>
                  <img
                    className='w-50'
                    src='img/contaracts.png'
                    alt='contaracts'
                  />
                  <h6
                    className='mb-0'
                    style={{ fontSize: "24px", fontWeight: 500 }}>
                    Contracts
                  </h6>
                </div>
              </Link>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-xl-4'>
            <div className='h-100 p-2'>
              <Link to='/invoices' id='' className='d-block px-2 py-2 p-urls2'>
                <div className='mb-4 text-center'>
                  <img className='w-50' src='img/invoice.png' alt='invoice' />
                  <h6
                    className='mb-0'
                    style={{ fontSize: "24px", fontWeight: 500 }}>
                    Invoices
                  </h6>
                </div>
              </Link>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-xl-4'>
            <div className='h-100 p-2'>
              <Link to='/proposals' id='' className='d-block px-2 py-2 p-urls2'>
                <div className='mb-4 text-center'>
                  <img className='w-50' src='img/proposel.png' alt='proposel' />
                  <h6
                    className='mb-0'
                    style={{ fontSize: "24px", fontWeight: 500 }}>
                    Proposals
                  </h6>
                </div>
              </Link>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-xl-4'>
            <div className='h-100 p-2'>
              <Link
                to='/contract-form'
                id=''
                className='d-block px-2 py-2 p-urls2'>
                <div className='mb-4 text-center'>
                  <img
                    className='w-50'
                    src='img/contact form.png'
                    alt='contact'
                  />
                  <h6
                    className='mb-0'
                    style={{ fontSize: "24px", fontWeight: 500 }}>
                    Contact Form
                  </h6>
                </div>
              </Link>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-xl-4'>
            <div className='h-100 p-2'>
              <Link to='/email' id='' className='d-block px-2 py-2 p-urls2'>
                <div className='mb-4 text-center'>
                  <img className='w-50' src='img/Email.png' alt='contact' />
                  <h6
                    className='mt-3'
                    style={{ fontSize: "24px", fontWeight: 500 }}>
                    Email
                  </h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Templates }; /* ========================= We had to remove default. */
