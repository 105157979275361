import React from "react";

function SendToCustomerFormModal() {
  return (
    <div
      className='modal fade'
      id='exampleModal'
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'>
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
        style={{ minHeight: "700px" }}>
        <div className='modal-content'>
          {/* Modal header */}
          <div className='modal-header bg-primary'>
            <h5 className='modal-title text-white w-100 text-center'>
              Send To Customer
            </h5>
            <button type='button' className='close' data-bs-dismiss='modal'>
              <span aria-hidden='true' className='modal_button'>
                <i className='fas fa-close'></i>
              </span>
              <span className='sr-only'>Close</span>
            </button>
          </div>
          <div
            className='modal-body py-5 px-4'
            style={{ background: "#f1f5f9" }}>
            <div className='row'>
              <div className='col-lg-12 col-md-12'>
                <div className='filter_bt'>
                  <b className='pt-1 fw-normal'> Filter by : </b>
                  <span>Proposals</span>
                  <span>Contract</span>
                  <span>Invoices</span>
                  <span>Tour</span>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-12 col-md-12'>
                <table className='table table-borderless modal-tb p-4'>
                  <thead>
                    <tr>
                      <th>NAME </th>
                      <th>CATEGORY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>PROPOSAL-2 HOUR EVENT</td>
                      <td>PROPOSAL</td>
                    </tr>
                    <tr>
                      <td>PROPOSAL-2 HOUR EVENT</td>
                      <td>PROPOSAL</td>
                    </tr>
                    <tr>
                      <td>PROPOSAL-2 HOUR EVENT</td>
                      <td>PROPOSAL</td>
                    </tr>
                    <tr>
                      <td>PROPOSAL-2 HOUR EVENT</td>
                      <td>PROPOSAL</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendToCustomerFormModal;
