import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEditableContent } from "../../Context/EditableContentContext";
import ContractFormModal from "../ContractForm/ContractFormModal";

export default function GalleryTopHeader({ toggleSidebar }) {
  const { pathname } = useLocation();
  const noHeader = pathname === "/gallery/settings";
  const { editableGalleryContent, handleGalleryContentChange } =
    useEditableContent();
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(editableGalleryContent);

  const handleInputChange = (e) => {
    setNewContent(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleGalleryContentChange(newContent);
      setIsEditing(false);
    }
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    handleGalleryContentChange(newContent);
    setIsEditing(false);
  };

  return (
    <>
      <ContractFormModal />
      <nav className='navbar navbar-expand flex-column flex-md-row bottom-header-main  px-lg-5 px-2 py-2'>
        <div className=' d-flex align-items-center d-md-flex ms-md-2'>
          <div className='d-flex align-items-center bottom-header'>
            {/* ====== Hamburger Button to Toggle The side Bar =========== */}
            <button
              className='sidebar-toggler flex-shrink-0 bg-transparent border-0 me-3'
              onClick={toggleSidebar}>
              <i className='fa fa-bars' />
            </button>

            <div>
              {/* Title */}

              {/* <div onClick={handleClick}>
                {isEditing ? (
                  <input
                    type='text'
                    className='border-0 outline-none'
                    value={newContent}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    onBlur={handleBlur}
                    autoFocus // Automatically focuses on the input field when it appears
                  />
                ) : (
                  <h5 className='untitled-product-title'>
                    {editableGalleryContent}
                  </h5>
                )}
              </div> */}

              {/* Folder icon and preview text */}
              {/* <Link to='/templates'>
                <p className='d-flex align-items-center'>
                  <i className='far fa-folder'></i>
                  <span className='preview-text'>Templates</span>
                </p>
              </Link> */}
            </div>
          </div>
        </div>
        {!noHeader && (
          <div className='navbar-nav align-items-center ms-md-auto'>
            <div className='py-3 button_group  gy-3'>
              {/* Preview button */}
              <Link to='/gallery/view'> 
                <button className='mb-md-0 bg-white preview_btn'>
                  PREVIEW
                </button>
              </Link>
              {/* Use template button */}
              <button
                type='btn'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop01'>
                PUBLISH
              </button>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}
