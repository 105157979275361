import React from "react";
import { Link } from "react-router-dom";

export default function PaymentMethods() {
  return (
    <>
      <div className='tab-pane fade' id='content3'>
        {/*=============== Start: Payment Sections Start =============== */}
        <div className='row g-lg-5 g-md-5 g-3 mt-3'>
          {/*=============== Start: Payment Section-1 =============== */}
          <div className='mb-3 row col-md-12 gap-4  rounded '>
            <div className='payment-gate-way col-md-5 col-lg-5  '>
              <div className='w-100 text-center image'>
                <img src='/img/square.png' alt='square' className='square' />
              </div>
              <div className='btn payment-gate-way-footer d-flex justify-content-between'>
                <span className='d-sm-inline-block  text-start '>Connect</span>
                <span className='d-md-inline-block text-end credit'>
                  Credit Cards
                </span>
              </div>
            </div>
            <div className='payment-gate-way col-md-5 col-lg-5 '>
              <div className='w-100 text-center image'>
                <img src='/img/stripe.png' alt='stripe' className='stripe' />
              </div>
              <div className='payment-gate-way-footer btn d-flex justify-content-between'>
                <span className='d-sm-inline-block  text-start '>Connect</span>
                <span className='d-md-inline-block text-end credit'>
                  Credit Cards
                </span>
              </div>
            </div>
            <div className='payment-gate-way col-md-5 col-lg-5 '>
              <div className='w-100 text-center image'>
                {" "}
                <img src='/img/paypal.png' alt='paypal' className='paypal' />
              </div>

              <div className='payment-gate-way-footer btn d-flex justify-content-between'>
                <span className='d-sm-inline-block  text-start '>Connect</span>
                <span className='d-md-inline-block text-end credit'>
                  Credit Cards
                </span>
              </div>
            </div>
          </div>
          {/*=============== End: Payment Section-1 =============== */}

          {/*=============== Start: Payment Section-2 =============== */}
          <div className='mb-3 col-md-12 border bg-white product-body rounded p-lg-3 p-md-2 p-2 my-4'>
            <div className='mb-3 col-md-6 my-5'>
              <h5 className='bold'>Client Payment Methods </h5>
            </div>

            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th></th>
                    <th>Payment Method </th>
                    <th>Connected To</th>
                    <th>Button Label</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope='row'>
                      <i className='fa-solid fa-bars'></i>
                    </th>
                    <td>Square</td>
                    <td>Tom jones (Photo Booth Owner)</td>
                    <td>Pay by Credit Card </td>
                    <td>
                      <Link
                        className='btn btn-secondary btn-sm m-1 '
                        to='#'
                        role='button'>
                        edit{" "}
                      </Link>
                      <Link
                        className='btn btn-primary btn-sm m-1'
                        to='#'
                        role='button'>
                        delete{" "}
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*=============== End: Payment Section-2 =============== */}
        </div>
        {/*=============== End: Payment Sections Start =============== */}
      </div>
    </>
  );
}
