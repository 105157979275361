import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/titleSlice";
import "./CreateContract.css";
import TextEditorToSection from "./TextEditorToSection";
import SideCanvas from "./SideCanvas";


export default function EditContract() {
  // Reference for the SunEditor instance
  const editorRef = useRef(null);
  const [contractCount, setContractCount] = useState(1);
  const title = useSelector((state) => state.title.title);
  const [contracttitle, setContracttitle] = useState("1");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [contractContent, setContractContent] = useState(`<div>
    <h1>Contract</h1>
    <p className="mt-5">Please type or copy and paste the content of your contract here. Alternatively, you can import one of your contract templates using the right panel. </p> 
    <p className="mt-5">If you don’t have a contract, you can browse our template gallery for lawyer-approved free contracts.</p> 
    <p className="mt-5">And remember to delete this text before sending.</p>
    <p className="smart_text">smart_text</p>
  </div>`);
  const { contractId } = useParams();
  const navigate = useNavigate();
  const [contract, setContract] = useState(null);
  const dispatch = useDispatch();
  const handleEditorChange = (content) => {
    setContractContent(content);
  };
  useEffect(() => {
    const addCustomButton = () => {
      const sunEditorToolbar = document.querySelector(".se-menu-list");

      if (sunEditorToolbar) {
        const customButton = document.createElement("button");
        customButton.classList.add("sun-editor-action", "customAction", "p-3");
        customButton.innerHTML = "Add Smart Text";
        customButton.title = "Add Smart Text";
        customButton.addEventListener("click", () => {});

        sunEditorToolbar.appendChild(customButton);
      }
    };

    addCustomButton();
  }, []);

  // Function to set default content when the component mounts
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContents("<p>Your default content goes here</p>");
    }
  }, []);

  useEffect(() => {
    const fetchContract = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/contracts/${contractId}`);
        setContractContent(response.data.pages[0].content);
        setContract(response.data);
        setContracttitle(response.data.title);
        dispatch(setTitle(response.data.title));
      } catch (error) {
        console.error('Error fetching contract:', error);
      }
    };
    fetchContract();
  }, [contractId]);

  if (!contract) {
    return <div>Loading...</div>;
  }

  const handleSubmit = async () => {
    try {
      await axios.put(`${backendUrl}/api/contracts/${contractId}`, {
        title: title,
        pages: [
          {
            content: contractContent
          }
        ]
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Contract updated successfully!",
      });
      navigate("/contracts");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error updating contract!",
      });
    }
  };

  const editorOptions = {
    height: 200,
    buttonList: [
      [
        "codeView",
        "paragraphStyle",
        "bold",
        "underline",
        "italic",
        "strike",
        "table",
        "link",
        "horizontalRule",
        "list",
        "align",
        "hiliteColor",
        "image",
        "video",
      ],
    ],
    fontSize: [12, 14, 16, 18, 20],
    colorList: [
      [
        "#828282",
        "#FF5400",
        "#676464",
        "#F1F2F4",
        "#FF9B00",
        "#F00",
        "#fa6e30",
        "#000",
        "rgba(255, 153, 0, 0.1)",
        "#FF6600",
        "#0099FF",
        "#74CC6D",
        "#FF9900",
        "#CCCCCC",
      ],
    ],
  };
// Data for the default content of the SunEditor
const data = `<div><h1>Contract</h1><p className="mt-5">Please type or copy and paste the content of your contract here. Alternatively, you can import one of your contract templates using the right panel. </p> <p className="mt-5">If you don’t have a contract, you can browse our template gallery for lawyer-approved free contracts.</p> <p  className="mt-5">And remember to delete this text before sending .</p>
<p className="smart_text">smart_text</p>
</div>`;

// Function to handle adding a new contract page
const handleAddContract = () => {
  // Increment contract count
  setContractCount((prevCount) => prevCount + 1);
  // Create unique ID for the new tab
  const newTabId = "v-pills-" + Math.random().toString(36).substr(2, 9);
  // Create unique ID for the new tab content
  const newTabContentId =
    "v-pills-content-" + Math.random().toString(36).substr(2, 9);

  // Create new tab content HTML
  const newTabContent = `
  <div class="tab-pane fade" id="${newTabContentId}" role="tabpanel" aria-labelledby="${newTabId}-tab">
    <!-- New tab content goes here -->
    <p>New Contract Content ${contractCount}</p>
  </div>
`;

  // Append the new tab content to the tab content container
  const tabContentContainer = document.querySelector(".tab-content");
  tabContentContainer.insertAdjacentHTML("beforeend", newTabContent);

  // Create new tab link HTML
  const newTabLink = `
  <button class="nav-link" id="${newTabId}-tab" data-bs-toggle="pill" data-bs-target="#${newTabContentId}" type="button" role="tab" aria-controls="${newTabContentId}" aria-selected="false">
    <div class='right-side-box'>
      <div class='d-flex align-items-center justify-content-between'>
        <div class='d-flex align-items-center justify-content-between'>
          <div class='icon-box active bg-white' role='button'>
            <!-- Skeletons -->
            <div class='row gap-2 align-items-start m-2'>
              <div class='col-12 skeleton-one'></div>
              <div class='col-12 skeleton-two'></div>
              <div class='col-12 skeleton-three'></div>
              <div class='col-12 skeleton-four'></div>
            </div>
          </div>
          <h5 class='box-title'>Page ${contractCount}</h5>
        </div>
        <!-- Dropdown -->
        <div class='nav-item dropdown' id='top-user-nav'>
          <div class='nav-link dropdown' data-bs-toggle='dropdown'>
            <div class='three-dot-btn'>
              <div class='border-0 bg-transparent' type='button'>
                <i class='fa-solid fa-ellipsis-vertical'></i>
              </div>
            </div>
          </div>
          <div class='dropdown-menu shadow-sm border-0 py-4 px-4'>
            <Link to='#'>
              <div class='link-btn mb-4 rename-page-btn' data-tab='${newTabId}'>
                <i class='fa fa-pencil' aria-hidden='true'></i>
                <span>Rename</span>
              </div>
            </Link>
            <Link to='#'>
              <div class='link-btn mb-4 duplicate-page-btn' data-tab='${newTabId}'>
                <i class='fa-solid fa-clone'></i>
                <span>Duplicate</span>
              </div>
            </Link>
            <Link to='#'>
              <div class='link-btn text-danger delete-page-btn' data-tab='${newTabId}'>
                <i class='fa-solid fa-trash'></i>
                <span>Delete</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </button>
`;

  // Append the new tab link to the tab links container
  const tabLinksContainer = document.querySelector("#v-pills-tab");
  tabLinksContainer.insertAdjacentHTML("afterbegin", newTabLink);

  // Add event listeners for the rename, duplicate, and delete buttons
  const renamePageBtn = document.querySelector(
    `.rename-page-btn[data-tab='${newTabId}']`
  );
  const duplicatePageBtn = document.querySelector(
    `.duplicate-page-btn[data-tab='${newTabId}']`
  );
  duplicatePageBtn.addEventListener("click", () => {
    // Clone the tab and its content
    const clonedTab = document
      .querySelector(`#${newTabId}-tab`)
      .cloneNode(true);
    const clonedContent = document
      .querySelector(`#${newTabContentId}`)
      .cloneNode(true);

    // Generate new IDs for the cloned tab and content
    const clonedTabId = "v-pills-" + Math.random().toString(36).substr(2, 9);
    const clonedTabContentId =
      "v-pills-content-" + Math.random().toString(36).substr(2, 9);

    // Update IDs in the cloned tab and content
    clonedTab.setAttribute("id", clonedTabId);
    clonedTab.setAttribute("aria-labelledby", `${clonedTabId}-tab`);
    clonedTab.setAttribute("data-bs-target", `#${clonedTabContentId}`);
    clonedContent.setAttribute("id", clonedTabContentId);
    clonedContent.setAttribute("aria-labelledby", `${clonedTabId}-tab`);

    // Append the cloned tab and content
    tabLinksContainer.appendChild(clonedTab);
    tabContentContainer.appendChild(clonedContent);

    // Add event listener to the delete button of the cloned item using event delegation
    tabLinksContainer.addEventListener("click", function (event) {
      const targetBtn = event.target.closest(".delete-page-btn");
      if (targetBtn) {
        const tabToRemoveId = targetBtn
          .closest(".nav-link")
          .getAttribute("id")
          .replace("-tab", "");
        const tabToRemove = document.querySelector(`#${tabToRemoveId}`);
        const contentToRemove = document.querySelector(
          `#${tabToRemoveId}-content`
        );
        if (tabToRemove) tabToRemove.remove();
        if (contentToRemove) contentToRemove.remove();
      }
    });
  });

  renamePageBtn.addEventListener("click", () => {
    const newTitle = prompt("Enter a new title for the page:");
    if (newTitle) {
      const tabTitle = document.querySelector(`#${newTabId}-tab .box-title`);
      tabTitle.textContent = newTitle;
    }
  });

  const deletePageBtn = document.querySelector(
    `.delete-page-btn[data-tab='${newTabId}']`
  );
  deletePageBtn.addEventListener("click", () => {
    // Remove the tab and its content
    const tabToRemove = document.querySelector(`#${newTabId}-tab`);
    const contentToRemove = document.querySelector(`#${newTabContentId}`);
    if (tabToRemove) tabToRemove.remove();
    if (contentToRemove) contentToRemove.remove();
  });
};

  return (
      <>
        {/* Side canvas for the smart text */}
        <SideCanvas
        // Pass the function to handle smart text input change
        />
        <div className='container-fluid p-lg-5 p-md-5 p-3'>
          <div className='row g-lg-5 row_mobile g-md-5 g-3 px-lg-5'>
            {/* Text Editor part */}
            <div className='col-md-9'>
              <div className='text-editor px-2 px-md-5 py-3'>
                <div class='tab-content h-100' id='v-pills-tabContent'>
                  {/* text editor page */}
                  <div
                    class='tab-pane fade show active'
                    id='v-pills-home'
                    role='tabpanel'
                    aria-labelledby='v-pills-home-tab'>
                    <TextEditorToSection />
                    <div className='position-relative overflow-hidden'>
                      <SunEditor
                        setOptions={editorOptions}
                        ref={editorRef}
                        defaultValue={contractContent}
                        onChange={handleEditorChange}
                        s
                      />
                    </div>
                  </div>
  
                  {/* add page */}
                  <div
                    class='tab-pane fade h-100  text-center'
                    id='v-pills-profile'
                    role='tabpanel'
                    aria-labelledby='v-pills-profile-tab'>
                    <div className='add-page  d-flex flex-column justify-content-center align-items-center h-100'>
                      <h2>Add pages from your templates</h2>
                      <p>
                        Browse your templates to add pages <br /> or select “Start
                        from blank” to build a page from scratch.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            {/* Contract Right Area */}
            <div className='col-md-3'>
              <span class='placeholder col-6'></span>
  
              <div className='row'>
                <div className='col-12'>
                  <div className='right-side-area'>
                    <div
                      class='nav flex-column nav-pills me-3'
                      id='v-pills-tab'
                      role='tablist'
                      aria-orientation='vertical'>
                      {/* Current Page Start: Contract Box */}
                      <button
                        class='nav-link active'
                        id='v-pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-home'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-home'
                        aria-selected='true'>
                        {/* Current Page: Contract Box */}
                        <div className='right-side-box'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                              <div
                                className='icon-box active bg-white'
                                role='button'>
                                {/* Skeletons */}
                                <div className='row gap-2 align-items-start m-2'>
                                  <div className='col-12 skeleton-one'></div>
                                  <div className='col-12 skeleton-two'></div>
                                  <div className='col-12 skeleton-three'></div>
                                  <div className='col-12 skeleton-four'></div>
                                </div>
                              </div>
                              <h5 className='box-title'>Contract</h5>
                            </div>
                            {/* Dropdown */}
                            <div className='nav-item dropdown' id='top-user-nav'>
                              <div
                                href='#'
                                className='nav-link dropdown'
                                data-bs-toggle='dropdown'>
                                <div className='three-dot-btn'>
                                  <button
                                    className='border-0 bg-transparent'
                                    type='button'>
                                    <i class='fa-solid fa-ellipsis-vertical'></i>
                                  </button>
                                </div>
                              </div>
                              {/* Dropdown Menu */}
                              <div className='dropdown-menu shadow-sm border-0 py-4 px-4'>
                                <Link to='#'>
                                  <div className='link-btn mb-4'>
                                    <i
                                      class='fa fa-pencil'
                                      aria-hidden='true'></i>
                                    <span>Rename</span>
                                  </div>
                                </Link>
                                <Link to='#'>
                                  <div className='link-btn mb-4'>
                                    <i class='fa-solid fa-clone'></i>
                                    <span>Duplicate</span>
                                  </div>
                                </Link>
                                <Link to='#'>
                                  <div className='link-btn text-danger'>
                                    <i class='fa-solid fa-trash'></i>
                                    <span>Delete</span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                      {/* Current Page End: Contract Box */}
  
                      {/* Add Page Start: New Page */}
                      <button
                        class='nav-link'
                        id='v-pills-profile-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-profile'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-profile'
                        aria-selected='false'>
                        {/* Add Page: New Page */}
                        <div className='right-side-box'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div
                              data-bs-toggle='offcanvas'
                              data-bs-target='#offcanvasRight2'
                              aria-controls='offcanvasRight2'
                              className='d-flex align-items-center'>
                              <div
                                className='icon-box icon-box-add-page'
                                role='button'>
                                <span>
                                  <i class='fa-solid fa-plus'></i>
                                </span>
                              </div>
                              <h5 className='box-title'>Add Page</h5>
                            </div>
                            {/* Dropdown */}
                            <div
                              className='nav-item dropdown'
                              id='top-user-nav'
                              aria-disabled='true'>
                              <div
                                href='#'
                                className='nav-link dropdown'
                                data-bs-toggle='dropdown'>
                                {/*  <div className='three-dot-btn'>
                                  <button className='border-0 bg-transparent pointer-event-none'>
                                    <i class='fa-solid fa-ellipsis-vertical'></i>
                                  </button>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                      {/* Add Page End: New Page */}
                    </div>
  
                    <div className='text-center pt-2'>
                      <button
                        id='submit-btn'
                        type='button'
                        className='px-5 rounded-pill btn btn-primary btn-lg text-uppercase'
                        onClick={handleSubmit}>
                        Save
                      </button>
                    </div>
  
                    {/* Add page canvas */}
                    <div className='create-contract'>
                      <div
                        class='offcanvas offcanvas-end'
                        tabindex='-1'
                        id='offcanvasRight2'
                        aria-labelledby='offcanvasRightLabel'
                        data-bs-backdrop='false'>
                        <div class='w-100 text-start header'>
                          <button
                            type='button'
                            class='btn-close mt-3 ms-4 text-black'
                            data-bs-dismiss='offcanvas'
                            aria-label='Close'></button>
  
                          <div className='text-center py-3'>
                            <h5>Add Page</h5>
                            <span style={{ color: "#727272" }}>
                              Select from the list below
                            </span>
                          </div>
                        </div>
                        <div class='w-100 text-start header'>
                          <div className='text-center py-3'>
                            <span>+ Page With Text Only</span>
                          </div>
                        </div>
  
                        <div class='offcanvas-body p-0'>
                          <h5 className='px-4 py-2'>Contracts</h5>
  
                          <div className='single_box mt-3'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <div onClick={handleAddContract}>
                                <h6>New Contract</h6>
                                <div className='d-flex align-items-center mt-1'>
                                  <i class='far fa-folder'></i>
                                  <span className='ms-2'>Contract</span>
                                </div>
                              </div>
                              <div className='icon'>
                                <i class='fa fa-chevron-right'></i>
                              </div>
                            </div>
                          </div>
  
                          <div className='single_box mt-4'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <div>
                                <h6>Basic Booth Contract</h6>
                                <div className='d-flex align-items-center mt-1'>
                                  <i class='far fa-folder'></i>
                                  <span className='ms-2'>Contract</span>
                                </div>
                              </div>
                              <div className='icon'>
                                <i class='fa fa-chevron-right'></i>
                              </div>
                            </div>
                          </div>
  
                          <div>
                            <h5 className='py-4 px-4'>Invoice</h5>
                            <div className='single_box mt-3'>
                              <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                  <h6>New Invoice</h6>
                                  <div className='d-flex align-items-center mt-1'>
                                    <i class='far fa-folder'></i>
                                    <span className='ms-2'>Invoice</span>
                                  </div>
                                </div>
                                <div className='icon'>
                                  <i class='fa fa-chevron-right'></i>
                                </div>
                              </div>
                            </div>
                            <div className='single_box mt-4'>
                              <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                  <h6>Click Firefly - 2 Hour Invoice</h6>
                                  <div className='d-flex align-items-center mt-1'>
                                    <i class='far fa-folder'></i>
                                    <span className='ms-2'>Invoice</span>
                                  </div>
                                </div>
                                <div className='icon'>
                                  <i class='fa fa-chevron-right'></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}