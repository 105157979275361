
import { useEditableContent } from "../../Context/EditableContentContext";
import {useState,useEffect} from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom'; 
export default function Title() {
  
  const {
    editableGalleryContent,
    handleGalleryContentChange,
    editableGalleryMessageContent,
    handleGalleryMessageContentChange,
  } = useEditableContent();
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [newTitleContent, setNewTitleContent] = useState(
    editableGalleryContent
  );
  const [isMessageEditing, setIsMessageEditing] = useState(false);
  const [newMessageContent, setNewMessageContent] = useState(
    editableGalleryMessageContent
  );
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [created_by, setCreatedBy] = useState(null);
  const navigate = useNavigate();
  const StaticGalleryTitle="Untitled Gallery";
  const StaticGalleryMsg="Write a message or direction for your customer here.";

  useEffect(() => {
      // Check if user data exists in localStorage
      const udata = localStorage.getItem('user');
      if (!udata) {
          // Redirect to login page if user data does not exist
          navigate('/sign-in');
          return;
      }
      const user_data = JSON.parse(udata);
      setCreatedBy(user_data.user.id);
  }, [navigate]);


  useEffect(() => {
    if (created_by) {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/get-users/${created_by}`);
                if (response.data.success) {
                  if(response.data.data.gallery_title!=""){
                    setNewTitleContent(response.data.data.gallery_title);
                  }
                  if(response.data.data.gallery_note!=""){
                    setNewMessageContent(response.data.data.gallery_note);
                  }
                } 
            } catch (err) {
                console.log('An error occurred while fetching user data.');
            } 
        };
        fetchUserData();
    } 
}, [created_by]); // Dependency array to refetch if userId changes

  const handleTitleInputChange = (e) => {
    setNewTitleContent(e.target.value);
  };

  const handleTitleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleGalleryContentChange(newTitleContent);
      setIsTitleEditing(false);
    }
  };

  const handleTitleClick = () => {
    setIsTitleEditing(true);
  };

  const handleTitleBlur = () => {
    handleGalleryContentChange(newTitleContent);
    setIsTitleEditing(false);
    if (newTitleContent) {
      axios.post(`${backendUrl}/api/gallery-title`, { title: newTitleContent,id:created_by })
        .then(response => {
        })
        .catch(error => {
          console.error("Error creating category:", error);
        });
    }
  };

  const handleMessageInputChange = (e) => {
    setNewMessageContent(e.target.value);
  };

  const handleMessageKeyPress = (e) => {
    if (e.key === "Enter") {
      handleGalleryMessageContentChange(newMessageContent);
      setIsMessageEditing(false);
    }
  };

  const handleMessageClick = () => {
    setIsMessageEditing(true);
  };

  const handleMessageBlur = () => {
    handleGalleryMessageContentChange(newMessageContent);
    setIsMessageEditing(false);
    if (newMessageContent) {
      axios.post(`${backendUrl}/api/gallery-title`, { note: newMessageContent,id:created_by })
        .then(response => {
        })
        .catch(error => {
          console.error("Error creating category:", error);
        });
    }
  };

  return (
    <div className='gallery_title'>
      <div onClick={handleTitleClick}>
        {isTitleEditing ? (
          <input
            type='text'
            className='border-0 outline-none bg-light p-2 rounded w-100'
            value={newTitleContent}
            onChange={handleTitleInputChange}
            onKeyPress={handleTitleKeyPress}
            onBlur={handleTitleBlur}
            autoFocus
          />
        ) : (
          <h2>{newTitleContent?newTitleContent:StaticGalleryTitle}</h2>
        )}
      </div>

      <div className=' mt-2'  onClick={handleMessageClick}>
        {isMessageEditing ? (
          <textarea
            type='text'
            className='border-0 outline-none bg-light p-2 rounded w-100'
            value={newMessageContent}
            onChange={handleMessageInputChange}
            onKeyPress={handleMessageKeyPress}
            onBlur={handleMessageBlur}
            autoFocus
          />
        ) : (
          <span className="mt-3">{newMessageContent?newMessageContent:StaticGalleryMsg}</span>
        )}
      </div>
    </div>
  );
}
