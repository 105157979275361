import React, { Component } from 'react'
import Outernavbar from './outernavbar.component'
import axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
export default class SignUp extends Component {

    constructor(props) {
        super(props)
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordConfirmation = this.onChangePasswordConfirmation.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            first_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            errorMessage: '', // New state for error message
            successMessage: '', // New state for success message
            loading: false // New state for loading spinner
        }
    }

    onChangeFirstName(e) {
        this.setState({ first_name: e.target.value })
    }

    onChangeUserEmail(e) {
        this.setState({ email: e.target.value })
    }

    onChangePassword(e) {
        this.setState({ password: e.target.value })
    }

    onChangePasswordConfirmation(e) {
        this.setState({ password_confirmation: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault()

        if(this.state.first_name.length < 2 || this.state.first_name.length > 20 ){
            this.setState({ errorMessage: "First name should be between 2-20 characters" });
            return;
        }

        if(this.state.password.length < 6 ){
            this.setState({ errorMessage: "Password should be greater than 5 characters" });
            return;
        }

        if(this.state.password !== this.state.password_confirmation ){
            this.setState({ errorMessage: "Enter the same password in both fields" });
            return;
        }

        this.setState({ loading: true, errorMessage: '', successMessage: '' });

        const userObject = {
            first_name: this.state.first_name,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,
        };

        axios.post(`${backendUrl}/api/auth/register`, userObject)
            .then((res) => {
                if(res.data.message === "User successfully registered"){
                    this.setState({ successMessage: "Registration Successful. Redirecting to login page...", errorMessage: '', loading: false });
                    setTimeout(() => {
                        window.location = "/sign-in";
                    }, 3000);
                }
            }).catch((error) => {
                if(error.response && error.response.data ===  "{\"email\":[\"The email has already been taken.\"]}"){
                    this.setState({ errorMessage: "The email has already been taken. Redirecting to login page...", successMessage: '', loading: false });
                    setTimeout(() => {
                        window.location = "/sign-in";
                    }, 3000);
                } else {
                    this.setState({ errorMessage: "An error occurred. Please try again later.", successMessage: '', loading: false });
                }
            });

        this.setState({ first_name: '', email: '', password: '', password_confirmation: '' });
    }

    render() {
        return (
            <div className='App'>
                <Outernavbar />
                <div className="auth-wrapper">
                    <div className="auth-inner">
                        <form onSubmit={this.onSubmit}>
                            <h3>Sign Up</h3>
                            {this.state.errorMessage && (
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMessage}
                                </div>
                            )}
                            {this.state.successMessage && (
                                <div className="alert alert-success" role="alert">
                                    {this.state.successMessage}
                                </div>
                            )}
                            <div>
                                <div className="mb-3">
                                    <label>Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Full name"
                                        onChange={this.onChangeFirstName}
                                        name="firstname"
                                        value={this.state.first_name}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter email"
                                        onChange={this.onChangeUserEmail}
                                        name="email"
                                        value={this.state.email}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Enter password"
                                        onChange={this.onChangePassword}
                                        name="password"
                                        value={this.state.password}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Enter password"
                                        onChange={this.onChangePasswordConfirmation}
                                        name="confirmpassword"
                                        value={this.state.password_confirmation}
                                    />
                                </div>
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-primary" disabled={this.state.loading}>
                                        {this.state.loading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : 'Sign Up'}
                                    </button>
                                </div>
                                <GoogleOAuthProvider clientId="386932037035-k8v833noqjk7m4***********.apps.googleusercontent.com">
                                    <React.StrictMode>
                                        <App />
                                    </React.StrictMode>
                                </GoogleOAuthProvider>
                                <p className="forgot-password text-right">
                                    Already registered <a href="/sign-in">sign in?</a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
