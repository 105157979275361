import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';

export default function AddGallerySet({ GalleryData,deletedids,setdeletedids}) {
  const { state } = useLocation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const handlegalleryselect = (id) => {
    // Check if the ID is already in the array
    if (deletedids.includes(id)) {
      setdeletedids(deletedids.filter((selectedId) => selectedId !== id)); // Remove the ID
    } else {
      setdeletedids([...deletedids, id]); // Add the ID
    }
  };
 
return (
  <div className='row mt-4 mb-5' style={{ rowGap: "20px" }}>
    {GalleryData.map((data, i) => (
      <div className='col-md-3' key={i}>
        <div className='uploaded_box'>
          <div className='check_input'>
          <input
              className='form-check-input mt-0'
              type='checkbox'
              aria-label='Checkbox for following text input'
              checked={deletedids.includes(data.id)} // Check if the ID is in deletedids
              onChange={() => handlegalleryselect(data.id)} // Toggle selection on change
            />
          </div>
          <Link to={`/gallery/settings/${data.id}`}>
            {data.gallery_media.map((media) => (
              media.type === "primary" && (
                <img
                  key={media.id}
                  src={`${backendUrl}${media.path}`}
                  alt='GALLERY'
                />
              )
            ))}
            <span className='text-content fw-bold d-block image_title'>
              {data.title}
            </span>
          </Link>
        </div>
      </div>
    ))}
    <div className='col-md-3'>
      <Link to='/gallery/settings'>
        <div className='upload_box'>
          <img src='/img/photo-gallery.png' alt='GALLERY' />
          <span className='text-content d-block'>Add Gallery Set</span> 
        </div>
      </Link>
    </div>
  </div>
);

}
