import { useEffect, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useEditableContent } from "../../Context/EditableContentContext";
import { deletePackage, fetchPackages } from "../../services/packagesService"; // Import your service functions

function Package() {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const { editablePackageContent, handlePackageContentChange } =
    useEditableContent();

  useEffect(() => {
    const getPackages = async () => {
      try {
        const packages = await fetchPackages();
        setPackages(packages);
      } catch (error) {
        console.error("Failed to fetch packages:", error);
      }
    };

    getPackages();
  }, []);

  /*   const toggleMenu = (opackage) => {
    setSelectedPackage(opackage);
    setMenuVisible(!menuVisible);
  }; */

  const showMenu = (opackage) => {
    setSelectedPackage(opackage);
    setMenuVisible(true);
  };

  const hideMenu = () => {
    setMenuVisible(false);
    setSelectedPackage(null);
  };

  const handleEdit = (packageId) => {
    window.location.href = `/edit-package/${packageId}`;
  };

  const handleDelete = async (packageId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await deletePackage(packageId);
        setPackages(packages.filter((opackage) => opackage.id !== packageId));
        Swal.fire("Deleted!", "Your package has been deleted.", "success");
        handlePackageContentChange("Untitled Item");
      }
    } catch (error) {
      console.error(`Failed to delete package with ID ${packageId}:`, error);
      Swal.fire("Error", "Failed to delete package.", "error");
    }
  };

  return (
    <div className='container-fluid p-lg-5 p-md-5 p-3'>
      <div className='row g-lg-5 g-md-5 g-3'>
        <div className='col-sm-12 col-md-12 col-xl-12'>
          <div className='mb-4'>
            <h3 className='mb-1'>Package Templates</h3>
            <p>
              Packages consist of a collection of services or items that are
              typically bundled together, accompanied by their <br /> respective
              prices. Package templates serve as fundamental components,
              enabling you to seamlessly integrate <br /> your services into
              various file formats such as brochures, invoices, and proposals.
            </p>
          </div>
        </div>

        <div className='col-sm-12 col-md-6 col-xl-4 product-items'>
          <div className='h-100 bg-white rounded p-3 shadow-sm'>
            <Link
              to='/add-package'
              id=''
              className='h-100 d-flex align-items-center justify-content-center px-2 py-5 p-urls'
              style={{ border: "1px dashed #534dbb" }}>
              <div className='mb-4 text-center'>
                <h1 className='mb-0 fw-light text-uppercase'>+</h1>
                <h6 className='mb-0 fw-light text-uppercase'>
                  Create a new Package
                </h6>
              </div>
            </Link>
          </div>
        </div>

        {packages.map((opackage) => (
          <div
            key={opackage.id}
            onClick={() => handleEdit(opackage.id)}
            className='col-sm-12 col-md-6 col-xl-4 product-items'>
            <div className='h-100 bg-white rounded p-4 shadow-sm position-relative product-items2'>
              <div className='d-flex justify-content-between '>
                <div id='p-meta'>
                  <h5>
                    {opackage.name}
                    <br />
                  </h5>
                  <p style={{ fontSize: 12 }}>
                    Created at{" "}
                    {new Date(opackage.created_at).toLocaleDateString()}
                  </p>
                </div>
                <div
                  id='p-dot'
                  onMouseEnter={() => showMenu(opackage)}
                  onMouseLeave={hideMenu}
                  style={{ position: "relative", zIndex: 10000 }}>
                  <FaEllipsisV
                    // onClick={() => toggleMenu(opackage)}
                    style={{ cursor: "pointer" }}
                  />
                  {menuVisible && selectedPackage === opackage && (
                    <div
                      className='position-absolute bg-white shadow-sm rounded'
                      style={{
                        top: "20px",
                        right: "0",
                        zIndex: "999",
                      }}>
                      <button
                        className='dropdown-item'
                        onClick={() => handleEdit(opackage.id)}>
                        Edit
                      </button>
                      <button
                        className='dropdown-item'
                        onClick={(e) => 
                          {
                          e.stopPropagation();
                          handleDelete(opackage.id)
                          }
                        }>
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div id='product-cart' style={{ fontSize: 12 }}>
                <i className='fa fa-shopping-cart pe-2' aria-hidden='true' />
                Package
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export { Package };
